import {useState,useCallback} from 'react';


export default function useChange(initialState=false){
    const [changed, setChanged] = useState(initialState);

    const haveChanged = useCallback(()=>{
        setChanged(changed=>!changed);
    },[])

    return [changed, haveChanged]
}
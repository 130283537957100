/**
 * @fileoverview Component for AUTO MONEY Drawer
 */
import './autoMoney.css';

import React, { useState, useEffect } from 'react';
import { AUTO_MONEY_PARAMETER } from '../../../constants/constants.filter';
import { HELP_TEXT_AUTO_MONEY_DRAWER } from '../../../constants/constants.popover';
import { COMMON_TEXT, STEP } from '../../../constants/constants.string';
import { isEmpty } from '../../../utils/utils';
import { InputField } from './indicatorDrawer';
import { INDICATOR_DATATYPES} from '../../../constants/constants.Indicator';
import UpdateButton from './updateButton';

import { Form, Popover, Select } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons'

const { Option } = Select

export default function AutoMoneyDrawer(props) {

    const { code, onCodeChange, setDrawerState } = props

    // sets parameters corresponding to filter type
    const [codeBlock, setCodeBlock] = useState({});
    const [codeBlockError, setCodeBlockError] = useState({})
    const [popOverContent, setPopOverContent] = useState({})

    useEffect(() => {
        !isEmpty(code) && setCodeBlock({ ...code })
        setCodeBlockError({})
        setPopOverContent({ ...HELP_TEXT_AUTO_MONEY_DRAWER })
    }, [code])

    /**
     * @param {string} value value of the field
     * @param {string} fieldName name of the field
     * @description handles change in input fields
     */
    const handleFieldChange = (value, fieldName) => {
        let tempCodeBlock = { ...codeBlock };
        tempCodeBlock[fieldName] = value;
        setCodeBlock({ ...tempCodeBlock })
        setCodeBlockError({
            ...codeBlockError,
            [fieldName]: {
                status: (value || value === 0) ? false : true,
                help: (value || value === 0) ? null : COMMON_TEXT.EMPTY_STRING
            }
        })
    }

    // validation states for empty field check
    const [isValid, setIsValid] = useState(true)
    useEffect(() => {
        if (Object.keys(codeBlockError).length) {
            const isValid = !Boolean(Object.values(codeBlockError).filter(i => i.status).length)
            setIsValid(isValid)
        }
    }, [codeBlockError])

    /**
     * @description on Update button clicked
     */
    const handleOnUpdateClick = () => {
        if (isValid) {
            onCodeChange({ ...codeBlock })
            setDrawerState(false)
        }
    }

    const fields = Object.keys(AUTO_MONEY_PARAMETER).map(key => AUTO_MONEY_PARAMETER[key])

    return (
        <div className="drawer-body">
            <div className="property-header">{COMMON_TEXT.PROPERTIES}</div>
            <div className="property-body">
                {fields && fields.map(field => {
                    if (field.DATATYPE === INDICATOR_DATATYPES.ENUM) {
                        return codeBlock[field.NAME] && <AutoMoneySelectField key={field.NAME} label={field.NAME}
                        data={field.DATA}
                        checked={codeBlock[field.NAME]}
                        value={codeBlock[field.NAME]}
                        help={codeBlockError[field.NAME] && codeBlockError[field.NAME].help}
                        error={codeBlockError[field.NAME] && codeBlockError[field.NAME].status}
                        onChange={(event) => handleFieldChange(event, field.NAME)}
                        content={popOverContent[field.NAME]} />
                        }
                    else {
                        return codeBlock[field.NAME] && <InputField key={field.NAME} label={field.NAME}
                        content={popOverContent[field.NAME]}
                        value={codeBlock[field.NAME]}
                        step={STEP.FLOAT}
                        typeOfField='number'
                        help={codeBlockError[field.NAME] && codeBlockError[field.NAME].help}
                        error={codeBlockError[field.NAME] && codeBlockError[field.NAME].status}
                        onChange={(event) => handleFieldChange(event.target.value, field.NAME)} />
                        }
                    }                       
                )}
            </div>
            <UpdateButton onUpdate={handleOnUpdateClick} />
        </div>
    );
}

/**
 * @description Select Field Component for data of type ENUM
 */
export function AutoMoneySelectField(props) {
    return (
        <div className="option-style" id="auto-money-block">
            <div className="auto-money-field">
                <div className="auto-money-label">{props.label}</div>
                <Popover content={props.content && props.content}>
                <img style={{height:'15px',maxWidth:'100%'}} src={require('../../../assets/robotasset/message-question.png')} alt="" />
                </Popover>
            </div>
            <Form.Item className="auto-money-form-item"
                help={props.help && props.help}
                hasFeedback={props.error} >
                <Select
                    placeholder='Select'
                    className="theme-text auto-money-select"
                    onChange={props.onChange}
                    showArrow={true}
                    value={props.value}>
                    {Object.keys(props.data).map((item, index) =>
                        <Option key={`${item}${index}`} value={props.data[item]}>
                            {item}
                        </Option>
                    )}
                </Select>
            </Form.Item>
        </div>
    )
}
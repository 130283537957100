import './index.css';
import './flex.less';
import './global.css'
import './ant-overrides.css';
import 'antd/dist/antd.css';

import * as serviceWorker from './serviceWorker';

import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";

import Layout from './components/layout/layout';
import { Provider } from "react-redux";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers/rootReducer";
import rootSaga from "./sagas";
import storage from "redux-persist/lib/storage";

/* eslint-disable */



const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];


function* initSaga() {
  console.log("Redux-Saga initialized");
}
const persistConfig = {
  key: 'userReducer',
  storage: storage,
  whitelist: ['userReducer'] // which reducer want to store
};
const pReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  pReducer,
  composeEnhancer(applyMiddleware(...middlewares))
);
sagaMiddleware.run(initSaga);
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);


const routing = (
  <Provider store={store}>
    <Router
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
      >
      <Layout />
    </Router>
  </Provider>
);

const rootElement = document.getElementById('root')
ReactDOM.render(routing, rootElement)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
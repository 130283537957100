import React from "react";
import "./subscription.css";
import { ImageBase } from "../../../constants/APIConstant";
import no_Image from "../../../assets/no_Image.png";
import { Grid } from "antd";

export default function CardContainer(props) {
  const { item, imageSize, ItemCard, parentUpdate } = props;

  const { useBreakpoint } = Grid;
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const isBelow1200px = !xl;
  const isBelow992px = !lg;

  let fimage = null;
  if (item.images?.length > 0) {
    fimage = item.images[0];
  } else {
    if (item.image_1) {
      fimage = item.image_1;
    }
  }

  const style = {
    width: "100%",
    height: imageSize,
  };
  // if (fimage) {
  //   style.backgroundImage = `url(${ImageBase.dev + fimage})`;
  // } else {
  //   style.backgroundImage = `url(${no_Image})`;
  // }
  if (item?.image1) {
    style.backgroundImage = `url(${ImageBase.dev + item?.image1})`;
  } else {
    style.backgroundImage = `url(${no_Image})`;
  }

  return (
    <div className="cardRatio">
      <div
        className="image-container"
        style={{ height: isBelow1200px ? "unset" : "350px" }}
      >
        <div style={style} className="image-set-back zoom-img"></div>
      </div>
      <ItemCard parentUpdate={parentUpdate} {...props}></ItemCard>
    </div>
  );
}

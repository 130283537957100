/**
 * @fileoverview Component for Bar Drawer
 */
import './barDrawer.css';

import { COMMON_TEXT, STEP } from '../../../constants/constants.string';
import { Checkbox, Form, Input, Popover, Select, Tooltip } from 'antd'
import { BAR_DATATYPES, BAR_DRAWER_STATE } from '../../../constants/constants.Bar'
import React, { useEffect, useState } from 'react'

import { DEFAULT_BAR_STATE } from '../../../constants/constants'
import { HELP_TEXT, POPOVER_USER_ADJUSTABLE } from '../../../constants/constants.popover';
import { QuestionCircleOutlined } from '@ant-design/icons'
import UpdateButton from './updateButton'
import { isEmpty } from '../../../utils/utils'

const { Option } = Select

export default function BarDrawer(props) {

    const { barType, code, onCodeChange, setDrawerState } = props

    // sets parameters corresponding to filter type
    const [codeBlock, setCodeBlock] = useState({});
    const [codeBlockError, setCodeBlockError] = useState({})
    const [popOverContent, setPopOverContent] = useState({})

    useEffect(() => {
        setCodeBlockError({})
        if (!isEmpty(code)) {
            if (code[barType]) {
                onCodeChange({ ...code[barType] })
                setCodeBlock({ ...code[barType] })
            } else {
                barType !== code['name']
                    ? setCodeBlock({ ...DEFAULT_BAR_STATE[barType] })
                    : setCodeBlock({ ...code })
            }
        }
        setPopOverContent({ ...HELP_TEXT[barType] })
    }, [barType, code])

    // validation states for empty field check
    const [isValid, setIsValid] = useState(true)
    useEffect(() => {
        if (Object.keys(codeBlockError).length) {
            const isValid = !Boolean(Object.values(codeBlockError).filter(i => i.status).length)
            setIsValid(isValid)
        }
    }, [codeBlockError])

    /**
     * @param {string} value value of the field
     * @param {string} fieldName name of the field
     * @description handles change in fields
     */
    const handleFieldChange = (value, fieldName, isFieldRequired = false) => {
        let tempCodeBlock = JSON.parse(JSON.stringify(codeBlock));
        tempCodeBlock[fieldName]['VALUE'] = value;
        setCodeBlock({ ...tempCodeBlock })
        setCodeBlockError({
            ...codeBlockError,
            [fieldName]: {
                status: (value || value === 0 || !isFieldRequired) ? false : true,
                help: (value || value === 0 || !isFieldRequired) ? null : COMMON_TEXT.EMPTY_STRING
            }
        })
    }

    /**
     * @param {boolean} isChecked is user-adjustable 
     * @param {string} fieldName name of the field
     * @description handles if parameter is user-adjustable
     */
    const handleCheckChange = (isChecked, fieldName) => {
        let tempCodeBlock = JSON.parse(JSON.stringify(codeBlock));
        tempCodeBlock[fieldName]['ADJUSTABLE'] = isChecked;
        setCodeBlock(tempCodeBlock)
    }

    /**
     * @description on Update button clicked
     */
    const handleOnUpdateClick = () => {
        if (isValid) {
            onCodeChange({ ...codeBlock })
            setDrawerState(false)
        }
    }

    const fields = BAR_DRAWER_STATE[barType]

    return (
        <div className="drawer-body">
            <div className="property-header">{COMMON_TEXT.PROPERTIES}</div>
            <div className="property-body">
                <Form>
                    {fields && fields.map(field => {
                        if (field.DATATYPE === BAR_DATATYPES.ENUM) {
                            return codeBlock[field.NAME] && <SelectField key={field.NAME} label={field.NAME}
                                data={field.DATA}
                                checked={codeBlock[field.NAME].ADJUSTABLE}
                                onCheckChange={(event) => handleCheckChange(event.target.checked, field.NAME)}
                                value={codeBlock[field.NAME].VALUE}
                                help={codeBlockError[field.NAME] && codeBlockError[field.NAME].help}
                                error={codeBlockError[field.NAME] && codeBlockError[field.NAME].status}
                                onChange={(key) => handleFieldChange(key, field.NAME)}
                                content={popOverContent[field.NAME]} />
                        }

                        if (field.DATATYPE === BAR_DATATYPES.STRING) {
                            return codeBlock[field.NAME] && <BarInputField key={field.NAME} label={field.NAME}
                                content={popOverContent[field.NAME]}
                                checked={codeBlock[field.NAME].ADJUSTABLE}
                                value={codeBlock[field.NAME].VALUE}
                                help={codeBlockError[field.NAME] && codeBlockError[field.NAME].help}
                                error={codeBlockError[field.NAME] && codeBlockError[field.NAME].status}
                                onChange={(event) => handleFieldChange(event.target.value, field.NAME, field.REQUIRED)}
                                onCheckChange={(event) => handleCheckChange(event.target.checked, field.NAME)} />
                        }

                        if (field.DATATYPE === BAR_DATATYPES.INTEGER
                            || field.DATATYPE === BAR_DATATYPES.DOUBLE) {
                            return codeBlock[field.NAME] && <BarInputField key={field.NAME} label={field.NAME}
                                content={popOverContent[field.NAME]}
                                checked={codeBlock[field.NAME].ADJUSTABLE}
                                onCheckChange={(event) => handleCheckChange(event.target.checked, field.NAME)}
                                value={codeBlock[field.NAME].VALUE}
                                step={field.DATATYPE === BAR_DATATYPES.INTEGER ? STEP.INTEGER : STEP.FLOAT}
                                typeOfField={field.DATATYPE !== BAR_DATATYPES.STRING && 'number'}
                                help={codeBlockError[field.NAME] && codeBlockError[field.NAME].help}
                                error={codeBlockError[field.NAME] && codeBlockError[field.NAME].status}
                                integerOnly={field.DATATYPE === BAR_DATATYPES.INTEGER}
                                onChange={(event) => handleFieldChange(event.target.value, field.NAME, field.REQUIRED)} />
                        }
                    })}
                </Form>
            </div>
            <UpdateButton onUpdate={handleOnUpdateClick} />
        </div>
    )
}

/**
 * @description Select Field Component for data of type ENUM
 */
export function SelectField(props) {
    return (
        <div className="option-style" id="bar-block">
            <div className="bar-field">
                <div className="bar-label">{props.label}</div>
                <Popover content={props.content && props.content}>
                <img style={{height:'15px',maxWidth:'100%'}} src={require('../../../assets/robotasset/message-question.png')} alt="" />
                </Popover>
                {typeof props.checked !== 'undefined'
                    && <Tooltip placement="right" title={POPOVER_USER_ADJUSTABLE}>
                        <Checkbox className="bar-checkbox"
                            checked={props.checked}
                            onChange={props.onCheckChange} />
                    </Tooltip>}
            </div>
            <Form.Item className="bar-form-item"
                help={props.help && props.help}
                hasFeedback={props.error} >
                <Select
                    placeholder='Select'
                    className="theme-text bar-select"
                    onChange={props.onChange}
                    showArrow={true}
                    value={props.value}>
                    {Object.keys(props.data).map((item, index) =>
                        <Option key={`${item}${index}`} value={props.data[item]}>
                            {item}
                        </Option>
                    )}
                </Select>
            </Form.Item>
        </div>
    )
}

/**
 * @description Input Field Component for data of type Integer, String, Float/Double
 */
export function BarInputField(props) {
    return (
        <div className="option-style" id="bar-block">
            <div className="bar-field">
                <div className="bar-label">{props.label}</div>
                <Popover content={props.content && props.content}>
                <img style={{height:'15px',maxWidth:'100%'}} src={require('../../../assets/robotasset/message-question.png')} alt="" />
                </Popover>
                {typeof props.checked !== 'undefined'
                    && <Tooltip placement="right" title={POPOVER_USER_ADJUSTABLE}>
                        <Checkbox className="bar-checkbox"
                            checked={props.checked}
                            onChange={props.onCheckChange} />
                    </Tooltip>}
            </div>
            <Form.Item className="bar-form-item"
                validateStatus={props.error ? 'error' : 'success'}
                help={props.help && props.help} >
                <Input placeholder={props.label}
                    type={props.typeOfField ? props.typeOfField : ''}
                    value={props.value}
                    step={props.step}
                    onChange={props.onChange}
                    onKeyDown={(event) => props.integerOnly
                        && event.key === '.'
                        && event.preventDefault()} />
            </Form.Item>
        </div>
    )
}
import React, { useState } from "react";
import DateTimePicker from "react-datetime-picker";
import Cop from "../../assets/robotasset/Calendar.png";
import Cancel from "../../assets/robotasset/cancel.png";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";


const Cicon = () => {
  return <img style={{ height: "25px", width: "25px" }} src={Cop} alt="" />;
};
const Ccancelicon = () => {
  return <img style={{ height: "15px", width: "15px" }} src={Cancel} alt="" />;
};

const DateTimeComponent = ({date,setDate}) => {

  const handleChange = (newDate) => {

    if (newDate) {
      setDate(newDate);
    }else{
        setDate(null)
    }
  };

  return (
    <>
      <DateTimePicker
        className="date-pick"
        onChange={handleChange}
        value={date}
        format="y-MM-dd HH:mm"
        placeholder="Select a date and time"
        calendarIcon={date?null :<Cicon />}
        clearIcon={date? <Ccancelicon /> :null}
        hourPlaceholder='HH'
        minutePlaceholder='MM'
        dayPlaceholder='DD'
        monthPlaceholder='MM'   
        yearPlaceholder="YYYY"
        autoFocus={false}
      />
    </>
  );
};

export default DateTimeComponent;

import PropTypes from "prop-types";
import React from "react";
import { Select } from "antd";

const { Option } = Select;

/*
 * dropdown for dashboard to filter event Type, fee type and event status
 */
export default function SelectDropDown (props) {
    const {optionsList, placeholder, handleChange, value, styleClass} = props;
    return (
        <Select 
            placeholder={placeholder}
            onChange={handleChange}
            showSearch={false}
            className={`height-50 ${styleClass}`}
            showArrow={true}
            value = {value!== "" ? value : placeholder}
        >
            {
                optionsList && optionsList.map(option => {
                    return (
                        <Option key={option} value = {option}>    
                            {option}
                        </Option>
                        )
                })
            }
        </Select>
    )
}
SelectDropDown.propTypes = {
    optionsList:PropTypes.array.isRequired,
    placeholder:PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.any,
}

import React from "react";
import { ReactComponent as Background } from "./Svg.svg";
import "./payment.css";
import { Button } from "antd";
import SelectDropDown from "../commonComponent/selectDropdown";
import Nano from "../../assets/robotasset/nanobot.png";
import Mate from "../../assets/robotasset/materobot.png";
import Magaton from "../../assets/robotasset/magton.png";
import { BRONZE, SILVER } from "../../constants/messages";

const Ptype = (props) => {
  const { type, price, content, options, styleclassNamename } = props;
  return (
    <section>
      {/* <div className="pricing pricing-palden">
        <div className="pricing-item">
          <div className={`pricing-deco ${type}`}>
            <Background />
            <div className="pricing-price">
              {price === "Free" ? (
                ""
              ) : (
                <span className="pricing-currency">$</span>
              )}
              {price && <>{price === "Free" ? "Free" : `${price}`}</>}
              {price === "Free" ? (
                ""
              ) : (
                <span className="pricing-period">/ mo</span>
              )}
            </div>
            <h3 className="pricing-title">({type})</h3>
          </div>
          <ul className="pricing-feature-list">
            <li className="pricing-feature">
              {price === "Free" ? (
                <div style={{ height: 50 }}></div>
              ) : (
                <SelectDropDown
                  optionsList={[`US $${price || ""} Billed Monthly`]}
                  styleClass="theme-text selectBox height-50 full-width"
                  value={`US $${price || ""} Billed Monthly`}
                  placeholder=""
                  handleChange={() => {}}
                />
              )}
            </li>
            <li>{content}</li>
            <li className="pricing-feature">
              <button
                onClick={() =>
                  props.onSubmit
                    ? props.onSubmit(type, price)
                    : props.history.push("/login")
                }
                className="pricing-action"
              >
                {price === "Free" ? "Sign up" : "Buy Now"}
              </button>
            </li>
            <li className="pricing-feature">
              <div className="payment-footer">
                {options.map((data, index) => {
                  return (
                    <div
                      className={!data.allowed ? "disable-text" : undefined}
                      key={index}
                    >
                      <span className={`${!data.allowed && "cross-mark"}`}>
                        {" "}
                        {data.allowed ? `✓ ` : "✗ "}
                      </span>
                      {data.content}
                    </div>
                  );
                })}
              </div>
            </li>
          </ul>
        </div>
      </div> */}
      <div className="paymeny_card_main">
        <div className="pay_card_main">
          <img
            src={type === BRONZE ? Nano : type === SILVER ? Mate : Magaton}
            alt=""
          />
          <div className="bottom_card">
            <div className="card_price">
              ${price}
              <span>/Month</span>
            </div>
            <div className="card_details">
              {options.map((data, index) => {
                return (
                  <div
                    className={!data.allowed ? "disable-text" : undefined}
                    key={index}
                  >
                    <span
                      className={`${!data.allowed && "cross-mark"}`}
                      style={{ marginRight: "5px" }}
                    >
                      {" "}
                      {data.allowed ? `✓ ` : "✗ "}
                    </span>
                    {data.content}
                  </div>
                );
              })}
            </div>
            <div className="pay_btn_card">
              <button
                onClick={() =>
                  props.onSubmit
                    ? props.onSubmit(type, price)
                    : props.history.push("/login")
                }
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ptype;

import { APIService, requestURLS } from "../constants/APIConstant";
import { ERROR, SUCCESS } from "../constants/messages";
import { call, put, takeLatest } from "redux-saga/effects";

import { actionLoginTypes } from "../constants/actionTypes";
import { actionPaymentTypes } from "../constants/actionTypes";
import {checkResponse} from "../actions/commonActions";
import { message } from "antd";
import { refreshToken } from "./userSaga";

/**
 * get plan
 * @param {callback} param
 * 
 * 
 */
export function* fetchPaymentPlans(param) {
  const { callback } = param;
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    let getURL = APIService.dev + requestURLS.PAYMENT_PLANS;
    let responseObject = {};
    yield put({ type: actionPaymentTypes.PAYMENT_FETCHING });
    let responseJson = yield fetch(getURL, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      responseObject = response;
      return response.json();
    });
    checkResponse(responseObject, responseJson);
    if (responseJson.message === ERROR)
    {
      callback(responseJson.message)
      yield put({ type: actionPaymentTypes.PAYMENT_FETCHING });
    }
    else{
      callback(SUCCESS)
      yield put({
        type: actionPaymentTypes.PLANS_RECIEVED,
        payload: responseJson,
      });
    }
  } catch (e) {
    if(e.ResponseCode===401){
      localStorage.clear();
        message.error('Token expired, please login again');
        setTimeout(() => {
          // window.location.replace("/login");
        }, 2000);
    }
    message.error(e.message);
    yield put({ type: actionPaymentTypes.PAYMENT_FETCHING });
  }
}

/**
 * post stripe payment
 * @param {data, callback} param
 * 
 * data: user contain token, product and user
 */
export function* postStripePayment(param) {
  const { data, callback } = param;
  try {
    let recievedResponse = {};
    yield put({ type: actionPaymentTypes.PAYMENT_FETCHING });

    const getUrl = APIService.dev + requestURLS.STRIPE_PAYMENT;
    let headers = {
      "Content-Type": "application/json",
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }

    yield put({
      type: actionPaymentTypes.PAYMENT_RECEIVED,  // received login cred over here
      payload: responseJSON.data,
    });

    yield put({
      type: actionLoginTypes.USER_RECIEVED,
      payload: responseJSON.data,
    });

    callback(responseJSON.message, responseJSON.data)
    
  } catch (e) {
    yield put({
      type: actionPaymentTypes.PAYMENT_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}
/**
 * post strip[e robot subscribe
 */

export function* postStripeRobotPayment(param) {
  const { data,accessToken, callback } = param;
  try {
    let recievedResponse = {};
    yield put({ type: actionPaymentTypes.PAYMENT_FETCHING });

    const getUrl = APIService.dev + requestURLS.STRIPE_PAYMENT_ROBOT;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }

    yield put({
      type: actionPaymentTypes.PAYMENT_RECEIVED,  // received login cred over here
      payload: responseJSON.data,
    });
    callback(SUCCESS, responseJSON.data)
    
  } catch (e) {
      if(e.ResponseCode===401){
        yield put({ type: actionLoginTypes.TOKEN_EMPTY });
       const newtoken= yield call(refreshToken);
       let recievedResponse = {};
       yield put({ type: actionPaymentTypes.PAYMENT_FETCHING });
   
       const getUrl = APIService.dev + requestURLS.STRIPE_PAYMENT_ROBOT;
       let headers = {
         "Content-Type": "application/json",
         Authorization: `Bearer ${newtoken}`,
       };
       const responseJSON = yield fetch(getUrl, {
         headers: headers,
         method: "POST",
         body: JSON.stringify(data),
       }).then((response) => {
         recievedResponse = response;
         return response.json();
       });
       if (!recievedResponse.ok) {
         throw responseJSON;
       }
   
       yield put({
         type: actionPaymentTypes.PAYMENT_RECEIVED,  // received login cred over here
         payload: responseJSON.data,
       });
       callback(SUCCESS, responseJSON.data)
      }
    yield put({
      type: actionPaymentTypes.PAYMENT_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}


/**
 * post stripe payment
 * @param {data, callback} param
 * 
 * data: user contain token, product and user
 */
export function* postPayPalPayment(param) {
  const { data, callback } = param;
  try {
    let recievedResponse = {};
    yield put({ type: actionPaymentTypes.PAYMENT_FETCHING });

    const getUrl = APIService.dev + requestURLS.PAYPAL_PAYMENT;
    let headers = {
      "Content-Type": "application/json",
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({
      type: actionPaymentTypes.PAYMENT_RECEIVED,  // received login cred over here
      payload: responseJSON.data,
    });
    yield put({
      type: actionLoginTypes.USER_RECIEVED,
      payload: responseJSON.data,
    });
    callback(responseJSON.message, responseJSON.data)
  } catch (e) {
    if(e.ResponseCode===401){
      localStorage.clear();
        message.error('Token expired, please login again');
        setTimeout(() => {
          // window.location.replace("/login");
        }, 2000);
    }
    yield put({
      type: actionPaymentTypes.PAYMENT_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}
/**
 * paypal robot payment
 * @param {*} param 
 */
 export function* postPayPalRobotPayment(param) {
  const { data, accessToken, callback } = param;
  try {
    let recievedResponse = {};
    yield put({ type: actionPaymentTypes.PAYMENT_FETCHING });

    const getUrl = APIService.dev + requestURLS.PAYPAL_ROBOT_PAYMENT;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({
      type: actionPaymentTypes.PAYMENT_RECEIVED,  // received login cred over here
      payload: responseJSON.data,
    });
    callback(SUCCESS, responseJSON.data)
  } catch (e) {
    if(e.ResponseCode===401){
        yield put({ type: actionLoginTypes.TOKEN_EMPTY });
        const newtoken= yield call(refreshToken);
        let recievedResponse = {};
    yield put({ type: actionPaymentTypes.PAYMENT_FETCHING });

    const getUrl = APIService.dev + requestURLS.PAYPAL_ROBOT_PAYMENT;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${newtoken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({
      type: actionPaymentTypes.PAYMENT_RECEIVED,  // received login cred over here
      payload: responseJSON.data,
    });
    callback(SUCCESS, responseJSON.data)
    }
    yield put({
      type: actionPaymentTypes.PAYMENT_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}

export function* updatePayPalPayment(param) {
  const { data, callback, accessToken, method } = param;
  try {
    let recievedResponse = {};
    yield put({ type: actionPaymentTypes.PAYMENT_FETCHING });
    let getUrl = ""
    if(method === "paypal")
    {
        getUrl = APIService.dev + requestURLS.UPDATE_PAYPAL_PAYMENT;
    }
    else if(method === "stripe")
    {
         getUrl = APIService.dev + requestURLS.UPDATE_STRIPE_PAYMENT;
    }
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    localStorage.setItem('is_coupon_used',responseJSON.data?.is_coupon_used)
    yield put({
      type: actionPaymentTypes.PAYMENT_RECEIVED, 
      payload: responseJSON.data,
    });
    yield put({
      type: actionLoginTypes.USER_PAYMENT_RECEIVED,
      payload: responseJSON.data,
    });
    callback(SUCCESS, responseJSON)
  } catch (e) {
    if(e.ResponseCode===401){
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      const newtoken= yield call(refreshToken);
      let recievedResponse = {};
      yield put({ type: actionPaymentTypes.PAYMENT_FETCHING });
      let getUrl = ""
      if(method === "paypal")
      {
          getUrl = APIService.dev + requestURLS.UPDATE_PAYPAL_PAYMENT;
      }
      else if(method === "stripe")
      {
           getUrl = APIService.dev + requestURLS.UPDATE_STRIPE_PAYMENT;
      }
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newtoken}`,
      };
      const responseJSON = yield fetch(getUrl, {
        headers: headers,
        method: "POST",
        body: JSON.stringify(data),
      }).then((response) => {
        recievedResponse = response;
        return response.json();
      });
  
      if (!recievedResponse.ok) {
        throw responseJSON;
      }
      localStorage.setItem('is_coupon_used',responseJSON.data?.is_coupon_used)
      yield put({
        type: actionPaymentTypes.PAYMENT_RECEIVED, 
        payload: responseJSON.data,
      });
      yield put({
        type: actionLoginTypes.USER_PAYMENT_RECEIVED,
        payload: responseJSON.data,
      });
      callback(SUCCESS, responseJSON)
    }
    yield put({
      type: actionPaymentTypes.PAYMENT_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}

export function* cancelSubscription(param) {
  const { accessToken, callback } = param;
  try {
    let recievedResponse = {};
    yield put({ type: actionPaymentTypes.PAYMENT_FETCHING });

    const getUrl = APIService.dev + requestURLS.CANCEL_SUBSCRIPTION;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    callback(responseJSON.data)
    yield put({ type: actionPaymentTypes.PAYMENT_FETCHING });
    yield put({ type: actionPaymentTypes.SUBSCRIPTION_CANCELLED ,payload:responseJSON.data});
  } catch (e) {
    if(e.ResponseCode===401){
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      const newtoken= yield call(refreshToken);
      let recievedResponse = {};
      yield put({ type: actionPaymentTypes.PAYMENT_FETCHING });
  
      const getUrl = APIService.dev + requestURLS.CANCEL_SUBSCRIPTION;
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newtoken}`,
      };
      const responseJSON = yield fetch(getUrl, {
        headers: headers,
        method: "GET",
      }).then((response) => {
        recievedResponse = response;
        return response.json();
      });
      if (!recievedResponse.ok) {
        throw responseJSON;
      }
      callback(responseJSON.data)
      yield put({ type: actionPaymentTypes.PAYMENT_FETCHING });
      yield put({ type: actionPaymentTypes.SUBSCRIPTION_CANCELLED ,payload:responseJSON.data});
    }
    yield put({
      type: actionPaymentTypes.PAYMENT_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}


export function* paymentActionWatcher() {
  yield takeLatest(actionPaymentTypes.GET_PLANS, fetchPaymentPlans);
  yield takeLatest(actionPaymentTypes.POST_STRIPE_PAYMENT, postStripePayment);
  yield takeLatest(actionPaymentTypes.POST_PAYPAL_PAYMENT, postPayPalPayment);
  yield takeLatest(actionPaymentTypes.CANCEL_SUBSCRIPTION, cancelSubscription);
  yield takeLatest(actionPaymentTypes.UPDATE_PAYPAL_SUBSCRIPTION, updatePayPalPayment);
  yield takeLatest(actionPaymentTypes.POST_STRIPE_ROBOT_PAYMENT, postStripeRobotPayment);
  yield takeLatest(actionPaymentTypes.POST_PAYPAL_ROBOT_PAYMENT,postPayPalRobotPayment);
}

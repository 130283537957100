import "./sideBanner.css";

import React from "react";
import WebFont from 'webfontloader';
import { ConfigeUI } from "../../configeUI/ConfigeUI";

WebFont.load({
  google: {
    families: ['Montserrat:300,400,700', 'sans-serif']
  }
});
/*
 * dropdown for dashboard to filter event Type, fee type and event status
 */
export default function SideBanner() {

  const webImages = ConfigeUI();

  return (
    <div className="side-banner-container">
      <img alt="logo" className="side_logo" src={require('../../assets/robotasset/leftnanne.png')} />
      {/* <img alt="logo" className="side_logo" src={webImages.sidelogo} />
      <div className="discription-style label">
        <ul>
          <li>Create automated trading robots</li>
          <li>Absolutely no coding required</li>
          <li>Generate robots in minutes</li>
          <li>Runs on FREE MT4/MT5 Platforms</li>
        </ul>
      </div> */}
    </div>
  );
}

import { APIService, requestURLS } from "../constants/APIConstant";
import {checkResponse} from "../actions/commonActions";
import { put, takeLatest } from "redux-saga/effects";
import { actionQuestionTypes } from "../constants/actionTypes";
import { message } from "antd";

export function* fetchQuestions() {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    let getURL = APIService.dev + requestURLS.FEEDBACK_OPERATIONS;
    let responseObject = {};
    let responseJson = yield fetch(getURL, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      responseObject = response;
      return response.json();
    });

    checkResponse(responseObject, responseJson);

    yield put({
      type: actionQuestionTypes.FETCHED_QUESTIONS,
      payload: responseJson,
    });
  } catch (e) {
    yield put({
      type: actionQuestionTypes.QUESTIONS_ERROR,
      error: e,
    });
    message.error(e.message);
  }
}
export function* questionActionWatcher() {
  yield takeLatest(actionQuestionTypes.QUESTIONS, fetchQuestions);
}

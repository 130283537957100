// Scripts
export const MQ4 = ".mq4";
export const EX4 = ".ex4";
export const DOWNLOAD_EX4 = "Download .ex4 executable (MT4)";
export const DOWNLOAD_EX5 = "Download .ex5 executable (MT5)";
export const DOWNLOAD_MQ4 = "Download .mq4 source code";
export const DOWNLOAD_MQ5 = "Download .mq5 source code";
export const Access_AI= "Access to AI engine";

//Payments
export const LIMITED_FILTERS = "Limited filters";
export const ALL_FILTERS = "Access to All Filters";
export const AI_ = "Access to AI Engine";
export const FREE_CONTENT = "Start creating your fully automated trading robots in minutes with our completely FREE plan!"
export const BRONZE_CONTENT = "The BRONZE plan contains all the features of the FREE plan plus the additional ones below."
export const SILVER_CONTENT = "The SILVER plan contains all the features of the BRONZE plan plus the additional ones below."
export const CANCEL_SUBSCRIPTION = "Your subcription has been cancelled. However, you can continue using it for any remaining period.";
export const BRONZE = "nanobot"
export const GOLD = "megatron"
export const UPGRADE = "Upgrade Subscription"
export const UPGRADE_TO_BRONZE = "Upgrade Subscription"
export const UPGRADE_OR = "Upgrade or Downgrade Subscription"
export const DOWNGRADE = "Downgrade to Bronze"
export const DOWNGRADES = "Downgrade Subscription"
export const CANCEL_SUBSCRIPTION_BUTTON = "Cancel Subscription";
export const SILVER = "robomate"
export const CANCELLED_SUBSCRIPTION_CONTENT1 = "Your subscription has been cancelled"
export const CANCELLED_SUBSCRIPTION_CONTENT2 = "Please wait for the billing cycle to end and subscribe again"
export const CANCEL_SUBSCRIPTION_CONTENT1 = "You will not be able to access robot maker from the next billing cycle."
export const CANCEL_SUBSCRIPTION_CONTENT2 = "Are you sure you want to cancel the subscription?"
export const PAYMENT_FAIL = "Oops! Payment was not successful. Please try again."
export const PAYMENT_SUCCESS = "Congratulations! Payment was successful!"
export const PAYMENT_CONTENT1 = "You are just one step away from accessing your automated trading workspace. Please select your subscription plan and get access to our online, cloud-based, Drag and Drop Robot Creation Software."
export const PAYMENT_HEADER = "Select payment method";
export const PLAN_SUBSCRIPTION_ERROR = "Payment plan prices are not in sync, Please contact admin."
export const MODAL_HEADER_PAYMENT_SYNC_ERROR = 'Payment sync error'

//modal content
export const MODAL_HEADER_PAYMENT_UPDATE = "Payment Plan Updates";
export const MODAL_HEADER_CURRENT_BILLING_CYCLE = "Current Billing Cycle - ";

export const MODAL_HEADER_CANCEL_SUBSCRIPTION = "Cancel Subscription";
export const MODAL_HEADER_CONFIRMATION = "Confirmation";

export const MODAL_HEADER_UNSAVED = "Unsaved Changes";
export const MODAL_HEADER_UNSAVED_CONTENT = "You have some unsaved changes, please save them before moving on";
export const MODAL_HEADER_SAVE = "Save your robot as";
export const MODAL_DELETE_ROBOT = "Are you sure you want to delete this? ";

//button
export const SAVE = "Save";
export const CANCEL = "Cancel";
export const PROCEED_ANYWAY = "Proceed Anyway";
export const DELETE = "Delete";
export const PROCEED = "Proceed";
export const UPDATE = "Update";
export const  HIDE_FREE_TRIAL= "Your free trial is over. Please upgrade your subscription.";


//workspace
export const WORKSPACE_CREATED = "New workspace created!";
export const UNTITLED_ROBOT = "Untitled Robot";

//Validations
export const PASSWORD_INFO =
  "Password must be of length 8-50 characters with one lower case, one upper case and one numeric character.";
export const PASSWORD_REQUIRED = "Please enter your password";
export const ACC_REQUIRED = "Please enter your Account number";
export const SERVER_REQUIRED = "Please enter your server name";
export const ANSWER_REQUIRED = "Please enter your answer";
export const NAME_REQUIRED = "Please enter your name";
export const ROBOT_NAME_REQUIRED ="Please enter robot name";
export const EMAIL_REQUIRED = "Please enter a valid email";
export const USERNAME_REQUIRED = "Please enter a valid username";
export const INVALID_PASSWORD =
  "Password doesn't meet all the given requirements";
export const CONFIRM_PASSWORD = "Please confirm your password";
export const PASSWORD_DO_NOT_MATCH = "Passwords do not match";
export const CODE_VERIFIY = "inactive";
export const USER_NOTFOUND ="No active account found with the given credentials";
export const CODE_VERIFIY_MESSAGE = "Please verify your email via OTP.";


//MAGIC NO
export const XML_INITAL_LEN = 61;

export const NUMBERSVALIDATION = /^\d+$/;
export const ONLY_NUMERIC = "Must contain positive numeric values only"


//CODE TYPE
export const INIT = 'On Init';
export const TICK = 'On Tick';
export const ONBAR = 'On Bar';

export const EMAIL_CHANGED = "Email changed successfully"
export const DATE_FORMAT = "DD, MMM 'YY";
export const THEME_COLOR = "var(--primary-color)";


// user
export const USER_EXISTS = "User already exists. Please try to login";
export const LOGIN = "Login";
export const VERIFY_EMAIL = "VERIFY E-mail";
export const LOADER = "Loading...";
export const CONFIRM = "Confirm";
export const NEXT = "Next";
export const SIGN_UP = "Sign Up";
export const FORGOT_PASSWORD = "Forgot Password?";
export const SIGN_UP_HEADER = "Sign up and get your automated trading account";
export const SET_SECURITY_QUE = "Set Security Question";
export const SECURITY_QUE_ERROR = "Oops! At least two answers should match";
export const PASSWORD_UPDATED = "Password updated successfully";

export const SUCCESS = "success"
export const ERROR = "error"

//mobile
export const MOBILE_HEADER = "Screen Too Small";
export const MOBILE_CONTENT = "Looks like you are using the application on a small screen, Please switch to bigger screen for better experience"

//robot store
// export const ROBOT_SUBSCRIPTION = "New Robot Subcription";
export const ROBOT_FEATURES = "Featured Robots";
export const ROBOT_FREE="Free Robots";
export const ROBOT_SUB_STORE = "ROBOT SUBSCRIPTION STORE";
export const PER_MONTH = "/Month";
export const DOLLAR = "$";
export const MY_ROBOT = "My Robots";
export const EDIT_ROBOT = "EDIT ROBOT";
export const RENT_ROBOT = "Rent your Robots and Make Money";
export const SUBSCRIBED_ROBOT = "My Subscribed Robot";
export const ROBOT_STORE = "Robot Marketplace";
export const REVIEW = "Reviews";

//Robot Button

export const ROBOT_SUBSCRIBE = "SUBSCRIBE";
export const PROCEED_PAY = "Proceed to Pay";
export const BIG = "400px";
export const NORMAL = "300px";
export const MEDIUM = "250px";
export const SMALL = "110px";
export const UPLOAD = "Uploaded images";
export const DOWNLOAD_BUTTON = "Download";
export const DOWNLOAD_BUTTON_EX4 = "Download .ex4";
export const DOWNLOAD_BUTTON_EX5 = "Download .ex5";
export const EDIT_BUTTON = "Edit";
export const BACK_BUTTON = "Back";

//robot form text

export const ROBOT_NAME = "Robot name";
export const ROBOT_DESCRIPTION = "Description";
export const ROBOT_RENT = "Monthly price";
export const ROBOT_ENABLE_FOR_RENT = "Enabled for rent";
export const EDIT_NOTE = ": A fee of 10% applies. Your account will be credited monthly net of this fee and excluding GST.";
export const EDIT_NOTES = "Note";

//product price

export const ROBOT_PRODUCT_PLAN={
  10:{stripe_plan: process.env.REACT_APP_STRIPE_ROBOT_PLAN_10, payPal_plan: process.env.REACT_APP_PAYPAL_ROBOT_PLAN_10},
   20:{ stripe_plan: process.env.REACT_APP_STRIPE_ROBOT_PLAN_20, payPal_plan: process.env.REACT_APP_PAYPAL_ROBOT_PLAN_20 },
   30:{ stripe_plan: process.env.REACT_APP_STRIPE_ROBOT_PLAN_30, payPal_plan: process.env.REACT_APP_PAYPAL_ROBOT_PLAN_30 },
   40:{ stripe_plan: process.env.REACT_APP_STRIPE_ROBOT_PLAN_40, payPal_plan: process.env.REACT_APP_PAYPAL_ROBOT_PLAN_40},
   50:{ stripe_plan: process.env.REACT_APP_STRIPE_ROBOT_PLAN_50, payPal_plan: process.env.REACT_APP_PAYPAL_ROBOT_PLAN_50},
   60:{ stripe_plan: process.env.REACT_APP_STRIPE_ROBOT_PLAN_60, payPal_plan: process.env.REACT_APP_PAYPAL_ROBOT_PLAN_60},
   70:{ stripe_plan: process.env.REACT_APP_STRIPE_ROBOT_PLAN_70, payPal_plan: process.env.REACT_APP_PAYPAL_ROBOT_PLAN_70 },
   80:{ stripe_plan: process.env.REACT_APP_STRIPE_ROBOT_PLAN_80, payPal_plan: process.env.REACT_APP_PAYPAL_ROBOT_PLAN_80 },
  90:{ stripe_plan:  process.env.REACT_APP_STRIPE_ROBOT_PLAN_90, payPal_plan: process.env.REACT_APP_PAYPAL_ROBOT_PLAN_90 },
  100:{ stripe_plan: process.env.REACT_APP_STRIPE_ROBOT_PLAN_100, payPal_plan:process.env.REACT_APP_PAYPAL_ROBOT_PLAN_100},
}

//nav button
export const STORE_BUTTON = "Robot subscription store";
export const RENT_BUTTON = "Rent your robots and make money";
export const CANCEL_ROBOT_SUBSCRIPTION = "Your robot subcription has been cancelled.";
export const ACCOUNT_UPDATED = "Account details updated.";

//payment robot Content
export const ROBOT_NAME_SUBSCRIPTION = "Subscription";
export const PAYMENT_METHOD = "Payment method";
export const PAYMENT_STRIPE = "Pay now with Stripe";
export const PAYMENT_PAYPAL = "Pay now with PapPal";
export const SUMMARY = "Summary";
export const MY_SUB_ROBOT_BUTTON = "My subscribed Robots";
export const TAX_CHARGE=0.2;
export const SAVE_RATING="Save Rating";
export const NOT_AVAILABLE="Not available";
export const No_Subscribe_Robot="You have no subscribed robots. Please click on the \"Robot Marketplace\" button to subscribe to robots."

import { actionMySubRobotTypes } from "../constants/actionTypes";

export const getMySubRobot = ({ id, accessToken, callback }) => {
  return {
    type: actionMySubRobotTypes.GET_MY_SUB_ROBOT,
    id: id,
    accessToken: accessToken,
    callback: callback
  };
};


export const getAllMySubRobotList = ({ accessToken, callback }) => {
  return {
    type: actionMySubRobotTypes.GET_ALL_MY_SUB_ROBOT,
    accessToken: accessToken,
    callback: callback
  };
};

export const cancelRobotSubscriptionApi = ({ id, accessToken, callback }) => {
  return {
    type: actionMySubRobotTypes.CANCEL_MY_ROBOT_SUBSCRIPTION,
    id: id,
    accessToken: accessToken,
    callback: callback
  };
}
export const postBrokerAccountApi = ({ data, accessToken, callback }) => {
  return {
    type: actionMySubRobotTypes.MY_SUB_ROBOT_EDIT_BROKER_ACCOUNT,
    data: data,
    accessToken: accessToken,
    callback: callback
  };
}
export const postRobotRating = ({ id,user_id,data, accessToken, callback }) => {
  return {
    type: actionMySubRobotTypes.POST_ROBOT_RATING,
    data: data,
    id:id,
    user_id:user_id,
    accessToken: accessToken,
    callback: callback
  };
}
export const getMySubRobotRating = ({ id, accessToken, callback }) => {
  return {
    type: actionMySubRobotTypes.GET_ROBOT_RATING,
    id: id,
    accessToken: accessToken,
    callback: callback
  };
};
export const downloadFile = ({accessToken,id, type, data, name}) => {
  return {
    type: actionMySubRobotTypes.DOWNLOAD_FILE,
    accessToken: accessToken,
    typeExt: type,
    data: data,
    name: name,
    id:id,
  };
};


import React from 'react';
import './subscription.css';
import { Rate, Typography, Col, Row, Avatar } from 'antd';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';

export default function ReviewCard(props) {
    const { item } = props;

    const { Text } = Typography;
    const Date = moment(item.updated_at).format('MMMM, DD, YYYY ');

    return (
        <>
            <div className=" card_rating">
            <div className="d-flex">
                <Avatar
                    // size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                    style={{ backgroundColor: '#908686' }}
                    icon={<UserOutlined />}
                />
                <div className="r_head" style={{ display: 'flex', flexDirection: 'column',width:'100%',marginLeft:'15px' }}>
                    <h3>
                        {item.user__first_name}
                    </h3>
                    <div className="stars" style={{ display: 'flex', gap: '2%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Rate disabled value={item.rating} style={{ color: "#E4A70A" }}  />
                        <h5 style={{ margin: '0px' }}>{Date}</h5>
                    </div>
                </div>
            </div>
            <div className="" style={{color:'#A3AED0'}}>
                    {item.rating_reviews}
            </div>
            </div>

        </>

    )
}
const BASE_URL = `${process.env.REACT_APP_API_URL}api/`;

export const APIService = {
  dev: BASE_URL,
};
export const ImageBase = { dev: `${process.env.REACT_APP_API_URL}media/` };
export const requestURLS = {
  LOGIN: "user/login/",
  DIRECTION: "ai_engine/forecast/get_by_time/",
  REGISTER: "users/signup/",
  CHECK_USER: "users/check_user_exists/",
  RESEND_CODE: "users/resend-otp/",
  VERIFY_CODE: "users/verify-otp/",
  RESET_PASSWORD: "users/reset/password/",
  CHANGE_PASSWORD: "authentication/change-password/",
  USER_OPERATIONS: "core/user/",
  FEEDBACK_OPERATIONS: "questions/security/questions/",
  FEEDBACK_POST: "core/feedback/",
  SECURITY_QUE: "users/check/email/",
  WORKSPACE_POST: "robot/workspace/save/",
  GET_WORKSPACE: "robot/workspace/get/details/",
  GET_AI_DATA: "ai_engine/forecast/get/",
  GET_ALL_WORKSPACE: "robot/workspace/get/",
  WORKSPACE_DELETE: "robot/workspace/",
  PAYMENT_PLANS: "payment/mode/all/",
  STRIPE_PAYMENT: "payment/mode/stripe/checkout/",
  PAYPAL_PAYMENT: "payment/mode/paypal/status/",
  UPDATE_PAYPAL_PAYMENT: "payment/plan/change/subscription/paypal/",
  UPDATE_STRIPE_PAYMENT: "payment/plan/change/subscription/stripe/",
  GET_USER_SETTINGS: "settings/data/",
  LOGOUT_USERS: "users/logout/",
  POST_USER_SETTINGS: "settings/update/",
  CHANGE_EMAIL: "email/edit/",
  SAVE_FILE: "robot/workspace/file/",
  CANCEL_SUBSCRIPTION: "payment/plan/cancel/subscription/",
  GET_ALL_ROBOT_STORE: "robot/workspace/get/robots/",
  GET_ALL_ROBOT_RENT: "robot/workspace/get/user_robot_details/",
  GET_ALL_MY_SUB_ROBOT: "users/my-subscribed-robots/",
  GET_ROBOT_ID:"robot/workspace/get/robot/details/",
  EDIT_RENT_ROBOT:"robot/workspace/edit-robot/",
  STRIPE_PAYMENT_ROBOT:"payment/robotplan/robot/stripe/checkout/",
  PAYPAL_ROBOT_PAYMENT:"payment/robotplan/robot/paypal/checkout/",
  CANCEL_MY_ROBOT_SUBSCRIPTION:"payment/robotplan/cancel/subscription/",
  MY_SUB_ROBOT_EDIT_BROKER_ACCOUNT:"payment/robotplan/update/account_no/",
  GET_ROBOT_RATING:"robot/workspace/get/rating/",
  POST_ROBOT_RATING:"robot/workspace/ratings/",
  DOWNLOAD_FILE:"robot/workspace/subscribed-robot/file/",
  COPY_ROBOT:"robot/workspace/copy_robot/",
  COUPON_VERIFY:"payment/mode/verify_coupon/?coupon_code=",
  UPDATE_COUPON_VERIFY:"users/update/",
  TOKEN:'token/refresh/'
};

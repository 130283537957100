import { APIService, requestURLS } from "../constants/APIConstant";
import { ERROR, SUCCESS } from "../constants/messages";
import { actionLoginTypes, actionMySubRobotTypes, actionWorkspaceTypes } from "../constants/actionTypes";
import { put, takeLatest, takeEvery, call, fork, take } from "redux-saga/effects";
import { ifAccessTokenExpired } from "../actions/commonActions";
import { message } from "antd";
import { TranslateSubRobot } from "../components/commonComponent/translate";
import { getSubscribedRobotRatings, getMySubRobotListWithRatings } from '../api'
import { refreshToken } from "./userSaga";

export function* getMySubRobotRating(param) {
    const { id, accessToken, callback } = param;

    try {
        const responseJSON = yield call(getSubscribedRobotRatings, accessToken, id)
        if (responseJSON.message === SUCCESS) {
            yield put({
                type: actionMySubRobotTypes.RATING_RECEVIED,
                payload: responseJSON.data,
            });
        }
        if (responseJSON.message !== SUCCESS) {
            throw responseJSON.message;
        }
    } catch (e) {
        if(e.ResponseCode===401){
            yield put({ type: actionLoginTypes.TOKEN_EMPTY });
            const newtoken= yield call(refreshToken);
            const responseJSON = yield call(getSubscribedRobotRatings, newtoken, id)
            if (responseJSON.message === SUCCESS) {
                yield put({
                    type: actionMySubRobotTypes.RATING_RECEVIED,
                    payload: responseJSON.data,
                });
            }
            if (responseJSON.message !== SUCCESS) {
                throw responseJSON.message;
            }
          }
        yield put({
            type: actionMySubRobotTypes.MY_SUB_ROBOT_ERROR,
            error: e,
        });
        callback(ERROR, e);
    }
}

export function* cancelRobotSubscription(param) {
    const { id, accessToken, callback } = param;
    try {
        let recievedResponse = {};
        yield put({ type: actionMySubRobotTypes.FETCHED_MY_SUB_ROBOT });

        const getUrl = APIService.dev + requestURLS.CANCEL_MY_ROBOT_SUBSCRIPTION + '?id=' + id;
        let headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        };
        const responseJSON = yield fetch(getUrl, {
            headers: headers,
            method: "GET",
        }).then((response) => {
            recievedResponse = response;
            return response.json();
        });
        if (!recievedResponse.ok) {
            throw responseJSON;
        }
        callback(SUCCESS, responseJSON.data)
        yield put({ type: actionMySubRobotTypes.FETCHED_MY_SUB_ROBOT });
        yield put({ type: actionMySubRobotTypes.MY_ROBOT_SUBSCRIPTION_CANCELLED });
    } catch (e) {
        if(e.ResponseCode===401){
            yield put({ type: actionLoginTypes.TOKEN_EMPTY });
            const newtoken= yield call(refreshToken);
            let recievedResponse = {};
        yield put({ type: actionMySubRobotTypes.FETCHED_MY_SUB_ROBOT });

        const getUrl = APIService.dev + requestURLS.CANCEL_MY_ROBOT_SUBSCRIPTION + '?id=' + id;
        let headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${newtoken}`,
        };
        const responseJSON = yield fetch(getUrl, {
            headers: headers,
            method: "GET",
        }).then((response) => {
            recievedResponse = response;
            return response.json();
        });
        if (!recievedResponse.ok) {
            throw responseJSON;
        }
        callback(SUCCESS, responseJSON.data)
        yield put({ type: actionMySubRobotTypes.FETCHED_MY_SUB_ROBOT });
        yield put({ type: actionMySubRobotTypes.MY_ROBOT_SUBSCRIPTION_CANCELLED });
          }
        yield put({
            type: actionMySubRobotTypes.MY_SUB_ROBOT_ERROR,
            error: e,
        });
        if(e.ResponseCode!==401){
            callback(ERROR, e.message);
        }
    }
}


export function* postBrokerAccount(param) {
    let { accessToken, callback, data } = param;
    try {
        let recievedResponse = {};
        yield put({ type: actionMySubRobotTypes.SET_ALL_MY_SUB_ROBOT_FETCHING });

        const postUrl = APIService.dev + requestURLS.MY_SUB_ROBOT_EDIT_BROKER_ACCOUNT;
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        };
        const responseJSON = yield fetch(postUrl, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(data),
        }).then((response) => {
            recievedResponse = response;
            return response.json();
        });
        if (!recievedResponse.ok) {
            throw responseJSON;
        }
        yield put({
            type: actionMySubRobotTypes.MY_SUB_ROBOT_ALL_RECIEVED,
            payload: responseJSON.data,
        });
        callback(SUCCESS, responseJSON.data);
    } catch (e) {
        if(e.ResponseCode===401){
            yield put({ type: actionLoginTypes.TOKEN_EMPTY });
            const newtoken= yield call(refreshToken);
            let recievedResponse = {};
        yield put({ type: actionMySubRobotTypes.SET_ALL_MY_SUB_ROBOT_FETCHING });

        const postUrl = APIService.dev + requestURLS.MY_SUB_ROBOT_EDIT_BROKER_ACCOUNT;
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        };
        const responseJSON = yield fetch(postUrl, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(data),
        }).then((response) => {
            recievedResponse = response;
            return response.json();
        });
        if (!recievedResponse.ok) {
            throw responseJSON;
        }
        yield put({
            type: actionMySubRobotTypes.MY_SUB_ROBOT_ALL_RECIEVED,
            payload: responseJSON.data,
        });
        callback(SUCCESS, responseJSON.data);
          }
        yield put({
            type: actionMySubRobotTypes.MY_SUB_ROBOT_ERROR,
            error: e,
        });
       
        if(e.ResponseCode!==401){
            callback(ERROR, e.message);
        }
    }
}

export function* postRobotRating(param) {
    let { accessToken, callback, data, id, user_id } = param;
    if (id === undefined || id === null && user_id === undefined) {
        return;
    }

    try {
        let recievedResponse = {};
        yield put({ type: actionMySubRobotTypes.SET_ALL_MY_SUB_ROBOT_FETCHING });

        const postUrl = APIService.dev + requestURLS.POST_ROBOT_RATING + '?id=' + id + '&user_id=' + user_id;
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        };
        const responseJSON = yield fetch(postUrl, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(data),
        }).then((response) => {
            recievedResponse = response;
            return response.json();
        });
        if (!recievedResponse.ok) {
            throw responseJSON;
        }
        callback(SUCCESS, responseJSON.data);
        yield put({
            type: actionMySubRobotTypes.POST_ROBOT_RATING,
            payload: responseJSON.data,
        });
    } catch (e) {
        if(e.ResponseCode===401){
            yield put({ type: actionLoginTypes.TOKEN_EMPTY });
            const newtoken= yield call(refreshToken);
            let recievedResponse = {};
            yield put({ type: actionMySubRobotTypes.SET_ALL_MY_SUB_ROBOT_FETCHING });
    
            const postUrl = APIService.dev + requestURLS.POST_ROBOT_RATING + '?id=' + id + '&user_id=' + user_id;
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${newtoken}`,
            };
            const responseJSON = yield fetch(postUrl, {
                headers: headers,
                method: "POST",
                body: JSON.stringify(data),
            }).then((response) => {
                recievedResponse = response;
                return response.json();
            });
            if (!recievedResponse.ok) {
                throw responseJSON;
            }
            callback(SUCCESS, responseJSON.data);
            yield put({
                type: actionMySubRobotTypes.POST_ROBOT_RATING,
                payload: responseJSON.data,
            });
          }
        yield put({
            type: actionMySubRobotTypes.MY_SUB_ROBOT_ERROR,
            error: e,
        });
        callback(ERROR, e.message);
    }
}

export function* getAllMySubRobotList(param) {
    const { accessToken, callback } = param;

    try {
        yield put({ type: actionMySubRobotTypes.SET_ALL_MY_SUB_ROBOT_FETCHING });
        const response = yield call(getMySubRobotListWithRatings, accessToken)
        if (!response.ok) {
            throw response.data;
        }
        callback(SUCCESS, response);
        yield put({
            type: actionMySubRobotTypes.MY_SUB_ROBOT_ALL_RECIEVED,
            payload: response.data,
        });
    } catch (e) {
        if(e.status===401){
            yield put({ type: actionLoginTypes.TOKEN_EMPTY });
             const newtoken= yield call(refreshToken);
             yield put({ type: actionMySubRobotTypes.SET_ALL_MY_SUB_ROBOT_FETCHING });
             const response = yield call(getMySubRobotListWithRatings, newtoken)
             if (!response.ok) {
                 throw response.data;
             }
             callback(SUCCESS, response);
             yield put({
                 type: actionMySubRobotTypes.MY_SUB_ROBOT_ALL_RECIEVED,
                 payload: response.data,
             });
        }
        yield put({
            type: actionMySubRobotTypes.MY_SUB_ROBOT_ERROR,
            error: e,
        });
        callback(ERROR, e.message);
    }
}

export function* downloadFile(param) {
    const { accessToken, id, data, typeExt, name } = param;
    let recievedResponse = {};
    try {
        yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FILE_FETCHING });
        yield put({ type: actionMySubRobotTypes.SET_ALL_MY_SUB_ROBOT_FETCHING });
        const getUrl = APIService.dev + requestURLS.DOWNLOAD_FILE + "?id=" + id;
        let headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        };
        if (data) {
            recievedResponse = yield fetch(getUrl, {
                headers: headers,
                method: "POST",
                body: JSON.stringify(data),
            })
        }
        if (!recievedResponse.ok) {
            
            throw recievedResponse
        }
        recievedResponse.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = `${name}.${typeExt}`;
            a.click();
        });
        yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FILE_RECIEVED });
    } catch (e) {
        if(e.status===401){
            yield put({ type: actionLoginTypes.TOKEN_EMPTY });
             const newtoken= yield call(refreshToken);
             yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FILE_FETCHING });
             yield put({ type: actionMySubRobotTypes.SET_ALL_MY_SUB_ROBOT_FETCHING });
             const getUrl = APIService.dev + requestURLS.DOWNLOAD_FILE + "?id=" + id;
             let headers = {
                 "Content-Type": "application/json",
                 Authorization: `Bearer ${newtoken}`,
             };
             if (data) {
                 recievedResponse = yield fetch(getUrl, {
                     headers: headers,
                     method: "POST",
                     body: JSON.stringify(data),
                 })
             }
             if (!recievedResponse.ok) {
                 throw recievedResponse.statusText
             }
             recievedResponse.blob().then(blob => {
                 let url = window.URL.createObjectURL(blob);
                 let a = document.createElement('a');
                 a.href = url;
                 a.download = `${name}.${typeExt}`;
                 a.click();
             });
             yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FILE_RECIEVED });
          }else{
              yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FILE_RECIEVED });
              yield put({
                  type: actionMySubRobotTypes.MY_SUB_ROBOT_ERROR,
                  error: e.statusText,
              });
              const re=yield recievedResponse.json()
              message.error(re.message|| e.statusText);
          }
    }
}

export function* mySubRobotActionWatcher() {
    yield takeLatest(actionMySubRobotTypes.CANCEL_MY_ROBOT_SUBSCRIPTION, cancelRobotSubscription);
    yield takeLatest(actionMySubRobotTypes.MY_SUB_ROBOT_EDIT_BROKER_ACCOUNT, postBrokerAccount);
    yield takeEvery(actionMySubRobotTypes.GET_ROBOT_RATING, getMySubRobotRating);
    yield takeLatest(actionMySubRobotTypes.POST_ROBOT_RATING, postRobotRating);
    yield takeLatest(actionMySubRobotTypes.GET_ALL_MY_SUB_ROBOT, getAllMySubRobotList);
    yield takeLatest(actionMySubRobotTypes.DOWNLOAD_FILE, downloadFile);
}
import { DRAWER_FILTER_TYPE, DEFAULT_WEEK_LIST, DEFAULT_INDICATOR_STATE, DEFAULT_BAR_STATE, DEFAULT_BUY_SELL_OPTION, DEFAULT_CONFIGURE_OPTION, DEFAULT_TIME_OPTION, DEFAULT_ALERT_OPTION, DEFAULT_ALERT_STATE, ALERT_PARAMETER_TYPE, ALERT_FUNCTIONS, ALERT, ALERT_PREFIX, PUSH_NOTIFICATION, SEND_EMAIL, DEFAULT_AUTO_MONEY_OPTION, DEFAULT_MARTINGALE_OPTION, DEFAULT_CUSTOM_CODE_OPTION, DEFAULT_AI_CONFIGURE_OPTION, DEFAULT_CONFIGURE_OPTION_AI } from "../../constants/constants";
import { ACCELERATOR, ADX, ALLIGATOR, AWESOME, ATR, BEARS_POWER, BULLS_POWER, BOLLINGER_BANDS, CCI, DEMARKER, CUSTOM_INDICATOR, ENUMERATION_TYPE, ENVELOPES, FORCE, FRACTALS, GATOR, ICHIMOKU, BWMFI, MOMENTUM, MFI, INDICATOR_DATATYPES, INDICATOR_FUNCTIONS, MOVING_AVERAGE, OSMA, RSI, PARAMETER_TYPE, MACD, OBV, ACCUMULATION, SAR, RVI, STDDEV, WILLIAMS, STOCHASTIC } from "../../constants/constants.Indicator";
import { HIGH, LOW, OPEN, CLOSE, BAR_DATATYPES, BAR_FUNCTIONS } from "../../constants/constants.Bar";
import { BUY_OPTIONS_CODE, SELL_OPTION_CODE } from "../../constants/constants.buysell"
import { CONFIGURE_PARAMETER, ALERT_PARAMETER, TIME_PARAMETER, MARTINGALE_PARAMETER, AUTO_MONEY_PARAMETER, CUSTOM_CODE_PARAMETER, AI_CONFIGURE_PARAMETER } from "../../constants/constants.filter";
import { getStringForNumber } from "../../utils/utils";
import { ONBAR, INIT, TICK } from "../../constants/messages";
import { CONTROLS_FLOW_STATEMENTS_HELPURL, CONTROLS_FLOW_STATEMENTS_WARNING } from "blockly/msg/en";
import { APIService, requestURLS } from "../../constants/APIConstant";
import moment from "moment-timezone";

let gTimeDefinitionCode = '';
let gAutoMoneyDefinitionCode = '';
let gMartingaleDefinitionCode = '';

/**
 * @param {string} generatedCode code for the blocks
 * @param {object} completeBlockCode code containing code for filters in workspace
 * @description generates executable code for the blockly workspace
 */
export const makeCode = async (generatedCode, workSpaceCode, codeType,aidate) => {
  let code = ''
  let counter = 0;
  let indicatorCounter = 1;
  let alertCounter = 1;
  let timeCount = 1;
 
  if (codeType === INIT && workSpaceCode[INIT]) {
    if (workSpaceCode[TICK]) {
      const completeBlockCode = workSpaceCode[TICK]
      
      Object.keys(completeBlockCode).map(blockId => {
        completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR && indicatorCounter++
      })
      //console.log("object is " + completeBlockCode)
    }
    if (workSpaceCode[ONBAR]) {
      const completeBlockCode = workSpaceCode[ONBAR]
      Object.keys(completeBlockCode).map(blockId => {
        completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR && indicatorCounter++
      })
      //console.log("object is " + completeBlockCode)
    }
    const completeBlockCode = workSpaceCode[INIT]
    code = await generateBlockCode(generatedCode, completeBlockCode, indicatorCounter, counter, alertCounter, timeCount,aidate)
  }
  else if (codeType === TICK && workSpaceCode[TICK]) {

    if (workSpaceCode[INIT]) {
      const completeBlockCode = workSpaceCode[INIT]
      Object.keys(completeBlockCode).map(blockId => {
        completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR && indicatorCounter++
      })
    }
    if (workSpaceCode[ONBAR]) {
      const completeBlockCode = workSpaceCode[ONBAR]
      Object.keys(completeBlockCode).map(blockId => {
        completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR && indicatorCounter++
      })
      //console.log("object is " + completeBlockCode)
    }
    const completeBlockCode = workSpaceCode[TICK]

    // console.log({workSpaceCode});
    // console.log({completeBlockCode});

    code = await generateBlockCode(generatedCode, completeBlockCode, indicatorCounter, counter, alertCounter, timeCount,aidate)
  }
  else if (codeType === ONBAR && workSpaceCode[ONBAR]) {
    if (workSpaceCode[INIT]) {
      const completeBlockCode = workSpaceCode[INIT]
      Object.keys(completeBlockCode).map(blockId => {
        completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR && indicatorCounter++
      })
      //console.log("object is " + completeBlockCode)
    }
    if (workSpaceCode[TICK]) {
      const completeBlockCode = workSpaceCode[TICK]
      Object.keys(completeBlockCode).map(blockId => {
        completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR && indicatorCounter++
      })
      //console.log("object is " + completeBlockCode)
    }
    const completeBlockCode = workSpaceCode[ONBAR]
    code = await generateBlockCode(generatedCode, completeBlockCode, indicatorCounter, counter, alertCounter, timeCount,aidate)
  }
  // console.log('code 1 is: ' + code)
  return code;
}

/**
 * @param {string} generatedCode blockly workspacec code
 * @param {object} completeBlockCode containing code for filters in workspace
 * @param {number} indicatorCounter counter for indicator Filters
 * @param {number} counter counter for week filter
 * @param {number} timeCounter counter for time filter
 * @description generate scoped code for each filter
 */
const generateBlockCode = async (generatedCode, completeBlockCode, indicatorCounter, counter, alertCounter, timeCount,aidate) => {
  const functionText = '\nint result;\nresult = 0;\n\n';
  let variable = '';
  let indicatorCode = '';
  let ai_code = ' ';
  let autoMoneyCode = '';
  let martingaleCode = '';
  let alertCode = '';
  let barCode = '';
  let code = '\n\n' + generatedCode;
  const completeBlockKeys = sortByTimeStamp(completeBlockCode)
  let customCodeDefinitionCode = '';
  gAutoMoneyDefinitionCode = '';
  gTimeDefinitionCode = '';
  gMartingaleDefinitionCode = '';
  let automoneyActualCode = '';
  let martingaleActualCode = '';
// console.log('*-*-*-*-*-*-*-*-',code);
  await Promise.all(completeBlockKeys.map(async (blockId) => {
    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.WEEK) {
      const [tempVariable, tempCode] = generateCodeForWeekFilter(blockId, counter++, code, completeBlockCode);
      variable = variable + tempVariable;
      code = tempCode
    }


    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.TIME) {
      const [tempInputCode, tempActualCode] = generateCodeForTimeFilter(blockId, timeCount++, code, completeBlockCode);
      //variable = variable + tempVariable;
      //console.log('tempInputCode is ' + tempInputCode)
      gTimeDefinitionCode = tempInputCode;
      //variable = variable + tempInputCode
      code = tempActualCode
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.CUSTOM_CODE) {
      code = code.replace(`<<${blockId}>>`, '')
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.AUTO_MONEY) {
      //const [tempInputCode, tempActualCode] = generateGlobalCodeForAutoMoneyFilter();
      automoneyActualCode = 'LotSizeAutomatic=UseAutomaticMoneyManagement;';
      code = code.replace(`<<${blockId}>>`, '')
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.MARTINGALE) {
      martingaleActualCode = 'UseMartingale=UseIntelligentMartingale;'
      code = code.replace(`<<${blockId}>>`, '')
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.CUSTOM_CODE) {
      code = code.replace(`<<${blockId}>>`, completeBlockCode[blockId]['code']['value']);
      customCodeDefinitionCode = generateCodeforCustomCode(completeBlockCode[blockId]['code']);
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.ALERT) {
      //const alertCodeMain = completeBlockCode[blockId]['code']
      const alertType = completeBlockCode[blockId]['code']['name']

      alertCode = generateCodeForAlertFilter(alertType, alertCounter++)  // working
      //console.log('alert code before is ' + code)
      code = code.replace(`<<${blockId}>>`, alertCode)
      //console.log('alert code after is ' + code)

      //code = code.replace(`<<${blockId}>>`, `AlertMessage${counter++}`)
      //code = code.replace(`<<${blockId}>>`, '\nAlert["Test"]') // working!!!
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.CONFIGUREAI) {
      var res = await BucketFileReader(completeBlockCode[blockId]?.code,aidate)
      ai_code += res
      code = code.replace(`<<${blockId}>>`, '')
    }
    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR) {
      const indicatorType = completeBlockCode[blockId]['code']['name']
      // console.log("ind block id is " + blockId)
      indicatorCode += generateCodeForIndicatorFilter(indicatorType, indicatorCounter)
      code = code.replace(`<<${blockId}>>`, `Indicator${indicatorCounter++}`)

    }
    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.CONFIGURE) {
      code = code.replace(`<<${blockId}>>`, '')
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.BAR) {
      const barType = completeBlockCode[blockId]['code']['name']
      //console.log("bar block id is " + blockId)
      //console.log("Bar type " + barType)
      indicatorCode += generateCodeForBarFilter(barType, indicatorCounter)
      //console.log("bar code is " + indicatorCode)
      code = code.replace(`<<${blockId}>>`, `Indicator${indicatorCounter++}`)
      //console.log("code 2 is : " + code)
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.BUY || completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.SELL) {
      const optionsCode = completeBlockCode[blockId]['code']
      const filterType = completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.BUY ? BUY_OPTIONS_CODE : SELL_OPTION_CODE
      code = code.replace(`<<${blockId}>>`, filterType[optionsCode.id])
      // console.log("Buy or sell found.  filterType[optionsCode.id] is: " + filterType[optionsCode.id])
    }



    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.ASK) {
      code = code.replace(`<<${blockId}>>`, 'Ask')
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.BID) {
      code = code.replace(`<<${blockId}>>`, 'Bid')
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.PUSH) {
      code = code.replace(`<<${blockId}>>`, '\nSendNotification("Push Notification Test");\n')
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.EMAIL) {
      code = code.replace(`<<${blockId}>>`, '\nSendMail("Push Notification Test");\n')
    }

  }))


  code = code.replace(/Options/g, "true");
  code = code.replace(/\(/g, '');
  code = code.replace(/\)/g, '');
  code = code.replace(/\[/g, '(');
  code = code.replace(/\]/g, ')');
  code = code.replace(/\|\|\>\>/g, '');
  code = code.replace(/\-<<.*?[^\>>]\>>/g, '');
  code = code.replace(/\>\>\ /g, '');
  code = code.replace(/\;/g, '');
  code = code.replace(/\^/g, ';');
  code = code.replace(/SEMICOLON/g, ';');
  //code = autoMoneyCode + '\n' + martingaleCode + '\n' + functionText + '\n' + indicatorCode + '\n' + variable + '\n' + code + '\n  '
  code = functionText + '\n' + indicatorCode + '\n' + ai_code + '\n' + variable + '\n' + automoneyActualCode + '\n' + martingaleActualCode + '\n' + code + '\n' + customCodeDefinitionCode + '\n'
  // console.log("code 4 is: " + ai_code)
  return code
}

export const BucketFileReader = async (code,date) => {

const token= localStorage.getItem('token')
const moscowTime = moment.tz("Europe/Moscow").format("YYYY-MM-DDTHH:mm:ss");

 const formData = new FormData();
  formData.append('symbol_name', code?.Symbol?.value);
  formData.append('timeframe', code?.Timeframe?.value?.split('_')[1]);
  formData.append('date_time', moment(moscowTime).format("YYYY-MM-DDTHH:mm:ss") + "Z");

  let getURL = APIService.dev + requestURLS.DIRECTION;
  try {
    const response = await fetch(getURL, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`, 
      },
      body: formData
    });
  
    if (response.ok) {
      const data = await response.json();
      if(data?.data?.forecast_direction){
        const str_two =  `
int TradeDirection;
string AI_Direction_From_Database = "${data?.data?.forecast_direction}";
     
if (AI_Direction_From_Database=="UP")
{
TradeDirection = LONG;
}
  
if (AI_Direction_From_Database=="DOWN")
{
TradeDirection = SHORT;
} 
`;
     const final_result = `${str_two}`
     return final_result;
      }

    } else {
      console.error('Failed to fetch:', response.status, response.statusText);
    }
    // const response = await fetch(getURL);
    // const fileContent = await response.text();
    // console.log(response);
    // const lines = fileContent.split(/\r?\n/);

    // const result = lines
    //   .map(line => line.replace(/\r$/, ''))
    //   .map(line => line.split(' '))
    //   .map(item => {
    //     const [currencyPair, period, direction] = item;
    //     const obj = {};
    //     if (currencyPair) obj.currencyPair = currencyPair;
    //     if (period) obj.period = period;
    //     if (direction) obj.direction = direction;
    //     return obj;
    //   })
    //   .filter(item => Object.keys(item).length > 0);

    // if (result.length > 0) {
    //   const str_one = `string vSymbol = _Symbol;
    // string firstCurrency = "";
    // string secondCurrency = "";
    // if (SymbolPrefix != "" && StringFind(vSymbol, SymbolPrefix, 0) == 0)
    //     vSymbol = StringSubstr(vSymbol, StringLen(SymbolPrefix));
    // if (SymbolSuffix != "" && StringFind(vSymbol, SymbolSuffix, 0) > 0)
    //     vSymbol = StringSubstr(vSymbol, 0, StringFind(vSymbol, SymbolSuffix, 0));
    
    // int TradeDirection;
    // string bucket_symbol;
    // string bucket_AI_Signal;
    // int bucket_Period;`

    //   const str_two = result.map(item => {
    //     return `
    // bucket_symbol = "${item.currencyPair}";
    // bucket_AI_Signal = "${item.direction}";
    // bucket_Period = ${item.period};

    // if (vSymbol == bucket_symbol && bucket_AI_Signal == "UP" && bucket_Period == _Period)
    // {
    // TradeDirection = LONG;
    // }

    // if (vSymbol == bucket_symbol && bucket_AI_Signal == "DOWN" && bucket_Period == _Period)
    // {
    // TradeDirection = SHORT;
    // }
    // `;
    //   }).join('\n');

    //   const final_result = `${str_one} ${str_two}`
    //   // console.log(final_result)
    //   return final_result;
    // }
  } catch (error) {
    console.error("Error fetching/processing file:", error);
    throw error; // Propagate the error
  }
};

/**
 * @param {object} completeBlockCode code for each block in workspace
 * @description generates global code for the blockly workspace
 */
export const makeGlobalCode = (workSpaceCode) => {
  let code = '';
  let indicatorCounter = 1;
  let alertCounter = 1;
  let timeCounter = 1;
  let barCounter = 1;
  let latestConfiguration = ''
  let latestConfigurationai = ''
  let indicatorCodeDeclaration = '';
  let indicatorCodeDefinition = '';

  let barCodeDeclaration = '';
  let barCodeDefinition = '';

  let alertCodeDeclaration = '';
  let alertCodeDefinition = '';

  let timeCodeDeclaration = '';
  let timeCodeDefinition = '';

  //let automoneyActualCode = '';

  const workSpaceKeys = sortByCodeType(workSpaceCode)
  workSpaceKeys.map(codeType => {
    const completeBlockCode = workSpaceCode[codeType]
    const completeBlockKeys = sortByTimeStamp(completeBlockCode)
    completeBlockKeys.map(blockId => {
      if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.CONFIGURE) {
        latestConfiguration = generateGlobalCodeForConfigureFilter(completeBlockCode[blockId]['code'])
        //console.log('block code 0 is ' + completeBlockCode[blockId]['code'])
      }
      if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.CONFIGUREAI) {
        latestConfigurationai = generateGlobalCodeForConfigureFilterai(completeBlockCode[blockId]['code'])
        //console.log('block code 0 is ' + completeBlockCode[blockId]['code'])
      }

      if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.MARTINGALE) {
        gMartingaleDefinitionCode = generateGlobalCodeForMartingaleFilter(completeBlockCode[blockId]['code'])
      }

      if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.AUTO_MONEY) {
        gAutoMoneyDefinitionCode = generateGlobalCodeForAutoMoneyFilter(completeBlockCode[blockId]['code'])
        //automoneyActualCode = 'LotSizeAutomatic=UseAutomaticMoneyManagement;';
      }

      if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.TIME) {
        const [tempInputCode, tempActualCode] = generateCodeForTimeFilter(blockId, timeCounter++, code, completeBlockCode);
        //variable = variable + tempVariable;
        console.log('tempInputCode new is ' + tempInputCode)
        timeCodeDeclaration += tempInputCode
        timeCodeDefinition += tempActualCode
      }

      if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.ALERT) {
        const alertType = completeBlockCode[blockId]['code']['name']
        //alertCodeDeclaration += `string Alert${alertCounter}; // ${getStringForNumber(alertCounter)} Alert ${alertType}\n`
        alertCodeDefinition += generateGlobalCodeForAlertFilter(completeBlockCode[blockId]['code'], alertCounter++)
        //console.log('alert code is ' + latestAlertConfig)
      }

      if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR || completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.BAR) {
        const indicatorType = completeBlockCode[blockId]['code']['name']
        indicatorCodeDeclaration += `double Indicator${indicatorCounter}; // ${getStringForNumber(indicatorCounter)} Indicator ${indicatorType}\n`
        indicatorCodeDefinition += generateGlobalCodeForIndicatorFilter(completeBlockCode[blockId]['code'], indicatorCounter++,completeBlockCode[blockId]['code'].name)
      }
      /*
      if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.BAR) {
        const barType = completeBlockCode[blockId]['code']['name']
        //console.log("got here1 " + barType);
        barCodeDeclaration += `double Bar${indicatorCounter}; // ${getStringForNumber(indicatorCounter)} Bar ${barType}\n`
        //console.log("got here2 " + barCodeDeclaration);
        barCodeDefinition += generateGlobalCodeForBarFilter(completeBlockCode[blockId]['code'], indicatorCounter++)
        //console.log("got here3 " + barCodeDefinition);
      }
      */
    })
  })
  code ="\n" + latestConfigurationai + "\n" + latestConfiguration + "\n" + gAutoMoneyDefinitionCode + "\n" + gMartingaleDefinitionCode + "\n" + timeCodeDeclaration + "\n" + alertCodeDeclaration + "\n" + indicatorCodeDeclaration + "\n" + indicatorCodeDefinition + "\n" + alertCodeDefinition + "\n"; //+ barCodeDeclaration + "\n" + barCodeDefinition + "\n"

  return code
}

/**
 * @param {object} workSpaceCode code containing code for whole workspace
 * @description sorts the block code by key
 */
const sortByCodeType = (workSpaceCode) => {
  return Object.keys(workSpaceCode).sort((a, b) => a.localeCompare(b))
}

/**
 * @param {object} completeBlockCode code containing code for filters in workspace
 * @description sorts the block code by timestamp
 */
const sortByTimeStamp = (completeBlockCode) => {
  return Object.keys(completeBlockCode).sort((a, b) => completeBlockCode[a].timeStamp > completeBlockCode[b].timeStamp ? 1 : -1)
}

/**
 * @param {object} blockCode contains drawer code for indicator filter
 * @param {number} counter counter for keeping track of num of indicators
 * @description generates global code for indicator filter
 */
const generateGlobalCodeForIndicatorFilter = (blockCode, counter,blockname) => {
  let code = ''

  // remove unused mt5 variaable
  const removeForce = ['Applied Volume'];

  const ignore_list = {
    "Force Index": removeForce,
    "Market Facilitation Index": removeForce,
    "Money Flow Index": removeForce,
    "On Balance Volume": removeForce,
    "Accumulation/Distribution": removeForce,
  };

  const removeFields = ignore_list[blockname];

  Object.keys(blockCode).map(key => {
    let fieldType = Object.values(PARAMETER_TYPE).filter(field => !removeFields?.includes(field.NAME) && field.NAME === key)
    fieldType = fieldType.length && fieldType[0]
    const isUserAdjustable = blockCode[key].ADJUSTABLE ? 'input ' : '';
    if (fieldType && fieldType.DATATYPE === INDICATOR_DATATYPES.STRING) {
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key
      let paramValue = blockCode[key].VALUE
      if (fieldType.NAME === PARAMETER_TYPE.CUSTOM_INDICATOR_PARAMETER.NAME) {
        let tempValue = paramValue.split(",");
        tempValue.map((item, index) => { if (isNaN(Number(item))) tempValue[index] = `\\"${item}\\"` })
        paramValue = tempValue.join(",")
      }
      paramValue = paramValue ? `"${paramValue}"` : 'NULL';
      code += `${isUserAdjustable}string ${paramName}${counter} = ${paramValue}; // ${key} value for ${getStringForNumber(counter)} Indicator\n`
    }

    if (fieldType && (fieldType.DATATYPE === INDICATOR_DATATYPES.INTEGER
      || fieldType.NAME === PARAMETER_TYPE.MODE.NAME || fieldType.NAME === PARAMETER_TYPE.ADX_MODE.NAME || fieldType.NAME === PARAMETER_TYPE.ALLIGATOR_MODE.NAME || fieldType.NAME === PARAMETER_TYPE.ICHI_MODE.NAME || fieldType.NAME === PARAMETER_TYPE.LINE_MODE.NAME)) {
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key
      code += `${isUserAdjustable}int ${paramName}${counter} = ${blockCode[key].VALUE}; // ${key} value for ${getStringForNumber(counter)} Indicator\n`
    }

    if (fieldType && fieldType.DATATYPE === INDICATOR_DATATYPES.DOUBLE) {
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key
      code += `${isUserAdjustable}double ${paramName}${counter} = ${blockCode[key].VALUE}; // ${key} value for ${getStringForNumber(counter)} Indicator\n`
    }

    if (fieldType && fieldType.DATATYPE === INDICATOR_DATATYPES.ENUM) {
      const typeName = ENUMERATION_TYPE[fieldType.NAME];
      if (!typeName) return;
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key;
      code += `${isUserAdjustable}${typeName} ${paramName}${counter} = ${blockCode[key].VALUE}; // ${key} value for ${getStringForNumber(counter)} Indicator\n`
    }
  })
  return code + '\n'
}

/**
 * @param {object} blockCode contains drawer code for configure filter
 * @description generates code for configure_filter
 */
const generateGlobalCodeForConfigureFilter = (blockCode) => {
  //console.log('block 0 is ' + blockCode[CONFIGURE_PARAMETER.TAKE_PROFIT.NAME]);
  return `\ninput double Take_Profit = ${blockCode[CONFIGURE_PARAMETER.TAKE_PROFIT.NAME]}; // Take Profit\ninput double Stop_Loss = ${blockCode[CONFIGURE_PARAMETER.STOP_LOSS.NAME]}; // Stop Loss\ninput double Lots = ${blockCode[CONFIGURE_PARAMETER.LOTS.NAME]}; // Lots\n\n`
}

// for AI engine
const generateGlobalCodeForConfigureFilterai = (blockCode) => {
 return ``
  // return ` \nstring Symbol0 = "${blockCode?.Symbol?.value}"; // Symbol value for First Indicator\n ENUM_TIMEFRAMES Timeframe0 = ${blockCode?.Timeframe?.value}; // Timeframe value for First Indicator\n\n`
}

/**
 * @param {object} blockCode contains drawer code for martingale filter
 * @description generates code for martingale_filter
 */
const generateGlobalCodeForMartingaleFilter = (blockCode) => {
  return `\ninput bool UseIntelligentMartingale = ${blockCode[MARTINGALE_PARAMETER.USE_INTELLIGENT_MARTINGALE.NAME]}; // Intelligent Martingale\ninput double MartingaleMultiplier = ${blockCode[MARTINGALE_PARAMETER.MARTINGALE_MULTIPLIER.NAME]}; // Martingale Multiplier\ninput double MaximumMartingaleLotSize = ${blockCode[MARTINGALE_PARAMETER.MAX_MARTINGALE_LOT_SIZE.NAME]}; // MAximum Martingale Lot Size\n\n`
}

const generateCodeforCustomCode = (blockCode) => {
  return `\n ${blockCode[CUSTOM_CODE_PARAMETER.CUSTOM_CODE.NAME].VALUE} // Custom Code\n\n`
}

/**
 * @param {object} blockCode contains drawer code for Auto Money filter
 * @description generates code for auto_money_filter
 */
const generateGlobalCodeForAutoMoneyFilter = (blockCode) => {
  //const auto_money_input_code = `\ninput bool UseAutomaticMoneyManagement = ${blockCode[AUTO_MONEY_PARAMETER.USE_AUTO_MONEY_MANAGEMENT.NAME]}; // Use Auto Money Management\ninput double TradeRiskPercentage = ${blockCode[AUTO_MONEY_PARAMETER.TRADE_RISK_PERCENTAGE.NAME]}; // Trade Risk Percentage\n\n`
  //const auto_money_actual_code = 'LotSizeAutomatic=UseAutomaticMoneyManagement;'
  return `\ninput bool UseAutomaticMoneyManagement = ${blockCode[AUTO_MONEY_PARAMETER.USE_AUTO_MONEY_MANAGEMENT.NAME]}; // Use Auto Money Management\ninput double TradeRiskPercentage = ${blockCode[AUTO_MONEY_PARAMETER.TRADE_RISK_PERCENTAGE.NAME]}; // Trade Risk Percentage\n\n`
}

const generateGlobalCodeForTimeFilter = (blockCode, counter) => {
  console.log('block 0 is ' + blockCode[TIME_PARAMETER.TIME.NAME]);
  const time_input_code = `\ninput string Time${counter} = aa${blockCode[TIME_PARAMETER.TIME.NAME]}aa; // Time \n\n`
  const time_actual_code = ''
  return [time_input_code, time_actual_code]
}

/*
const generateGlobalCodeForAlertFilter = (blockCode, counter) => {
  console.log('block 1 is ' + blockCode[ALERT_PARAMETER.DO_ALERT.NAME]);
  return `\ninput bool DoAlert${counter} = ${blockCode[ALERT_PARAMETER.DO_ALERT.VALUE]}; // Do Alert\ninput string AlertMessage${counter} = ${blockCode[ALERT_PARAMETER.ALERT_MESSAGE.NAME]}; // Alert Message\n\n`
}
*/

/*** Alert Code */

/**
 * @param {string} indicatorName name of the indicator filter
 * @param {number} counter counter for keeping track of num of indicators
 * @description generates code for indicator_filter
 */
const generateCodeForAlertFilter = (alertName, alertCounter) => {
  let code = '';

  const functionName = ALERT_FUNCTIONS[alertName]
  if (alertName === ALERT) {
    const params = [
      ALERT_PARAMETER_TYPE.ALERT_MESSAGE.NAME
    ]
    return generateFunctionCodeForAlertBlock(params, alertCounter, functionName)
  }
  if (alertName === PUSH_NOTIFICATION) {
    const params = [
      ALERT_PARAMETER_TYPE.ALERT_MESSAGE.NAME
    ]
    return generateFunctionCodeForAlertBlock(params, alertCounter, functionName)
  }

  if (alertName === SEND_EMAIL) {
    const params = [
      ALERT_PARAMETER_TYPE.ALERT_MESSAGE.NAME,
      ALERT_PARAMETER_TYPE.ALERT_MESSAGE.NAME
    ]
    return generateFunctionCodeForAlertBlock(params, alertCounter, functionName)
  }
}
/** End Alert Code */
/**
 * @param {string} indicatorName name of the indicator filter
 * @param {number} counter counter for keeping track of num of indicators
 * @description generates code for indicator_filter
 */
const generateCodeForIndicatorFilter = (indicatorName, counter) => {
  let code = '';
  const functionName = INDICATOR_FUNCTIONS[indicatorName]
  if (indicatorName === ACCELERATOR) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === ACCUMULATION) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME,PARAMETER_TYPE.SHIFT.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === ADX) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.APPLIED_PRICE.NAME, PARAMETER_TYPE.ADX_MODE.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === ALLIGATOR) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.JAW_PERIOD.NAME, PARAMETER_TYPE.JAW_SHIFT.NAME,
      PARAMETER_TYPE.TEETH_PERIOD.NAME, PARAMETER_TYPE.TEETH_SHIFT.NAME, PARAMETER_TYPE.LIPS_PERIOD.NAME, PARAMETER_TYPE.LIPS_SHIFT.NAME,
      PARAMETER_TYPE.METHOD.NAME, PARAMETER_TYPE.APPLIED_PRICE.NAME, PARAMETER_TYPE.ALLIGATOR_MODE.NAME,
      PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === AWESOME) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === ATR) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === BEARS_POWER) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.APPLIED_PRICE.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === BULLS_POWER) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.APPLIED_PRICE.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === BOLLINGER_BANDS) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.DEVIATION.NAME, PARAMETER_TYPE.SHIFT.NAME, PARAMETER_TYPE.APPLIED_PRICE.NAME,
      PARAMETER_TYPE.MODE.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === CCI) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.APPLIED_PRICE.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === DEMARKER) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === ENVELOPES) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.METHOD.NAME, PARAMETER_TYPE.SHIFT.NAME, PARAMETER_TYPE.APPLIED_PRICE.NAME,
      PARAMETER_TYPE.DEVIATION.NAME, PARAMETER_TYPE.MODE.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === FORCE) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.METHOD.NAME, PARAMETER_TYPE.APPLIED_PRICE.NAME,
      PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === FRACTALS) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.MODE.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === GATOR) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.JAW_PERIOD.NAME, PARAMETER_TYPE.JAW_SHIFT.NAME,
      PARAMETER_TYPE.TEETH_PERIOD.NAME, PARAMETER_TYPE.TEETH_SHIFT.NAME, PARAMETER_TYPE.LIPS_PERIOD.NAME, PARAMETER_TYPE.LIPS_SHIFT.NAME,
      PARAMETER_TYPE.METHOD.NAME, PARAMETER_TYPE.APPLIED_PRICE.NAME, PARAMETER_TYPE.MODE.NAME,
      PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === ICHIMOKU) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.TENKANSEN_PERIOD.NAME,
      PARAMETER_TYPE.KIJUNSEN_PERIOD.NAME, PARAMETER_TYPE.SENKOUSPANB_PERIOD.NAME,
      PARAMETER_TYPE.ICHI_MODE.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === BWMFI) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === MOMENTUM) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.APPLIED_PRICE.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === MFI) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === OBV) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.APPLIED_PRICE.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === MOVING_AVERAGE) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.SHIFT.NAME, PARAMETER_TYPE.METHOD.NAME, PARAMETER_TYPE.APPLIED_PRICE.NAME,
      PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === OSMA) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.FAST_EMA_PERIOD.NAME,
      PARAMETER_TYPE.SLOW_EMA_PERIOD.NAME, PARAMETER_TYPE.SIGNAL_PERIOD.NAME,
      PARAMETER_TYPE.APPLIED_PRICE.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === MACD) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.FAST_EMA_PERIOD.NAME,
      PARAMETER_TYPE.SLOW_EMA_PERIOD.NAME, PARAMETER_TYPE.SIGNAL_PERIOD.NAME,
      PARAMETER_TYPE.APPLIED_PRICE.NAME, PARAMETER_TYPE.LINE_MODE.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === RSI) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.APPLIED_PRICE.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === CUSTOM_INDICATOR) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CUSTOM_INDICATOR_NAME.NAME,
      PARAMETER_TYPE.CUSTOM_INDICATOR_PARAMETER.NAME, PARAMETER_TYPE.BUFFER_MODE.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === HIGH) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === LOW) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === OPEN) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === CLOSE) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
 
  if (indicatorName === SAR) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.STEP.NAME,PARAMETER_TYPE.MAXIMUM.NAME,PARAMETER_TYPE.SHIFT.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === RVI) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME,PARAMETER_TYPE.PERIOD.NAME,PARAMETER_TYPE.MODE.NAME,PARAMETER_TYPE.SHIFT.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === STDDEV) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.SHIFT.NAME, PARAMETER_TYPE.METHOD.NAME, PARAMETER_TYPE.APPLIED_PRICE.NAME,
      PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === WILLIAMS) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME,PARAMETER_TYPE.PERIOD.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  if (indicatorName === STOCHASTIC) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME,
      PARAMETER_TYPE.TIMEFRAME.NAME,
      PARAMETER_TYPE.K_PERIOD.NAME,
      PARAMETER_TYPE.D_PERIOD.NAME,
      PARAMETER_TYPE.SLOWING.NAME,
      PARAMETER_TYPE.METHOD.NAME,
      PARAMETER_TYPE.PRICE_STOCHASTIC_FIELD.NAME,
      PARAMETER_TYPE.LINE_MODE.NAME,
      PARAMETER_TYPE.SHIFT.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName)
  }
  /**
     * TODO: DEFINE NEW INDICATOR
     * Define the function parameters for new indicator
     * (order the parameters according to indicator function)
     * Refer to above indicators and define new indicator
     * (**Important:** Import Display name for new indicator)
     */
  return code
}

/**  Bar Code Generate */

const generateCodeForBarFilter = (barName, counter) => {
  let code = '';
  const functionName = BAR_FUNCTIONS[barName]
  if (barName === HIGH) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForBarBlock(params, counter, functionName)
  }
  if (barName === LOW) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForBarBlock(params, counter, functionName)
  }
  if (barName === OPEN) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForBarBlock(params, counter, functionName)
  }
  if (barName === CLOSE) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForBarBlock(params, counter, functionName)
  }


  /**
      * TODO: DEFINE NEW INDICATOR
      * Define the function parameters for new indicator
      * (order the parameters according to indicator function)
      * Refer to above indicators and define new indicator
      * (**Important:** Import Display name for new indicator)
      */
  //console.log("code is " + code)
  return code
}

const generateGlobalCodeForBarFilter = (blockCode, counter) => {
  let code = ''
  Object.keys(blockCode).map(key => {
    let fieldType = Object.values(PARAMETER_TYPE).filter(field => field.NAME === key)
    fieldType = fieldType.length && fieldType[0]
    const isUserAdjustable = blockCode[key].ADJUSTABLE ? 'input ' : '';
    if (fieldType && fieldType.DATATYPE === BAR_DATATYPES.STRING) {
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key
      let paramValue = blockCode[key].VALUE

      paramValue = paramValue ? `"${paramValue}"` : 'NULL';
      code += `${isUserAdjustable}string ${paramName}${counter} = ${paramValue}; // ${key} value for ${getStringForNumber(counter)} Bar\n`
    }

    if (fieldType && (fieldType.DATATYPE === INDICATOR_DATATYPES.INTEGER
      || fieldType.NAME === PARAMETER_TYPE.MODE.NAME || fieldType.NAME === PARAMETER_TYPE.ADX_MODE.NAME || fieldType.NAME === PARAMETER_TYPE.ALLIGATOR_MODE.NAME || fieldType.NAME === PARAMETER_TYPE.ICHI_MODE.NAME)) {
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key
      code += `${isUserAdjustable}int ${paramName}${counter} = ${blockCode[key].VALUE}; // ${key} value for ${getStringForNumber(counter)} Bar\n`
    }

    if (fieldType && fieldType.DATATYPE === INDICATOR_DATATYPES.DOUBLE) {
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key
      code += `${isUserAdjustable}double ${paramName}${counter} = ${blockCode[key].VALUE}; // ${key} value for ${getStringForNumber(counter)} Bar\n`
    }

    if (fieldType && fieldType.DATATYPE === INDICATOR_DATATYPES.ENUM) {
      const typeName = ENUMERATION_TYPE[fieldType.NAME];
      if (!typeName) return;
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key;
      code += `${isUserAdjustable}${typeName} ${paramName}${counter} = ${blockCode[key].VALUE}; // ${key} value for ${getStringForNumber(counter)} Bar\n`
    }
  })
  return code + '\n'
}


/** Code for Alerts */

const generateGlobalCodeForAlertFilter = (blockCode, alertCounter) => {
  let code = ''
  Object.keys(blockCode).map(key => {
    let fieldType = Object.values(ALERT_PARAMETER_TYPE).filter(field => field.NAME === key)
    fieldType = fieldType.length && fieldType[0]
    const isUserAdjustable = blockCode[key].ADJUSTABLE ? 'input ' : '';
    if (fieldType && fieldType.DATATYPE === INDICATOR_DATATYPES.STRING) {
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key
      let paramValue = blockCode[key].VALUE

      paramValue = paramValue ? `"${paramValue}"` : 'NULL';
      code += `${isUserAdjustable}string ${paramName}${alertCounter} = ${paramValue}; // ${key} value for ${getStringForNumber(alertCounter)} Alert\n`
    }

    if (fieldType && (fieldType.DATATYPE === INDICATOR_DATATYPES.INTEGER
      || fieldType.NAME === PARAMETER_TYPE.MODE.NAME)) {
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key
      code += `${isUserAdjustable}int ${paramName}${alertCounter} = ${blockCode[key].VALUE}; // ${key} value for ${getStringForNumber(alertCounter)} Alert\n`
    }

    if (fieldType && fieldType.DATATYPE === INDICATOR_DATATYPES.DOUBLE) {
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key
      code += `${isUserAdjustable}double ${paramName}${alertCounter} = ${blockCode[key].VALUE}; // ${key} value for ${getStringForNumber(alertCounter)} Alert\n`
    }

    if (fieldType && fieldType.DATATYPE === INDICATOR_DATATYPES.ENUM) {
      const typeName = ENUMERATION_TYPE[fieldType.NAME];
      if (!typeName) return;
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key;
      code += `${isUserAdjustable}${typeName} ${paramName}${alertCounter} = ${blockCode[key].VALUE}; // ${key} value for ${getStringForNumber(alertCounter)} Alert\n`
    }
  })
  return code + '\n'
}


/** End Code for Alerts */
/**
 * @param {Array} parameter list of parameters for the function
 * @param {number} counter counter for the bar block
 * @param {string} functionName name of the function for the bar
 * @description generate bar function code
 */
const generateFunctionCodeForBarBlock = (parameter, counter, functionName) => {
  let code = `Indicator${counter} = ${functionName}(`
  parameter.map(param => code += `${param.replace(/\s/g, '')}${counter},`)
  return code.substring(0, code.length - 1) + ');\n'
}

/* End Bar Code Generate */

/**
 * @param {Array} parameter list of parameters for the function
 * @param {number} counter counter for the indicator block
 * @param {string} functionName name of the function for the indicator
 * @description generate indicator function code
 */
const generateFunctionCodeForIndicatorBlock = (parameter, counter, functionName) => {
  let code = `Indicator${counter} = ${functionName}(`
  
  parameter.map(param => code += `${param.replace(/\s/g, '')}${counter},`)
  return code.substring(0, code.length - 1) + ');\n'
}

const generateFunctionCodeForAlertBlock = (parameter, alertCounter, functionName) => {
  let code = `${functionName}[`
  parameter.map(param => code += `${param.replace(/\s/g, '')}${alertCounter},`)
  return code.substring(0, code.length - 1) + ']'
}

/**
 * @param {string} blockId id of the block
 * @param {number} index index of the block (distinguish similar filters)
 * @param {string} code code generated by blocks
 * @param {object} completeBlockCode code for each block in workspace
 * @description generates code for week_filter
 */
const generateCodeForWeekFilter = (blockId, index, code, completeBlockCode) => {
  const variable = variableData(completeBlockCode[blockId]['code'], index) + '\n'
  const blockCode = code.replace(`Options-<<${blockId}`, collateData(completeBlockCode[blockId]['code'], index))
  return [variable, blockCode]
}

/**
 * @param {string} blockId id of the block
 * @param {number} index index of the block (distinguish similar filters)
 * @param {string} code code generated by blocks
 * @param {object} completeBlockCode code for each block in workspace
 * @description generates code for week_filter
 */
const generateCodeForTimeFilter = (blockId, index, code, completeBlockCode) => {
  //const variable = variableData(completeBlockCode[blockId]['code'], index) + '\n'
  const temp1 = completeBlockCode[blockId]['code'];
  const temp2 = temp1[TIME_PARAMETER.TIME.NAME].VALUE;
  const temp3 = index;
  //temp2 = "Time${index}'
  //const blockCode = code.replace(`Options-<<${blockId}`, 'TimeCurrent[] >= "' + temp2 + '"') // working!!
  const time_actual_code = code.replace(`Options-<<${blockId}`, 'TimeCurrent[] >= StrToTime[TimeToStr[TimeCurrent[] , TIME_DATE] + " " + Time' + temp3 + ']')
  //StrToTime[TimeToStr[TimeCurrent[] , TIME_DATE] + " " + temp2]

  const time_input_code = `\ninput string Time${index} = "${temp2}"; // Time \n\n`
  return [time_input_code, time_actual_code]

  //return [blockCode]
}

/**
 * @param {object} singleWeek week data
 * @param {number} num index of the block in the workspace 
 * @description generate data for variable data of week filter
 */
const variableData = (singleWeek, num) => {
  let a = "";
  Object.keys(singleWeek).map((index) => (
    a = a + '\nbool ' + 'Week_' + num + singleWeek[index].name + ' = ' +
    (singleWeek[index].action === 'True' ? true : false) + ';'
  ))
  return a;
}

/**
 * @param {object} singleWeek week data
 * @param {number} num index of the block in the workspace 
 * @description collate code for week filter
 */
/*
const collateData = (singleWeek, num) => {
  let a = "";
  Object.keys(singleWeek).map((index) => (
    a = a + 'Week_' + num + singleWeek[index].name + ' == ' + true + '||'
  ))
  return a;
}
*/

const collateData = (singleWeek, num) => {
  let a = "";

  Object.keys(singleWeek).map((index) => (

    a = a + ' [DayOfWeek[]==' + index + ' && ' + 'Week_' + num + singleWeek[index].name + ' == ' + true + ']' + '||'
  ))
  return a;
}

const collateTimeData = (singleWeek, num) => {
  let a = "";

  Object.keys(singleWeek).map((index) => (

    a = a + ' [DayOfWeek[]==' + index + ' && ' + 'Week_' + num + singleWeek[index].name + ' == ' + true + ']' + '||'
  ))
  return a;
}

/**
 * @param {string} type type of filter
 * @description returns default drawer state 
 */
export const getDefaultDrawer = (type) => {
  if (type === DRAWER_FILTER_TYPE.WEEK)
    return DEFAULT_WEEK_LIST;

  if (type === DRAWER_FILTER_TYPE.INDICATOR)
    return DEFAULT_INDICATOR_STATE;

  if (type === DRAWER_FILTER_TYPE.BAR)
    return DEFAULT_BAR_STATE;

  if (type === DRAWER_FILTER_TYPE.BUY || type === DRAWER_FILTER_TYPE.SELL)
    return DEFAULT_BUY_SELL_OPTION;

  if (type === DRAWER_FILTER_TYPE.CONFIGURE)
    return DEFAULT_CONFIGURE_OPTION;

  if (type === DRAWER_FILTER_TYPE.CONFIGUREAI)
    return DEFAULT_CONFIGURE_OPTION_AI;

  if (type === DRAWER_FILTER_TYPE.TIME)
    return DEFAULT_TIME_OPTION;

  if (type === DRAWER_FILTER_TYPE.ALERT)
    return DEFAULT_ALERT_STATE;

  if (type === DRAWER_FILTER_TYPE.MARTINGALE)
    return DEFAULT_MARTINGALE_OPTION;

  if (type === DRAWER_FILTER_TYPE.AUTO_MONEY)
    return DEFAULT_AUTO_MONEY_OPTION;

  if (type === DRAWER_FILTER_TYPE.CUSTOM_CODE)
    return DEFAULT_CUSTOM_CODE_OPTION;

  return ''
}

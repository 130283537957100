import "./forgotPassword.css";

import {
  ANSWER_REQUIRED,
  CANCEL,
  CONFIRM,
  CONFIRM_PASSWORD,
  EMAIL_REQUIRED,
  ERROR,
  FORGOT_PASSWORD,
  INVALID_PASSWORD,
  NEXT,
  PASSWORD_DO_NOT_MATCH,
  PASSWORD_INFO,
  PASSWORD_REQUIRED,
  PASSWORD_UPDATED,
  SECURITY_QUE_ERROR,
  SUCCESS,
} from "../../constants/messages";
import { Button, Divider, Form, Input, message } from "antd";
import {
  EMAIL_VALIDATION,
  PASSWORD_VALIDATION,
} from "../../constants/constants";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getSecurityQuestions,
  postForgotPassword,
} from "../../actions/commonActions";
import logo from "../../assets/logo.png";
import { ArrowLeftOutlined } from "@ant-design/icons";
import FormSteps from "./formSteps";
import PropTypes from "prop-types";
import SideBanner from "../commonComponent/sideBanner";
import { connect } from "react-redux";
import EmailIcon from "../../assets/robotasset/email.png";
import PassIcon from "../../assets/robotasset/Password.png";

const IMF = ({ url }) => {
  return (
    <img src={url} style={{ height: "25px", marginRight: "5px" }} alt="" />
  );
};

function useQuery() {
  const [query, setQuery] = useState(new Map());
  const location = useLocation();
  useEffect(() => {
    setQuery(new URLSearchParams(location.search));
  }, [location]);
  return query;
}

function ForgotPassword(props) {
  const [stepList] = useState(["Email", "Check Email", "Password"]);
  const [activeKey, setActiveKey] = useState(0);
  const [email] = useState("");
  const [passwordPattern, setPasswordPattern] = useState("");
  const [submitData, setSubmitData] = useState({});
  const [hasErrored, setHasErrored] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Unable to connect with the server."
  );
  const [uidb, setUidb] = useState("");
  const [resetToken, setResetToken] = useState("");

  let query = useQuery();
  useEffect(() => {
    if (query.get("uidb64") && query.get("reset-token")) {
      setActiveKey(2);
      setUidb(query.get("uidb64"));
      setResetToken(query.get("reset-token"));
    }
  }, [query]);

  const getSecurityQuestionsCallback = (error, activeKey) => {
    if (!error) {
      setActiveKey(activeKey + 1);
    } else {
      setHasErrored(true);
      setErrorMessage(error);
    }
  };

  const checkIfCorrectAnswers = (value) => {
    const securityQuestions = props.securityQuestions;
    let count = 0;
    Object.keys(value).map((data, index) => {
      if (value[data].trim() === securityQuestions[index].answer.answer) {
        count = count + 1;
      }
      return count;
    });
    return count >= 2;
  };

  const formatQuestions = (value) => {
    let data = [];
    Object.keys(value).map((key) =>
      data.push({
        question_id: parseInt(key.split("-")[1]),
        answer: value[key].trim(),
      })
    );
    return data;
  };

  const onFinish = (value) => {
    let { getSecurityQuestions, postForgotPassword } = props;
    if (activeKey === 0) {
      const formData = { ...value, email: value.email.toLowerCase().trim() };
      getSecurityQuestions({
        data: formData,
        callback: (error, data) => {
          if (error === SUCCESS) {
            setActiveKey(activeKey + 1);
          } else if (error === ERROR) {
            setHasErrored(true);
            setErrorMessage(data);
          }
        },
      });
      setSubmitData(formData);
    } else if (activeKey === 2) {
      //   const correctAnswers = checkIfCorrectAnswers(value);
      //   getSecurityQuestionsCallback(
      //     correctAnswers ? false : SECURITY_QUE_ERROR,
      //     activeKey
      //   );
      //   if (correctAnswers) {
      //     setSubmitData({
      //       ...submitData,
      //       answers: formatQuestions(value),
      //     })
      //   }
      // } else {
      const data = {
        uidb64: uidb,
        token: resetToken,
        password: value.password,
      };
      postForgotPassword({
        data: data,
        callback: (error) => {
          if (!error) {
            message.success(PASSWORD_UPDATED);
            props.history.push("/login");
          } else {
            setHasErrored(true);
            setErrorMessage(error);
          }
        },
      });
    }
  };

  const handlePasswordChange = (input) => {
    let value = input.target.value;
    value = "^" + value + "$";
    setPasswordPattern(value);
    setHasErrored(false);
  };

  const handleChange = () => {
    setHasErrored(false);
  };

  const handleLogin = () => {
    props.history.push("/");
  };

  const securityQuestions = props.securityQuestions;
  return (
    <div className="signup-container">
      <SideBanner />
      <div className="right-body-login center_div">
        <img
          src={require("../../assets/robotasset/logo.png")}
          className="auth_logo"
          alt=""
        />
        <div className="login-box">
          <div className="form-container">
            {activeKey === 0 && (
              <div className="forget_pass">
                <Form
                  name="email"
                  initialValues={{
                    email: email,
                  }}
                  onFinish={onFinish}
                >
                  <Form.Item>
                    <div>
                      <div className="large-text label theme-text">
                        {FORGOT_PASSWORD}
                      </div>
                      <div className="regular-text  theme-text top-spacing">
                        Enter the email address associated with your account
                      </div>
                    </div>
                    <div className="steps-div full-width">
                      <FormSteps stepList={stepList} activeKey={activeKey} />
                    </div>
                    <Divider />
                  </Form.Item>
                  <div className="f_label">EMAIL*</div>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: EMAIL_REQUIRED },
                      {
                        pattern: EMAIL_VALIDATION,
                        message: EMAIL_REQUIRED,
                      },
                    ]}
                  >
                    
                    <Input
                      prefix={<IMF url={EmailIcon} />}
                      placeholder="Email Address"
                      className="inputBox-style full-width height-50 b-5"
                      onChange={handleChange}
                    />
                  </Form.Item>
                  {hasErrored && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                  <Form.Item>
                    <div className="step_btn">
                      <Button
                        type="primary"
                        className="button height-50 primary-button full-width  spacing  login-btn"
                        htmlType="submit"
                      >
                        Next
                      </Button>
                      <Button
                        type="default"
                        className="button height-50 default-button full-width b-5"
                        onClick={handleLogin}
                        htmlType="submit"
                      >
                        {CANCEL}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            )}
            {activeKey === 1 && (
              <div className="forget_pass">
                {" "}
                <Form
                  name="email"
                  initialValues={{
                    email: email,
                  }}
                  onFinish={onFinish}
                >
                  <Form.Item>
                    <div>
                      <div className="large-text label theme-text">
                        {FORGOT_PASSWORD}
                      </div>
                      <div className="regular-text  theme-text top-spacing">
                        Enter the email address associated with your account!
                      </div>
                    </div>
                    <div className="steps-div full-width">
                      <FormSteps stepList={stepList} activeKey={activeKey} />
                    </div>
                  </Form.Item>
                  <Divider />
                  <Form.Item>
                    <div className="regular-text">
                      Check your email for password reset link
                    </div>
                  </Form.Item>
                  {hasErrored && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </Form>
              </div>
            )}
            {activeKey === 2 && (
              <div className="forget_pass">
                <Form name="password" onFinish={onFinish}>
                  <Form.Item>
                    <div>
                      <div className="large-text label theme-text">
                        Set new password
                      </div>
                      <div className="regular-text theme-text top-spacing">
                        Please enter your new password and use Same password to
                        login
                      </div>
                    </div>
                    <div className="steps-div full-width">
                      <FormSteps stepList={stepList} activeKey={activeKey} />
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: PASSWORD_REQUIRED,
                      },
                      {
                        pattern: PASSWORD_VALIDATION,
                        message: INVALID_PASSWORD,
                      },
                    ]}
                  >
                    <Input.Password
                      prefix={<IMF url={PassIcon} />}
                      className="inputBox-style full-width height-50 b-5"
                      placeholder="New Password"
                      onChange={handlePasswordChange}
                    />
                  </Form.Item>
                  <Form.Item
                    name="confirmPassword"
                    rules={[
                      { required: true, message: CONFIRM_PASSWORD },
                      {
                        pattern: passwordPattern,
                        message: PASSWORD_DO_NOT_MATCH,
                      },
                    ]}
                  >
                    <Input.Password
                      prefix={<IMF url={PassIcon} />}
                      className="inputBox-style full-width height-50 b-5"
                      placeholder="Repeat Password"
                      onChange={handleChange}
                    />
                  </Form.Item>
                  <div className="password-info">{PASSWORD_INFO}</div>
                  {hasErrored && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                  <Form.Item>
                    <div className="step_btn">
                      <Button
                        type="primary"
                        className="button height-50 primary-button full-width  spacing login-btn"
                        htmlType="submit"
                      >
                        {CONFIRM}
                      </Button>
                      <Button
                        type="default"
                        className="button height-50 default-button full-width login-btn"
                        onClick={handleLogin}
                        htmlType="submit"
                      >
                        {CANCEL}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            )}
          </div>
        </div>
        <div className="footer_form">
          <p>© 2024 RobotMaker.AI. All Rights Reserved</p>
          <div className="">
            <p>Terms of Use</p>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
}

ForgotPassword.propTypes = {
  history: PropTypes.object.isRequired,
  fetchingUser: PropTypes.bool.isRequired,
  getSecurityQuestions: PropTypes.func.isRequired,
  postForgotPassword: PropTypes.func.isRequired,
};
const mapStateToProps = ({
  userReducer: { fetchingUser, securityQuestions },
}) => ({
  fetchingUser,
  securityQuestions,
});

const mapDispatchToProps = {
  getSecurityQuestions: getSecurityQuestions,
  postForgotPassword: postForgotPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

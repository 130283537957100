import { actionWorkspaceTypes } from "../constants/actionTypes";

const initialData = {
  fetchingWorkspace: false,
  fetchingfileWorkspace: false,
  workspaceData: {},
  workspaceList: [],
  symbol:null,
  timeframe:null,
  date:null,
  time:null
};

const workspaceReducer = (state = initialData, action) => {
  switch (action.type) {
    case actionWorkspaceTypes.SET_FETCHING:
      return {
        ...state,
        symbol: action.payload.symbol,
        timeframe: action.payload.timeframe,
        date: action.payload.date,
        time: action.payload.time,
      };
    case actionWorkspaceTypes.SET_WORKSPACE_FETCHING:
      return {
        ...state,
        fetchingWorkspace: true,
      };
    case actionWorkspaceTypes.SET_WORKSPACE_FILE_FETCHING:
      return {
        ...state,
        fetchingfileWorkspace: true,
      };
    case actionWorkspaceTypes.SET_WORKSPACE_FILE_RECIEVED:
      return {
        ...state,
        fetchingfileWorkspace: false,
      };
    case actionWorkspaceTypes.WORKSPACE_RESET:
   
      return {
        ...state,
        workspaceData: {},
      };
    case actionWorkspaceTypes.WORKSPACE_RECIEVED:
      return {
          ...state,
          workspaceData: action.payload,
          fetchingWorkspace: false,
      };
    case actionWorkspaceTypes.WORKSPACE_ALL_RECIEVED:
        return {
          ...state,
          workspaceList: action.payload,
          fetchingWorkspace: false,
        };
    case actionWorkspaceTypes.WORKSPACE_ERROR:
        return {
          ...state,
          fetchingWorkspace: false,
          fetchingfileWorkspace: false,
        };
    default:
      return state;
  }
};
export default workspaceReducer;

import { actionRobotTypes } from "../constants/actionTypes";

export const getStoreRobot = ({ id, accessToken, callback }) => {
  return {
    type: actionRobotTypes.GET_ROBOT,
    id: id,
    accessToken: accessToken,
    callback: callback
  };
};


export const getAllRobotStoreList = ({ accessToken,callback }) => {
  return {
    type: actionRobotTypes.GET_ALL_ROBOTSTORE,
    accessToken: accessToken,
    callback: callback
  };
};
export const postCopyRobot = ({ id, accessToken, callback, data }) => {

return{
  type: actionRobotTypes.COPY_ROBOT,
  accessToken: accessToken,
  id:id,
  data:data,
  callback:callback,
}
}
/**
 * @fileoverview Component for Buy/Sell Drawer
 */
import React, { useState, useEffect } from 'react'
import './indicatorDrawer.css';

import { DEFAULT_BUY_SELL_OPTION, BUY_OPTIONS_LIST, SELL_OPTIONS_LIST, DRAWER_FILTER_TYPE } from "../../../constants/constants"
import { COMMON_TEXT } from '../../../constants/constants.string';
import { HELP_TEXT } from '../../../constants/constants.popover';
import UpdateButton from './updateButton'
import { isEmpty } from '../../../utils/utils'

import { Popover, Select } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

const { Option } = Select;

function BuySellDrawer(props) {

    const { currentFilterType, code, setDrawerState, onCodeChange } = props

    // set parameters corresponding to filter type
    const [currentOption, setCurrentOption] = useState({})
    const [currentLabel, setCurrentLabel] = useState('')
    const [currentFilterOptionList, setCurrentFilterOptionList] = useState()

    useEffect(() => {
        if (!isEmpty(code)) {
            switch (currentFilterType) {
                case DRAWER_FILTER_TYPE.BUY:
                    setCurrentLabel('Buying Options')
                    setCurrentFilterOptionList(BUY_OPTIONS_LIST)
                    break;
                case DRAWER_FILTER_TYPE.SELL:
                    setCurrentLabel('Selling Options')
                    setCurrentFilterOptionList(SELL_OPTIONS_LIST)
                    break;
            }
            setCurrentOption(code)
        }
        else {
            setCurrentOption(DEFAULT_BUY_SELL_OPTION)
        }
    }, [code])

    /**
     * 
     * @param {number} key id of the selected option
     * @description handle option select change 
     */
    const handleOptionChange = (key) => {
        let op = {}
        switch (currentFilterType) {
            case DRAWER_FILTER_TYPE.BUY:
                op = { id: key, option: BUY_OPTIONS_LIST[key].option }
                break;
            case DRAWER_FILTER_TYPE.SELL:
                op = { id: key, option: SELL_OPTIONS_LIST[key].option }
                break;
        }
        setCurrentOption(op)
    }

    /**
     * @description handle click on update button
     */
    const handleOnUpdateClick = () => {
        onCodeChange(currentOption)
        setDrawerState(false)
    }

    return (
        <div className="drawer-body">
            <div className="property-header">{COMMON_TEXT.PROPERTIES}</div>
            <div className="property-body">
                <div className="option-style" id="indicator-block" >
                    <div className="indicator-field">
                        <div className="indicator-label">
                            {currentLabel}
                        </div>
                        <Popover content={HELP_TEXT.CONTENT}>
                        <img style={{height:'15px',maxWidth:'100%'}} src={require('../../../assets/robotasset/message-question.png')} alt="" />
                        </Popover>
                    </div>
                </div>
                <Select
                    placeholder='Select'
                    className='buy-sell-select full-width'
                    onChange={handleOptionChange}
                    showArrow={true}
                    value={currentOption && currentOption.option}>
                    {currentFilterOptionList &&
                        currentFilterOptionList.map((item, index) => {
                            return (
                                <Option key={index} value={item.id}>
                                    {item.option}
                                </Option>
                            )
                        })}
                </Select>
            </div>
            <UpdateButton onUpdate={handleOnUpdateClick} />
        </div>
    )
}

export default BuySellDrawer;
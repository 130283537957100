import { actionRobotTypes } from "../constants/actionTypes";

const initialData = {
  fetchingAllRobot: false,
  allRobotData: {},
  allRobotList: []
};

const robotStoreReducer = (state = initialData, action) => {
  switch (action.type) {
    case actionRobotTypes.SET_ALL_ROBOT_FETCHING:
      return {
        ...state,
        fetchingAllRobot: true,
      };
    case actionRobotTypes.ROBOT_RECIEVED:
      return {
        ...state,
        allRobotData: action.payload,
        fetchingAllRobot: false,
      };
    case actionRobotTypes.COPY_ROBOT_RECIEVED:
      return {
        ...state,
        fetchingAllRobot: false,
      };
    case actionRobotTypes.ROBOT_ALL_RECIEVED:
      return {
        ...state,
        allRobotList: action.payload,
        fetchingAllRobot: false,
      };
    default:
      return state;
  }
};
export default robotStoreReducer;

import { APIService, requestURLS } from "../constants/APIConstant";
import { ERROR, SUCCESS } from "../constants/messages";
import { actionLoginTypes, actionRobotRentTypes } from "../constants/actionTypes";
import { call, put, takeLatest } from "redux-saga/effects";
import {  ifAccessTokenExpired } from "../actions/commonActions";
import {TranslateOtherRobot} from '../components/commonComponent/translate';
import { message } from "antd";
import { refreshToken } from "./userSaga";

export function* getAllRobotRentList(param) {
    const { id, accessToken,callback } = param;

    let recievedResponse = {};
    try {
        yield put({ type: actionRobotRentTypes.FETCHED_ROBOT_RENT });

        const getUrl = APIService.dev + requestURLS.GET_ALL_ROBOT_RENT + '?id=' + id;
        let headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        };
        const responseJSON = yield fetch(getUrl, {
            headers: headers,
            method: "GET",
        }).then((response) => {
            recievedResponse = response;
            return response.json();
        });

        if (!recievedResponse.ok) {
            throw responseJSON;
        }
        callback(SUCCESS, responseJSON.data);
        yield put({
            type: actionRobotRentTypes.ROBOT_RENT_ALL_RECIEVED,
            payload: TranslateOtherRobot(responseJSON.data),
        });
    } catch (e) {
        if(e.ResponseCode===401){
            yield put({ type: actionLoginTypes.TOKEN_EMPTY });
            const newtoken =yield call(refreshToken);
            yield put({ type: actionRobotRentTypes.FETCHED_ROBOT_RENT });

        const getUrl = APIService.dev + requestURLS.GET_ALL_ROBOT_RENT + '?id=' + id;
        let headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${newtoken}`,
        };
        const responseJSON = yield fetch(getUrl, {
            headers: headers,
            method: "GET",
        }).then((response) => {
            recievedResponse = response;
            return response.json();
        });

        if (!recievedResponse.ok) {
            throw responseJSON;
        }
        callback(SUCCESS, responseJSON.data);
        yield put({
            type: actionRobotRentTypes.ROBOT_RENT_ALL_RECIEVED,
            payload: TranslateOtherRobot(responseJSON.data),
        });
          }
        callback(ERROR, e.message);
        yield put({
            type: actionRobotRentTypes.ROBOT_RENT_ERROR,
            error: e,
        });
    }
}


export function* postEditRentRobot(param) {
    let { accessToken, callback, data, id } = param;
    try {
        let recievedResponse = {};
        yield put({ type: actionRobotRentTypes.SET_ALL_ROBOT_RENT_FETCHING });

        const postUrl = APIService.dev + requestURLS.EDIT_RENT_ROBOT + '?id=' + id;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };
        const responseJSON = yield fetch(postUrl, {
            headers: headers,
            method: "POST",
            body: data,
        }).then((response) => {
            recievedResponse = response;
            return response.json();
        });
        if (!recievedResponse.ok) {
            throw responseJSON;
        }
        yield put({
            type: actionRobotRentTypes.ROBOT_RENT_RECIEVED,
            payload: responseJSON.data,
        });
        callback(SUCCESS, responseJSON.data);
    } catch (e) {
        if(e.ResponseCode===401){
            yield put({ type: actionLoginTypes.TOKEN_EMPTY });
            const newtoken =yield call(refreshToken);
            let recievedResponse = {};
        yield put({ type: actionRobotRentTypes.SET_ALL_ROBOT_RENT_FETCHING });

        const postUrl = APIService.dev + requestURLS.EDIT_RENT_ROBOT + '?id=' + id;
        const headers = {
            Authorization: `Bearer ${newtoken}`,
        };
        const responseJSON = yield fetch(postUrl, {
            headers: headers,
            method: "POST",
            body: data,
        }).then((response) => {
            recievedResponse = response;
            return response.json();
        });
        if (!recievedResponse.ok) {
            throw responseJSON;
        }
        yield put({
            type: actionRobotRentTypes.ROBOT_RENT_RECIEVED,
            payload: responseJSON.data,
        });
        callback(SUCCESS, responseJSON.data);
          }
        yield put({
            type: actionRobotRentTypes.ROBOT_RENT_ERROR,
            error: e,
        });
        callback(ERROR, e.message);
    }
}

export function* robotRentActionWatcher() {
    yield takeLatest(actionRobotRentTypes.GET_ALL_ROBOT_RENT, getAllRobotRentList);
    yield takeLatest(actionRobotRentTypes.POST_ROBOT_RENT, postEditRentRobot);

}
import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./pg.css";

const CustomPagination = ({ record, setDisplayData }) => {
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [res, setres] = useState([]);

  const receivedData = () => {
    const data = record;
    const slice = data.slice(offset, offset + perPage);
    setPageCount(Math.ceil(data.length / perPage));
    setDisplayData(slice);
    setres(slice);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffset(offset);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    setOffset(0);
  };

  const handleLastPage = () => {
    const lastPage = pageCount - 1;
    const offset = lastPage * perPage;

    setCurrentPage(lastPage);
    setOffset(offset);
  };

  useEffect(() => {
    receivedData();
  }, [record, currentPage, offset]);
  return (
    <div className={`${res.length <5 && 'p-absolute'}   `}>
      <div className="pagination-controls">
        <button
          className="pagination-button"
          onClick={handleFirstPage}
          disabled={currentPage === 0}
        >
          &laquo; First
        </button>
        <ReactPaginate
          previousLabel={"‹ Back"}
          nextLabel={"Next ›"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={currentPage}
        />
        <button
          className="pagination-button"
          onClick={handleLastPage}
          disabled={currentPage === pageCount - 1}
        >
          Last &raquo;
        </button>
      </div>
    </div>
  );
};

export default CustomPagination;

import { APIService, requestURLS } from "../constants/APIConstant";
import { ERROR, SUCCESS } from "../constants/messages";
import { call, put, takeLatest } from "redux-saga/effects";

import { actionLoginTypes, actionWorkspaceTypes } from "../constants/actionTypes";
import { message } from "antd";
import { ifAccessTokenExpired } from "../actions/commonActions";
import { refreshToken } from "./userSaga";

export function* postWorkspace(param) {
  let { accessToken, callback, data, id } = param;
  try {
    let recievedResponse = {};
    yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FETCHING });

    const postUrl = APIService.dev + requestURLS.WORKSPACE_POST;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    if (id) {
      data = { ...data, id: id } //in case of update robot
    }
    const responseJSON = yield fetch(postUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({
      type: actionWorkspaceTypes.WORKSPACE_RECIEVED,
      payload: responseJSON.data,
    });
    callback(SUCCESS, responseJSON.data);
  } catch (e) {
        if(e.ResponseCode===401){
          yield put({ type: actionLoginTypes.TOKEN_EMPTY });
          yield call(refreshToken);
        }
        yield put({
          type: actionWorkspaceTypes.WORKSPACE_ERROR,
          error: e,
        });
        callback(ERROR, e.message);
  }
}

export function* deleteWorkspace(param) {
  const { accessToken, id, callback } = param;
  try {
    let recievedResponse = {};
    yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FETCHING });

    const postUrl = APIService.dev + requestURLS.WORKSPACE_DELETE + 'delete/details/?id=' + id;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(postUrl, {
      headers: headers,
      method: "DELETE",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    callback(SUCCESS, responseJSON.message);
    
  } catch (e) {
    if(e.ResponseCode===401){
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      const newtoken =yield call(refreshToken);
      let recievedResponse = {};
      yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FETCHING });

      const postUrl = APIService.dev + requestURLS.WORKSPACE_DELETE + 'delete/details/?id=' + id;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newtoken}`,
      };
      const responseJSON = yield fetch(postUrl, {
        headers: headers,
        method: "DELETE",
      }).then((response) => {
        recievedResponse = response;
        return response.json();
      });
      if (!recievedResponse.ok) {
        throw responseJSON;
      }
      
      callback(SUCCESS, responseJSON.message);
      }
    yield put({
      type: actionWorkspaceTypes.WORKSPACE_ERROR,
      error: e,
    });

    callback(ERROR, e.message);
  }
}

export function* getWorkspace(param) {
  const { id, accessToken, callback } = param;
  let recievedResponse = {};
  try {
    yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FETCHING });

    const getUrl = APIService.dev + requestURLS.GET_WORKSPACE + '?id=' + id;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    callback(SUCCESS, responseJSON.data);
    yield put({
      type: actionWorkspaceTypes.WORKSPACE_RECIEVED,
      payload: responseJSON.data,
    });
  } catch (e) {
    if(e.ResponseCode===401){
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      const newTokenData = yield call(refreshToken);
      yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FETCHING });

    const getUrl = APIService.dev + requestURLS.GET_WORKSPACE + '?id=' + id;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${newTokenData}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    callback(SUCCESS, responseJSON.data);
    yield put({
      type: actionWorkspaceTypes.WORKSPACE_RECIEVED,
      payload: responseJSON.data,
    });
    }
    yield put({
      type: actionWorkspaceTypes.WORKSPACE_ERROR,
      error: e,
    });
    callback(ERROR, e);
  }
}

export function* getAllWorkspaceList(param) {
  const { accessToken } = param;
  let recievedResponse = {};
  try {
    yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FETCHING });
    
    const getUrl = APIService.dev + requestURLS.GET_ALL_WORKSPACE;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({
      type: actionWorkspaceTypes.WORKSPACE_ALL_RECIEVED,
      payload: responseJSON.data,
    });
  } catch (e) {
    
    if(e.ResponseCode===401){
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      try {
        const newTokenData = yield call(refreshToken);
        yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FETCHING });
    
        const getUrl = APIService.dev + requestURLS.GET_ALL_WORKSPACE;
        let headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${newTokenData}`,
        };
        const responseJSON = yield fetch(getUrl, {
          headers: headers,
          method: "GET",
        }).then((response) => {
          recievedResponse = response;
          return response.json();
        });
        
        if (!recievedResponse.ok) {
          throw responseJSON;
        }
        yield put({
          type: actionWorkspaceTypes.WORKSPACE_ALL_RECIEVED,
          payload: responseJSON.data,
        });
     } catch (refreshError) {
       yield put({ type: actionLoginTypes.REFRESH_TOKEN_ERROR,  error: e});
 
     }
    }
    yield put({
      type: actionWorkspaceTypes.WORKSPACE_ERROR,
      error: e,
    });
  }
}

export function* saveFile(param) {
  const { accessToken, data, typeExt, name,callback } = param;
  let recievedResponse = {};
  try {
    yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FILE_FETCHING });
   
    const getUrl = APIService.dev + requestURLS.SAVE_FILE;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    recievedResponse = yield fetch(getUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    })
    if (!recievedResponse.ok) {
      recievedResponse.json().then(body => {
        const error = body.message || recievedResponse.statusText;
        message.error(error);
      });
      throw recievedResponse.statusText
    }
    callback(SUCCESS, false);
    recievedResponse.blob().then(blob => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = `${name}.${typeExt}`;
      a.click();
    });
    yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FILE_RECIEVED });
  } catch (e) {
    if(e.ResponseCode===401){
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      yield call(refreshToken);
      yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FILE_FETCHING });
   
      const getUrl = APIService.dev + requestURLS.SAVE_FILE;
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      recievedResponse = yield fetch(getUrl, {
        headers: headers,
        method: "POST",
        body: JSON.stringify(data),
      })
      if (!recievedResponse.ok) {
        recievedResponse.json().then(body => {
          const error = body.message || recievedResponse.statusText;
          message.error(error);
        });
        throw recievedResponse.statusText
      }
      callback(SUCCESS, false);
      recievedResponse.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${name}.${typeExt}`;
        a.click();
      });
      yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FILE_RECIEVED });
    }
    yield put({
      type: actionWorkspaceTypes.WORKSPACE_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
    // message.error(e);
    yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FILE_RECIEVED });

  }
}

export function* getAIforcast(param) {
  const { data, accessToken, callback } = param;

  let recievedResponse = {};
  try {
    yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FILE_FETCHING });

    const searchParams = new URLSearchParams(data);
    const apiUrl = `?${searchParams.toString()}`;

    const getUrl = APIService.dev + requestURLS.GET_AI_DATA + apiUrl;

    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    callback(SUCCESS, responseJSON.data);
    yield put({
      type: actionWorkspaceTypes.SET_WORKSPACE_FILE_RECIEVED,
      payload: responseJSON.data,
    });
  } catch (e) {
    if(e.ResponseCode===401){
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      yield call(refreshToken);
      
    }
    yield put({
      type: actionWorkspaceTypes.SET_WORKSPACE_FILE_RECIEVED,
      error: e,
    });
    callback(ERROR, e.message);
  }
}

function* GlobalStates(param) {
  const { symbol, date, time, timeframe } = param;
  try {
    const user = { symbol: symbol, date: date,time:time,timeframe:timeframe };
    yield put({ type: actionWorkspaceTypes.SET_FETCHING, payload: user });
  } catch (error) {
    yield put({ type: 'FETCH_USER_FAILURE', payload: 'Error fetching user' });
  }
}
export function* workspaceActionWatcher() {
  yield takeLatest(actionWorkspaceTypes.POST_WORKSPACE, postWorkspace);
  yield takeLatest(actionWorkspaceTypes.AI_WORKSPACE, getAIforcast);
  yield takeLatest(actionWorkspaceTypes.GET_WORKSPACE, getWorkspace);
  yield takeLatest(actionWorkspaceTypes.DELETE_WORKSPACE, deleteWorkspace);
  yield takeLatest(actionWorkspaceTypes.GET_ALL_WORKSPACE, getAllWorkspaceList);
  yield takeLatest(actionWorkspaceTypes.SAVE_FILE, saveFile);
  yield takeLatest(actionWorkspaceTypes.SET_FETCHINGS, GlobalStates);
}

import React, { useState, useEffect } from "react";
import { isEmpty } from "../../../utils/utils";
import {
  HELP_TEXT_CONFIGURE_DRAWER,
  POPOVER_USER_ADJUSTABLE,
} from "../../../constants/constants.popover";
import { COMMON_TEXT } from "../../../constants/constants.string";
import { INDICATOR_DATATYPES } from "../../../constants/constants.Indicator";
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Popover,
  Select,
  Tooltip,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { AI_CONFIGURE_PARAMETER } from "../../../constants/constants.filter";
import UpdateButton from "./updateButton";
import moment from "moment";
import DateTimeComponent from "../../AiForcast/DateTimeComponent";

const { Option } = Select;

const ConfigureDrawerai = (props) => {
  const { code, onCodeChange, setDrawerState } = props;
  const [codeBlock, setCodeBlock] = useState({});
  const [codeBlockError, setCodeBlockError] = useState({});
  const [popOverContent, setPopOverContent] = useState({});

  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (Object.keys(codeBlockError).length) {
      const isValid = !Boolean(
        Object.values(codeBlockError).filter((i) => i.status).length
      );
      setIsValid(isValid);
    }
  }, [codeBlockError]);

  useEffect(() => {
    !isEmpty(code) && setCodeBlock({ ...code });
    setCodeBlockError({});
    setPopOverContent({ ...HELP_TEXT_CONFIGURE_DRAWER });
  }, [code]);

  const handleFieldChange = (value, fieldName, isFieldRequired = false) => {
    let tempCodeBlock = JSON.parse(JSON.stringify(codeBlock));
    // console.log(tempCodeBlock);
    tempCodeBlock[fieldName]["value"] = value;
    setCodeBlock({ ...tempCodeBlock });
    setCodeBlockError({
      ...codeBlockError,
      [fieldName]: {
        status: value || value === 0 || !isFieldRequired ? false : true,
        help:
          value || value === 0 || !isFieldRequired
            ? null
            : COMMON_TEXT.EMPTY_STRING,
      },
    });
  };

  const handleCheckChange = (isChecked, fieldName) => {
    let tempCodeBlock = JSON.parse(JSON.stringify(codeBlock));
    tempCodeBlock[fieldName]["ADJUSTABLE"] = isChecked;
    setCodeBlock(tempCodeBlock);
  };

  const handleOnUpdateClick = () => {
    if (isValid) {
      onCodeChange({ ...codeBlock });
      setDrawerState(false);
    }
  };

  const fields = Object.keys(AI_CONFIGURE_PARAMETER).map(
    (key) => AI_CONFIGURE_PARAMETER[key]
  );


  
  return (
    <div className="drawer-body">
      <div className="property-header">{COMMON_TEXT.PROPERTIES}</div>
      <div className="property-body">
        <Form>
          {fields &&
            fields.map((field) => {
              if (field.DATATYPE === INDICATOR_DATATYPES.ENUM) {
                return (
                  codeBlock[field.NAME] && (
                    <SelectField
                      key={field.NAME}
                      label={field.NAME}
                      data={field.DATA}
                      checked={codeBlock[field.NAME].ADJUSTABLE}
                      onCheckChange={(event) =>
                        handleCheckChange(event.target.checked, field.NAME)
                      }
                      value={codeBlock[field.NAME].value}
                      help={
                        codeBlockError[field.NAME] &&
                        codeBlockError[field.NAME].help
                      }
                      error={
                        codeBlockError[field.NAME] &&
                        codeBlockError[field.NAME].status
                      }
                      onChange={(key) => handleFieldChange(key, field.NAME)}
                      content={popOverContent[field.NAME]}
                    />
                  )
                );
              }

              if (field.DATATYPE === INDICATOR_DATATYPES.STRING) {
                return (
                  codeBlock[field.NAME] && (
                    <InputField
                      key={field.NAME}
                      label={field.NAME}
                      content={popOverContent[field.NAME]}
                      checked={codeBlock[field.NAME].ADJUSTABLE}
                      value={codeBlock[field.NAME].value}
                      help={
                        codeBlockError[field.NAME] &&
                        codeBlockError[field.NAME].help
                      }
                      error={
                        codeBlockError[field.NAME] &&
                        codeBlockError[field.NAME].status
                      }
                      onChange={(event) =>
                        handleFieldChange(
                          event.target.value,
                          field.NAME,
                          field.REQUIRED
                        )
                      }
                      onCheckChange={(event) =>
                        handleCheckChange(event.target.checked, field.NAME)
                      }
                    />
                  )
                );
              }
            })}
        </Form>
      </div>
      <UpdateButton onUpdate={handleOnUpdateClick} />
    </div>
  );
};

export default ConfigureDrawerai;

export function SelectField(props) {
  return (
    <div className="option-style" id="indicator-block">
      <div className="indicator-field">
        <div className="indicator-label">{props.label}</div>
        <Popover content={props.content && props.content}>
        <img style={{height:'15px',maxWidth:'100%'}} src={require('../../../assets/robotasset/message-question.png')} alt="" />
        </Popover>
        {typeof props.checked !== "undefined" && (
          <Tooltip placement="right" title={POPOVER_USER_ADJUSTABLE}>
            <Checkbox
              className="indicator-checkbox"
              checked={props.checked}
              onChange={props.onCheckChange}
            />
          </Tooltip>
        )}
      </div>
      <Form.Item
        className="indicator-form-item"
        help={props.help && props.help}
        hasFeedback={props.error}
      >
        <Select
          placeholder="Select"
          className="theme-text indicator-select"
          onChange={props.onChange}
          showArrow={true}
          value={props.value}
        >
          {Object.keys(props.data).map((item, index) => (
            <Option key={`${item}${index}`} value={props.data[item]}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
}
export function InputField(props) {
  return (
    <div className="option-style" id="indicator-block">
      <div className="indicator-field">
        <div className="indicator-label">{props.label}</div>
        <Popover content={props.content && props.content}>
        <img style={{height:'15px',maxWidth:'100%'}} src={require('../../../assets/robotasset/message-question.png')} alt="" />
        </Popover>
        {typeof props.checked !== "undefined" && (
          <Tooltip placement="right" title={POPOVER_USER_ADJUSTABLE}>
            <Checkbox
              className="indicator-checkbox"
              checked={props.checked}
              onChange={props.onCheckChange}
            />
          </Tooltip>
        )}
      </div>
      <Form.Item
        className="indicator-form-item"
        validateStatus={props.error ? "error" : "success"}
        help={props.help && props.help}
      >
        <Input
          placeholder={props.label}
          type={props.typeOfField ? props.typeOfField : ""}
          value={props.value}
          step={props.step}
          onChange={props.onChange}
          onKeyDown={(event) =>
            props.integerOnly && event.key === "." && event.preventDefault()
          }
        />
      </Form.Item>
    </div>
  );
}

import "./payment.css";

import {
  BRONZE,
  PAYMENT_CONTENT1,
  PAYMENT_FAIL,
  PAYMENT_HEADER,
  PAYMENT_SUCCESS,
  SILVER,
  SUCCESS,
  THEME_COLOR,
} from "../../constants/messages";
import React, { useState } from "react";
import { Spin, message, Modal } from "antd";
import { getQuestions, getUser } from "../../actions/commonActions";
import {
  postPayPalPayment,
  postStripePayment,
  updatePayPalPayment,
} from "../../actions/paymentActions";

import PayPalBtn from "./paypalBtn";
import PropTypes from "prop-types";
import StripeCheckout from "react-stripe-checkout";
import { connect } from "react-redux";

function PaymentMethod(props) {
  const [hasErrored, setHasErrored] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [spinning, setSpinning] = useState(true);
  const [openStripeModal, setOpenStripeModal] = useState(false);
  const [couponCode, setCouponCode] = useState("");

  const paypalOnApprove = (data, detail) => {
    const { selectedPrice, selectedType, loginData, accessToken, action } =
      props;
    const requestData = {
      user: {
        email: loginData ? loginData.email : "",
        plan: selectedType,
        password: loginData ? loginData.password : "",
      },
      price: selectedPrice,
      payment_status: "COMPLETED",
      receipt_id: data.orderID,
      message: SUCCESS,
      subscription_id: data.subscriptionID,
      action: loginData ? "" : action,
    };

    if (
      props.action &&
      (props.action === "update" || props.action === "resubscribe")
    ) {
      props.updatePayPalPayment({
        data: { requestData },
        accessToken: accessToken,

        callback: callbackUpdate,
        method: "paypal",
      });
    } else {
      props.postPayPalPayment({
        data: { requestData },
        callback: callback,
      });
    }
  };

  const paypalSubscribe = (data, actions) => {
    return actions.subscription.create({
      plan_id:
        props.selectedType === BRONZE
          ? process.env.REACT_APP_PAYPAL_PLAN_NANOBOT_ID:
          props.selectedType === SILVER?
           process.env.REACT_APP_PAYPAL_PLAN_ROBOMATE_ID:process.env.REACT_APP_PAYPAL_MEGATRON_PLAN_ID,
    });
  };

  const paypalOnError = (err) => {
    setHasErrored(true);
    setErrorMessage(PAYMENT_FAIL);
  };

  const callbackUpdate = (res, data) => {
    if (res === SUCCESS) {
      message.success(data.message);
      props.onComplete();
      setOpenStripeModal(false);
      setCouponCode("");
      props.setis_coupon_used(res?.data?.is_coupon_used)
    } else {
      if(data !=='Token is Expired'){
      setHasErrored(true);
      setErrorMessage(data);
      }
    }
  };

  const callback = (res, data) => {
    if (res === SUCCESS) {
      localStorage.setItem("token", data.access);
      props.setis_coupon_used(res?.data?.is_coupon_used)
      if (data.user.is_coupon_used) {
        localStorage.setItem("is_coupon_used", data.user.is_coupon_used);
      }
      props.history.push("/workspace");
      message.success(PAYMENT_SUCCESS);
      setOpenStripeModal(false);
      setCouponCode("");
    } else {
      setHasErrored(true);
      setErrorMessage(data);
    }
  };

  const handleToken = (token, addresses, value) => {
    const { selectedPrice, selectedType, loginData, accessToken, action } =
      props;

    const product = {
      name: selectedType,
      price: selectedPrice,
      description: selectedType,
    };
    const data = {
      token: token,
      product: product,
      user: {
        email: loginData ? loginData.email : "",
        plan: selectedType,
        password: loginData ? loginData.password : "",
      },
      action: loginData ? "" : action,
      coupon_code: couponCode,
    };

    if (
      props.action &&
      (props.action === "update" || props.action === "resubscribe")
    ) {
      props.updatePayPalPayment({
        data: { data },
        accessToken: accessToken,
        callback: callbackUpdate,
        method: "stripe",
      });
    } else {
      props.postStripePayment({
        data: { data },
        callback: callback,
      });
    }
  };

  const { selectedPrice, selectedType, styleClassname } = props;
  return (
    <div className={` ${styleClassname}`}>
      <div className="payment-stripe-div">
        <div className={`paypals-div ${styleClassname}`}>
          <div className="payment-header-desc">
            <div className="medium-text label">{PAYMENT_HEADER}</div>
            <div className="small-text">{PAYMENT_CONTENT1}</div>
          </div>
          {spinning && (
            <Spin
              tip="Loading Paypal Option..."
              size="small"
              className="spinner"
            />
          )}
          <PayPalBtn
            amount={selectedPrice}
            currency={"USD"}
            createSubscription={paypalSubscribe}
            onApprove={paypalOnApprove}
            catchError={paypalOnError}
            onError={paypalOnError}
            onCancel={paypalOnError}
            stopSpin={() => setSpinning(!spinning)}
          />

          <div className="horizontal-line">
            <hr className="striped-border" />
            OR
            <hr className="striped-border" />
          </div>
          {props.selectedType === SILVER ? (
            <StripeCheckout
              label="Pay with stripe "
              stripeKey={process.env.REACT_APP_STRIPE_KEY}
              token={handleToken}
              amount={selectedPrice * 100}
              name={selectedType}
              billingAddress
              shippingAddress
              ComponentClass="payment-header-desc"
              coupon="" // Add the coupon prop here
              disabled={!couponCode ? false : true}
            />
          ) : (
            <StripeCheckout
              label="Subscribe with Stripe"
              stripeKey={process.env.REACT_APP_STRIPE_KEY}
              token={handleToken}
              amount={selectedPrice * 100}
              name={selectedType}
              billingAddress
              shippingAddress
              ComponentClass="payment-header-desc"
            />
          )}
          {hasErrored && (
            <div className="error-message padding-20">{errorMessage}</div>
          )}
        </div>
      </div>
      {/* <Modal
        title="Coupon Code"
        onCancel={() => setOpenStripeModal(false)}
        visible={openStripeModal}
        closable={true}
        closeIcon={<>x</>}
        bodyStyle={{ color: THEME_COLOR }}
        footer={[
          <StripeCheckout
            label="Pay with coupon code on stripe"
            stripeKey={process.env.REACT_APP_STRIPE_KEY}
            token={handleToken}
            amount={selectedPrice * 100}
            name={selectedType}
            billingAddress
            shippingAddress
            ComponentClass="payment-header-desc"
            coupon={couponCode} // Add the coupon prop here
            disabled={couponCode ? false : true}
          />,
          <StripeCheckout
            label="Skip"
            stripeKey={process.env.REACT_APP_STRIPE_KEY}
            token={handleToken}
            amount={selectedPrice * 100}
            name={selectedType}
            billingAddress
            shippingAddress
            ComponentClass="payment-header-desc"
            coupon="" // Add the coupon prop here
            disabled={!couponCode ? false : true}
          />,
        ]}
      >
        <div className=" text-center">
          <input
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            className={"full-width inputBox-style height-50 b-5"}
            style={{ padding: "0 10px" }}
            placeholder="Enter your coupon code here"
          />
        </div>
      </Modal> */}
    </div>
  );
}

PaymentMethod.propTypes = {
  getQuestions: PropTypes.func,
  questions: PropTypes.any,
  fetchingQuestions: PropTypes.bool,
  postStripePayment: PropTypes.func,
  postPayPalPayment: PropTypes.func,
};

const mapDispatchToProps = {
  getUser: getUser,
  getQuestions: getQuestions,
  postStripePayment: postStripePayment,
  postPayPalPayment: postPayPalPayment,
  updatePayPalPayment: updatePayPalPayment,
};

const mapStateToProps = ({
  questionReducer: { questions, fetchingQuestions },
  paymentReducer: { plans, fetchingPayments },
  userReducer: { userData, accessToken, refreshToken },
}) => ({
  questions,
  fetchingQuestions,
  plans,
  accessToken,
  refreshToken,
  userData,
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);

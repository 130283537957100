import "./payment.css";

import {
  ALL_FILTERS,
  BRONZE_CONTENT,
  FREE_CONTENT,
  DOWNLOAD_EX4,
  DOWNLOAD_MQ4,
  ERROR,
  LIMITED_FILTERS,
  MODAL_HEADER_PAYMENT_SYNC_ERROR,
  SILVER_CONTENT,
  THEME_COLOR,
  BRONZE,
  PAYMENT_CONTENT1,
  Access_AI,
  DOWNLOAD_MQ5,
  DOWNLOAD_EX5,
  SILVER,
  GOLD,
} from "../../constants/messages";
import { Button, Modal } from "antd";
import React, { Component } from "react";


// import PaymentType from './paymentType';
import Ptype from "./Ptype";
import SideBanner from "../commonComponent/sideBanner";
import { connect } from "react-redux";
import { getPlans } from "../../actions/paymentActions";
import logo from "../../assets/logo.png";
/**
 * login component for users
 */
class PaymentOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentPopUp: false,
      planError: false,
    };
  }

  componentDidMount = () => {
    const { paymentPopUp } = this.props;

    this.props.getPlans({
      callback: (res) => {
        if (res === ERROR) {
          this.setState({
            planError: !this.state.planError,
          });
        } else {
          this.setState({
            paymentPopUp:
              paymentPopUp &&
              this.props.onSubmit &&
              window.location.pathname === "/sign-up",
          });
        }
      },
    });
  };

  handleProceed = () => {
    this.setState({
      paymentPopUp: !this.state.paymentPopUp,
    });
  };

  login = () => {
    this.props.history.push("/login");
    window.location.pathname === "/login" && window.location.reload();
  };

  render() {
    const {
      plans,
      modalvisible,
      setShowPaymentOptions,
      setmodalVisible,
      submitData,
      selectedType,
      loginData,
      paymentFailedCheck,
      paymentPopUpContent,
    } = this.props;

    const bronze =
      plans.length > 0
        ? plans?.find((e) => e?.payment_type.toLowerCase() === BRONZE)
        : {};
    const silver =
      plans.length > 0
        ? plans?.find((e) => e?.payment_type.toLowerCase() === SILVER)
        : {};
    const gold =
      plans.length > 0
        ? plans?.find((e) => e?.payment_type.toLowerCase() === GOLD)
        : {};
    // const free = plans.length > 0 ? plans[1] : {};
    // const paymentPlans = plans.length > 0 ? plans.filter(p => p.price !== 0).sort((a, b) => { return (a.price > b.price) ? 1 : -1 }) : [];

    return (
      <div className={this.props.onSubmit ? " " : "payment-indiv-div"}>
        <div className=" text-center">
          <img alt="logo" className="logo cursor login_logo" src={logo} />
        </div>
        {!this.props.onSubmit && <SideBanner />}
        <div className={`${!this.props.onSubmit} payment-parent-div `}>
          <div className="payment-header-desc">
            <div className="medium-text label">Select your subscription</div>
            <div className="small-text">{PAYMENT_CONTENT1}</div>
          </div>
          <div className="payment-outer-div">
            {/* <Ptype
              history={this.props.history}
              type={"free"}
              onSubmit={this.props.onSubmit}
              content={FREE_CONTENT} price={"Free"}
              modalvisible={modalvisible}
              setShowPaymentOptions={setShowPaymentOptions}
              setmodalVisible={setmodalVisible}
              submitData={submitData}
              selectedType={selectedType}
              options={[
                { content: LIMITED_FILTERS, allowed: true },
                { content: DOWNLOAD_EX4, allowed: true },
                { content: DOWNLOAD_MQ4, allowed: false },
              ]}

            /> */}
            <Ptype
              paymentPopUpContent={paymentPopUpContent}
              history={this.props.history}
              type={bronze.payment_type?.toLowerCase()}
              onSubmit={this.props.onSubmit}
              content={BRONZE_CONTENT}
              price={bronze.price}
              modalvisible={modalvisible}
              setShowPaymentOptions={setShowPaymentOptions}
              setmodalVisible={setmodalVisible}
              submitData={submitData}
              selectedType={selectedType}
              loginData={loginData}
              paymentFailedCheck={paymentFailedCheck}
              options={[
                { content: ALL_FILTERS, allowed: true },
                { content: DOWNLOAD_EX4, allowed: true },
                { content: DOWNLOAD_EX5, allowed: true },
                // { content: DOWNLOAD_MQ4, allowed: false },
                // { content: DOWNLOAD_MQ5, allowed: false },
              ]}
            />
            <Ptype
              paymentPopUpContent={paymentPopUpContent}
              history={this.props.history}
              type={silver.payment_type?.toLowerCase()}
              onSubmit={this.props.onSubmit}
              content={SILVER_CONTENT}
              price={silver.price}
              modalvisible={modalvisible}
              setShowPaymentOptions={setShowPaymentOptions}
              setmodalVisible={setmodalVisible}
              submitData={submitData}
              selectedType={selectedType}
              loginData={loginData}
              paymentFailedCheck={paymentFailedCheck}
              options={[
                { content: ALL_FILTERS, allowed: true },
                { content: DOWNLOAD_EX4, allowed: true },
                { content: DOWNLOAD_EX5, allowed: true },
                // { content: DOWNLOAD_MQ4, allowed: true },
                // { content: DOWNLOAD_MQ5, allowed: true },
                { content: Access_AI, allowed: true },
              ]}
            />
            <Ptype
              paymentPopUpContent={paymentPopUpContent}
              history={this.props.history}
              type={gold?.payment_type?.toLowerCase()}
              onSubmit={this.props.onSubmit}
              content={SILVER_CONTENT}
              price={gold?.price}
              modalvisible={modalvisible}
              setShowPaymentOptions={setShowPaymentOptions}
              setmodalVisible={setmodalVisible}
              submitData={submitData}
              selectedType={selectedType}
              loginData={loginData}
              paymentFailedCheck={paymentFailedCheck}
              options={[
                { content: ALL_FILTERS, allowed: true },
                { content: DOWNLOAD_EX4, allowed: true },
                { content: DOWNLOAD_EX5, allowed: true },
                { content: DOWNLOAD_MQ4, allowed: true },
                { content: DOWNLOAD_MQ5, allowed: true },
                { content: Access_AI, allowed: true },
              ]}
            />
          </div>
        </div>
        <Modal
          title="Please select a plan"
          visible={this.state.paymentPopUp}
          closable={false}
          bodyStyle={{ color: THEME_COLOR }}
          footer={[
            <Button key="submit" type="primary" onClick={this.handleProceed}>
              Proceed
            </Button>,
          ]}
        >
          {/* <p>Your account has been created.</p> */}
          <p>Please select a plan.</p>
        </Modal>

        <Modal
          title={MODAL_HEADER_PAYMENT_SYNC_ERROR}
          visible={this.state.planError}
          bodyStyle={{ color: THEME_COLOR }}
          footer={[
            <Button key="submit" type="primary" onClick={this.login}>
              Login
            </Button>,
          ]}
        >
          <p>
            {" "}
            Your account has been created but payment prices are not in sync.
          </p>
          <p>Please contact administrator.</p>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getPlans: getPlans,
};

const mapStateToProps = ({
  questionReducer: { questions, fetchingQuestions },
  paymentReducer: { plans },
}) => ({
  questions,
  fetchingQuestions,
  plans,
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOptions);

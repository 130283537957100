import React, { useState, useEffect } from "react";
import { Pagination, Rate, Typography, Button } from "antd";
import { ImageBase } from "../../constants/APIConstant";

import DefaultImg from "../../assets/default.png";
import { ROBOT_SUBSCRIBE, PER_MONTH, DOLLAR, EDIT_BUTTON } from "../../constants/messages";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomPagination from "../CustomPagination/CustomPagination";

const RentCards = ({ featureRobot }) => {
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const { Text } = Typography;
  const history = useHistory();

  useEffect(() => {
    setData([]);
    if (featureRobot?.length >0 ) {
      setData(featureRobot);
    }
  }, [featureRobot]);


  const ImageComponent = ({ src, alt, fallbackSrc }) => {
    const handleImageError = (event) => {
      event.target.src = fallbackSrc;
    };

    return <img src={src} alt={alt} onError={handleImageError} />;
  };
  const namehandle = (name) => {
    if (name.length > 25) {
      const first = name?.substring(0, 20) + "..." + name?.slice(-4);
      const second = first?.charAt(0).toUpperCase() + first?.slice(1);
      return second;
    } else {
      return name?.charAt(0)?.toUpperCase() + name?.slice(1);
    }
  };

  return (
    <>
      <div className="card_main">
        {displayData &&
          displayData.map((card) => (
            <div class="card-custom">
              <ImageComponent
                src={`${
                  card.image_1 ? ImageBase.dev + card.image_1 : DefaultImg
                }`}
                alt="Example Image"
                fallbackSrc={DefaultImg}
              />
              <div className="data-content">
                <div className="c_name">
                  <h3>{namehandle(card?.name)}</h3>
                </div>
                <div className="c_bottom">
                  {card.rent_amount ? (
                    <Text className="font price_card">
                      
                      <sup>{DOLLAR} </sup>
                      {card.rent_amount}
                      <span>{PER_MONTH}</span>
                    </Text>
                  ) : (
                    <Text className="font price_card">Free</Text>
                  )}
                  <div className="buttons">
                  <Button type="primary" className="button primary width-full b-5 btns" onClick={() => history.push(`/robot_rent/${card.id}`)}>{EDIT_BUTTON}
                  </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {
        data.length > 0 &&
      <CustomPagination record={data} setDisplayData={setDisplayData} />
      }
    </>
  );
};

export default RentCards;

import { APIService, requestURLS } from "../constants/APIConstant";
import { ERROR, SUCCESS } from "../constants/messages";
import { actionLoginTypes, actionRobotTypes } from "../constants/actionTypes";
import { call, put, takeLatest } from "redux-saga/effects";
import { ifAccessTokenExpired } from "../actions/commonActions";
import TranslateResponse, {
  translateItem,
} from "../components/commonComponent/translate";
import { message } from "antd";
import { refreshToken } from "./userSaga";

export function* getAllRobotStoreList(param) {
  const { accessToken, callback } = param;

  try {
    yield put({ type: actionRobotTypes.SET_ALL_ROBOT_FETCHING });

    const getUrl = APIService.dev + requestURLS.GET_ALL_ROBOT_STORE;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    let response = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    });
    let responseJSON = yield response.json();
    if (!response.ok) {
      throw responseJSON;
    }
    callback(SUCCESS, responseJSON);
    yield put({
      type: actionRobotTypes.ROBOT_ALL_RECIEVED,
      payload: TranslateResponse(responseJSON),
    });
  } catch (e) {
    yield put({
      type: actionRobotTypes.ROBOT_ERROR,
      error: e,
    });
    if (e.ResponseCode === 401) {
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      const newtoken = yield call(refreshToken);

      yield put({ type: actionRobotTypes.SET_ALL_ROBOT_FETCHING });

      const getUrl = APIService.dev + requestURLS.GET_ALL_ROBOT_STORE;
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newtoken}`,
      };
      let response = yield fetch(getUrl, {
        headers: headers,
        method: "GET",
      });
      let responseJSON = yield response.json();
      if (!response.ok) {
        throw responseJSON;
      }
      callback(SUCCESS, responseJSON);
      yield put({
        type: actionRobotTypes.ROBOT_ALL_RECIEVED,
        payload: TranslateResponse(responseJSON),
      });
    }

    callback(ERROR, e);
  }
}

export function* getStoreRobot(param) {
  const { id, accessToken, callback } = param;
  let recievedResponse = {};
  try {
    yield put({ type: actionRobotTypes.SET_ALL_ROBOT_FETCHING });

    const getUrl = APIService.dev + requestURLS.GET_ROBOT_ID + "?id=" + id;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    callback(SUCCESS, translateItem(responseJSON.data));
    yield put({
      type: actionRobotTypes.ROBOT_RECIEVED,
      payload: translateItem(responseJSON.data),
    });
  } catch (e) {
    if (e.ResponseCode === 401) {
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      const newtoken = yield call(refreshToken);
      yield put({ type: actionRobotTypes.SET_ALL_ROBOT_FETCHING });

      const getUrl = APIService.dev + requestURLS.GET_ROBOT_ID + "?id=" + id;
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newtoken}`,
      };
      const responseJSON = yield fetch(getUrl, {
        headers: headers,
        method: "GET",
      }).then((response) => {
        recievedResponse = response;
        return response.json();
      });

      if (!recievedResponse.ok) {
        throw responseJSON;
      }
      callback(SUCCESS, translateItem(responseJSON.data));
      yield put({
        type: actionRobotTypes.ROBOT_RECIEVED,
        payload: translateItem(responseJSON.data),
      });
    }
    yield put({
      type: actionRobotTypes.ROBOT_ERROR,
      error: e,
    });
    callback(ERROR, e);
  }
}
export function* postCopyRobot(param) {
  let { accessToken, callback, data, id } = param;
  try {
    let recievedResponse = {};
    yield put({ type: actionRobotTypes.SET_ALL_ROBOT_FETCHING });

    const postUrl = APIService.dev + requestURLS.COPY_ROBOT + "?id=" + id;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(postUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({
      type: actionRobotTypes.COPY_ROBOT_RECIEVED,
      payload: responseJSON.data,
    });
    callback(SUCCESS, responseJSON.data);
  } catch (e) {
    if (e.ResponseCode === 401) {
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      const newtoken = yield call(refreshToken);
      let recievedResponse = {};
      yield put({ type: actionRobotTypes.SET_ALL_ROBOT_FETCHING });
  
      const postUrl = APIService.dev + requestURLS.COPY_ROBOT + "?id=" + id;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newtoken}`,
      };
      const responseJSON = yield fetch(postUrl, {
        headers: headers,
        method: "POST",
        body: JSON.stringify(data),
      }).then((response) => {
        recievedResponse = response;
        return response.json();
      });
      if (!recievedResponse.ok) {
        throw responseJSON;
      }
      yield put({
        type: actionRobotTypes.COPY_ROBOT_RECIEVED,
        payload: responseJSON.data,
      });
      callback(SUCCESS, responseJSON.data);
    }
    yield put({
      type: actionRobotTypes.ROBOT_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}

export function* robotStoreActionWatcher() {
  yield takeLatest(actionRobotTypes.GET_ALL_ROBOTSTORE, getAllRobotStoreList);
  yield takeLatest(actionRobotTypes.GET_ROBOT, getStoreRobot);
  yield takeLatest(actionRobotTypes.COPY_ROBOT, postCopyRobot);
}


import React from 'react';
import './subscription.css';
import { Rate, Typography, Button } from 'antd';
import { ROBOT_SUBSCRIBE, PER_MONTH, DOLLAR } from '../../../constants/messages';

export default function FeatureCard(props) {
    const { item } = props;
    const { Text } = Typography;
    const rent_amount = item.rent_amount ? item.rent_amount : "Free"
    const namehandle = (name) => {
        if (name.length > 25) {
          const first = name?.substring(0, 20) + "..." + name?.slice(-4);
          const second = first?.charAt(0).toUpperCase() + first?.slice(1);
          return second;
        } else {
          return name?.charAt(0)?.toUpperCase() + name?.slice(1);
        }
      };
    return (
        <div className="column div-pad">

            <div className="data-content">
                <div className="c_name">
                  <h3>{namehandle(item?.name)}</h3>
                  <div className="rating">
                    <Rate
                      disabled
                      value={item?.avg_rating}
                      style={{ color: "#EDA830",fontSize:'18px' }}
                    />
                  </div>
                </div>
                <div className="c_bottom">
                  {item.rent_amount ? (
                    <Text className="font price_card">
                      
                      <sup>{DOLLAR} </sup>
                      {item.rent_amount}
                      <span>{PER_MONTH}</span>
                    </Text>
                  ) : (
                    <Text className="font price_card">Free</Text>
                  )}
                  <div className="buttons">
                    <Button
                      type="primary"
                      className="button primary width-full b-5 btns"
                      onClick={() => props.history.push(`/robot_store/${item?.id}`)}
                    >
                      {ROBOT_SUBSCRIBE}
                    </Button>
                  </div>
                </div>
              </div>
        </div>
    )
}
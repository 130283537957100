const INITIAL_XML =
  '<xml xmlns="https://developers.google.com/blockly/xml"><block type="week_filter" id="iyE~z!gNQMF%d0mRR@@S" x="300" y="38"><field name="NAME">week</field><value name="NAME"><block type="week_filter" id="XUu5f8|gT~!pZgJ?i]YC"><field name="NAME">week</field></block></value></block></xml>';

const INITIAL_TOOLBOX_XML =
  '<xml xmlns="http://www.w3.org/1999/xhtml" id="toolbox" style="display: none;">\n' +
  '  <category name="Initialisation Blocks" colour="#4a38a0" categorystyle="logic_category">\n' +
  '    <block type="configure_filter"></block>\n' +
  "  </category>\n" +
  "  <sep></sep>\n" +
  '  <category name="Artificial Intelligence" colour="#031438" categorystyle="logic_category">\n' +
  '    <block type="configure_filter_ai"></block>\n' +
  "  </category>\n" +
  "  <sep></sep>\n" +
  '  <category name="Indicators" colour="#2e76cc">\n' +
  '    <block type="indicator_filter"></block>\n' +
   "  </category>\n" +
  '  <category name="Conditions and Formulas" colour="#8d52cc">\n' +
  '    <block type="operator_filter"></block>\n' +
  //'    <block type="Crosses_above"></block>\n' +
  //'    <block type="Crosses_below"></block>\n' +
  "  </category>\n" +
  '  <category name="BUY/SELL" colour="#19ea6c">\n' +
  '    <block type="Buy"></block>\n' +
  '    <block type="Sell"></block>\n' +
  "  </category>\n" +
  "  <sep></sep>\n" +
  '  <category name="Filters" colour="#3046cc">\n' +  
  '    <block type="week_filter"></block>\n' +
  '    <block type="time_filter"></block>\n' +
  //'   <block type="bar_filter"></block>\n' +
  "  </category>\n" +
  "  <sep></sep>\n" +
  '  <category name="Prices and Bars" colour="#21aff7">\n' +  
  '   <block type="bar_filter"></block>\n' +
  '   <block type="Ask"></block>\n' +
  '   <block type="Bid"></block>\n' +
  "  </category>\n" +
  "  <sep></sep>\n" +
  '  <category name="Money Management" colour="#e29423">\n' +  
  '   <block type="auto_money"></block>\n' +
  '   <block type="martingale"></block>\n' +
  //'   <block type="trailing_stop"></block>\n' +
  "  </category>\n" +
  "  <sep></sep>\n" +
  '  <category name="Notifications" colour="#F72605">\n' +  
  '   <block type="Alert"></block>\n' +
  //'   <block type="Push_Notification"></block>\n' +
 // '   <block type="Email"></block>\n' +
  "  </category>\n" +
  "  <sep></sep>\n" +
  '  <category name="Custom Code" colour="#324768">\n' +  
  '   <block type="Custom_Code"></block>\n' +
  "  </category>\n" +
  "  <sep></sep>\n" +
  "</xml>";

const INITIAL_TOOLBOX_CATEGORIES = [
  {
    name: "Text",
    blocks: [
      { type: "text" },
      {
        type: "text_print",
        values: {
          TEXT: {
            type: "text",
            shadow: true,
            fields: {
              TEXT: "abc",
            },
          },
        },
      },
    ],
  },
];

const ConfigFiles = {
  INITIAL_XML,
  INITIAL_TOOLBOX_XML,
  INITIAL_TOOLBOX_CATEGORIES,
};

export default ConfigFiles;

import { actionPaymentTypes } from "../constants/actionTypes";

const initialData = {
  plans: {},
  fetchingPayment: false,
  error: '',
};

const paymentReducer = (state = initialData, action) => {
  switch (action.type) {
    case actionPaymentTypes.PAYMENT_FETCHING:
      return {
        ...state,
        fetchingPayment: !state.fetchingPayment,
      };
    case actionPaymentTypes.PLANS_RECIEVED:
      return {
        ...state,
        plans: action.payload.data,
        fetchingPayment: false,
      };

      case actionPaymentTypes.PAYMENT_RECEIVED:
      return {
        ...state,
        fetchingPayment: false,
      };

      case actionPaymentTypes.PAYMENT_ERROR:
      return {
        ...state,
        fetchingPayment: false,
        error: action.error
      };
    
    default:
      return state;
  }
};
export default paymentReducer;

/**
 * @fileoverview Main Drawer Component
 */
import { Drawer } from 'antd';
import React from 'react';

function DrawerBlock(props) {

    const { title, onClose, visible } = props;

    return (
        <Drawer
            title={title}
            placement={'right'}
            closable={true}
            width={330}
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            key={'right'} >
            { props.children}
        </Drawer>
    );
}

export default DrawerBlock;

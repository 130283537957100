import { APIService, requestURLS } from "../constants/APIConstant";
import { ERROR, SUCCESS } from "../constants/messages";

import { call, put, takeLatest } from "redux-saga/effects";

import { actionLoginTypes, actionWorkspaceTypes } from "../constants/actionTypes";
import { message } from "antd";
import { ifAccessTokenExpired } from "../actions/commonActions";

export function* logOut(param) {
  try {
    yield put({ type: actionLoginTypes.LOG_OUT });
    param.callback();
  } catch (e) {
    yield put({
      type: actionLoginTypes.USER_ERROR,
      error: e,
    });
  }
}

export function* refreshToken() {
  try {
    yield put({ type: actionLoginTypes.SET_USER_FETCHING });
    const refreshToken = localStorage.getItem("refresh");
    const getUrl = APIService.dev + requestURLS.TOKEN;
    const response = yield fetch(getUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });
    const responseJSON = yield response.json();

    if (response.ok) {
      yield put({  type: actionLoginTypes.USER_ERROR });
      localStorage.setItem("token", responseJSON.access);
      yield put({
        type: actionLoginTypes.REFRESH_TOKEN_SUCCESS,
        payload: responseJSON.access,
      });
      return responseJSON.access;
    } else {
      throw new Error("Token refresh failed");
    }
    
  } catch (e) {
    console.log('error');
    
    localStorage.clear();
    yield put({
      type: actionLoginTypes.LOG_OUT,
      error: e,
    });
    message.error('Token expired, please login again');

      window.location.replace("/login");

  }
}


export function* getUser(param) {
  const { email, password, callback } = param;
  let recievedResponse = {};
  try {
    yield put({ type: actionLoginTypes.SET_USER_FETCHING });

    const getUrl = APIService.dev + requestURLS.LOGIN;
    let headers = {
      "Content-Type": "application/json",
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify({ email, password }),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }

    if(responseJSON?.data==="subscriptionFailed"){
      callback(ERROR, responseJSON);
      yield put({ type: actionLoginTypes.SET_USER_FETCHING });
    }else{
    localStorage.setItem('lastActiveDate',  new Date()); 
    localStorage.setItem("subscription_plan", responseJSON.data.subscription_plan);
    if (responseJSON.data.subscription_plan === "free -> 0.0 USD") {
      localStorage.setItem("token", responseJSON.data.access);
      localStorage.setItem("refresh", responseJSON.data.refresh);
      yield put({
        type: actionLoginTypes.USER_RECIEVED_LOGIN,
        payload: { ...responseJSON.data },
      });
      localStorage.setItem("token", responseJSON.data.access);
      if (responseJSON.data.user.is_coupon_used) {
        localStorage.setItem(
          "is_coupon_used",
          responseJSON.data.user.is_coupon_used
        );
      }
      this.props.history.push("/workspace");
    } else if (responseJSON.message === SUCCESS) {
      localStorage.setItem("token", responseJSON.data.access);
      localStorage.setItem("refresh", responseJSON.data.refresh);
      yield put({
        type: actionLoginTypes.USER_RECIEVED,
        payload: { ...responseJSON.data },
      });
      localStorage.setItem("token", responseJSON.data.access);
      if (responseJSON.data.user.is_coupon_used) {
        localStorage.setItem( "is_coupon_used", responseJSON.data.user.is_coupon_used );
      }
      this.props.history.push("/workspace");
    
    }
    else {
      callback(ERROR, responseJSON);
      yield put({ type: actionLoginTypes.SET_USER_FETCHING });
    }
  }
  } catch (e) {
    yield put({
      type: actionLoginTypes.USER_ERROR,
      error: e,
    });
    callback("inactive", e);
  }
}

export function* logoutUsers(param) {
  const { accessToken } = param;
  let recievedResponse = {};
  const formData = new FormData();
  formData.append('access',localStorage.getItem('token'));
  formData.append('refresh',localStorage.getItem('refresh'));
  try {
    yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FILE_RECIEVED });
   
    const getUrl = APIService.dev + requestURLS.LOGOUT_USERS;
    let headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "post",
      body: formData
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    localStorage.clear();
    yield put({ type: actionWorkspaceTypes.SET_WORKSPACE_FILE_RECIEVED });
  } catch (e) {
    if(e.ResponseCode===401){
     
    }
  }
}
export function* getUserSettings(param) {
  const { accessToken, callback } = param;
 
  let recievedResponse = {};
  try {
    yield put({ type: actionLoginTypes.SET_USER_FETCHING });

    const getUrl = APIService.dev + requestURLS.GET_USER_SETTINGS;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({
      type: actionLoginTypes.USER_SETTINGS_RECIEVED,
      payload: responseJSON.data,
    });
    callback(responseJSON.data);
  } catch (e) {
    if(e.ResponseCode===401){
          yield put({ type: actionLoginTypes.TOKEN_EMPTY });
        const newtoken= yield call(refreshToken);
          yield put({ type: actionLoginTypes.SET_USER_FETCHING });

        const getUrl = APIService.dev + requestURLS.GET_USER_SETTINGS;
        let headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${newtoken}`,
        };
        const responseJSON = yield fetch(getUrl, {
          headers: headers,
          method: "GET",
        }).then((response) => {
          recievedResponse = response;
          return response.json();
        });

        if (!recievedResponse.ok) {
          throw responseJSON;
        }
        yield put({
          type: actionLoginTypes.USER_SETTINGS_RECIEVED,
          payload: responseJSON.data,
        });
        callback(responseJSON.data);
    }
    yield put({
      type: actionLoginTypes.USER_ERROR,
      error: e,
    });
    callback(ERROR,e.detail);
  }
}

export function* postUserSettings(param) {
  const { accessToken, data } = param;
  let recievedResponse = {};
  if (ifAccessTokenExpired(accessToken)) {
    return;
  }
  try {
    yield put({ type: actionLoginTypes.SET_USER_FETCHING });

    const getUrl = APIService.dev + requestURLS.POST_USER_SETTINGS;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({
      type: actionLoginTypes.USER_SETTINGS_RECIEVED,
      payload: responseJSON.data,
    });
  } catch (e) {
    if(e.ResponseCode===401){
      
    }
    yield put({
      type: actionLoginTypes.USER_ERROR,
      error: e,
    });
   
  }
}

export function* postUser(param) {
  const { data, callback } = param;
  try {
    let recievedResponse = {};
    yield put({ type: actionLoginTypes.SET_USER_FETCHING });

    const getUrl = APIService.dev + requestURLS.REGISTER;
    let headers = {
      "Content-Type": "application/json",
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    // if (responseJSON.message === SUCCESS) {
    //   yield put({
    //     type: actionLoginTypes.USER_RECIEVED,
    //     payload: {...responseJSON.data},
    //   });
    //   localStorage.setItem("token", responseJSON.data.access);
    //   this.props.history.push("/workspace");
    // }
    else if (responseJSON.subscription_plan === "free") {
      yield put({
        type: actionLoginTypes.USER_RECIEVED_LOGIN,
        payload: { ...responseJSON },
      });
      localStorage.setItem("token", responseJSON.access);
      this.props.history.push("/workspace");
    } else {
      callback(SUCCESS, responseJSON);
      yield put({ type: actionLoginTypes.SET_USER_FETCHING });
    }

    // yield put({
    //   type: actionLoginTypes.SET_USER_FETCHING,
    // });

    // callback(SUCCESS);
  } catch (e) {
    if(e.ResponseCode===401){
      
    }
    yield put({
      type: actionLoginTypes.USER_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}

export function* getQuestions(param) {
  const { data, callback } = param;
  try {
    let recievedResponse = {};
    yield put({ type: actionLoginTypes.SET_USER_FETCHING });

    //make API call here
    const getUrl = APIService.dev + requestURLS.SECURITY_QUE;
    const headers = {
      "Content-Type": "application/json",
    };
    let responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({ type: actionLoginTypes.SET_USER_FETCHING });
    yield put({
      type: actionLoginTypes.QUESTIONS_RECIEVED,
      payload: responseJSON.data,
    });
    callback(SUCCESS, responseJSON);
  } catch (e) {
    yield put({
      type: actionLoginTypes.USER_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}

export function* forgotPassword(param) {
  const { data, callback } = param;
  try {
    let recievedResponse = {};
    yield put({ type: actionLoginTypes.SET_USER_FETCHING });

    const getUrl = APIService.dev + requestURLS.RESET_PASSWORD;
    const headers = {
      "Content-Type": "application/json",
    };

    let responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "PATCH",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }

    yield put({ type: actionLoginTypes.SET_USER_FETCHING });
    callback();
  } catch (e) {
    yield put({
      type: actionLoginTypes.USER_ERROR,
      error: e,
    });
    callback(e.message);
  }
}

export function* changeEmail(param) {
  const { accessToken, data, callback } = param;
  let recievedResponse = {};
  try {
    yield put({ type: actionLoginTypes.SET_USER_FETCHING });

    const getUrl = APIService.dev + requestURLS.CHANGE_EMAIL;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    callback(SUCCESS, responseJSON);
    yield put({
      type: actionLoginTypes.EMAIL_UPDATED,
      payload: responseJSON,
    });
  } catch (e) {
    if(e.ResponseCode===401){
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      const newtoken= yield call(refreshToken);
      yield put({ type: actionLoginTypes.SET_USER_FETCHING });

      const getUrl = APIService.dev + requestURLS.CHANGE_EMAIL;
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newtoken}`,
      };
      const responseJSON = yield fetch(getUrl, {
        headers: headers,
        method: "POST",
        body: JSON.stringify(data),
      }).then((response) => {
        recievedResponse = response;
        return response.json();
      });
  
      if (!recievedResponse.ok) {
        throw responseJSON;
      }
      callback(SUCCESS, responseJSON);
      yield put({
        type: actionLoginTypes.EMAIL_UPDATED,
        payload: responseJSON,
      });
    }
    yield put({ type: actionLoginTypes.USER_ERROR });
    callback(ERROR, e);
  }
}

export function* resendCode(param) {
  const { data, callback } = param;

  try {
    let recievedResponse = {};
    yield put({ type: actionLoginTypes.SET_API_FETCHING });

    const getUrl = APIService.dev + requestURLS.RESEND_CODE;
    let headers = {
      "Content-Type": "application/json",
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    } else {
      callback(SUCCESS, responseJSON);
      yield put({ type: actionLoginTypes.SET_API_FETCHING });
    }
  } catch (e) {
    if(e.ResponseCode===401){
      
    }
    yield put({
      type: actionLoginTypes.USER_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}
export function* verifyCode(param) {
  const { data, callback } = param;

  try {
    let recievedResponse = {};

    const getUrl = APIService.dev + requestURLS.VERIFY_CODE;
    let headers = {
      "Content-Type": "application/json",
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    } else {
      callback(SUCCESS, responseJSON);
    }
  } catch (e) {
   
    yield put({
      type: actionLoginTypes.USER_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}
export function* verifyCouponCode(param) {
  const { data, callback } = param;

  try {
    let recievedResponse = {};

    const getUrl = APIService.dev + requestURLS.COUPON_VERIFY + data.couponCode;
    let headers = {
      "Content-Type": "application/json",
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    } else {
      callback(SUCCESS, responseJSON);
    }
  } catch (e) {
  
    yield put({
      type: actionLoginTypes.USER_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}
export function* updateverifyCouponCode(param) {
  const { data, callback } = param;

  try {
    let recievedResponse = {};

    const getUrl = APIService.dev + requestURLS.UPDATE_COUPON_VERIFY;
    let headers = {
      "Content-Type": "application/json",
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    } else {
      callback(SUCCESS, responseJSON);
    }
  } catch (e) {
    
    yield put({
      type: actionLoginTypes.USER_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}
export function* verifyUser(param) {
  const { data, callback } = param;
  try {
    let recievedResponse = {};

    const getUrl = `${APIService.dev}${requestURLS.CHECK_USER}?email=${data.email}`;
    let headers = {
      "Content-Type": "application/json",
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    } else {
      callback(SUCCESS, responseJSON);
    }
  } catch (e) {
    yield put({
      type: actionLoginTypes.USER_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}
export function* userActionWatcher() {
  yield takeLatest(actionLoginTypes.LOGGING_OUT, logOut);
  yield takeLatest(actionLoginTypes.GET_USER, getUser);
  yield takeLatest(actionLoginTypes.POST_USER, postUser);
  yield takeLatest(actionLoginTypes.GET_SECURITY_QUE, getQuestions);
  yield takeLatest(actionLoginTypes.FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(actionLoginTypes.GET_USER_SETTINGS, getUserSettings);
  yield takeLatest(actionLoginTypes.POST_USER_SETTINGS, postUserSettings);
  yield takeLatest(actionLoginTypes.CHANGE_EMAIL, changeEmail);
  yield takeLatest(actionLoginTypes.RESEND_CODE, resendCode);
  yield takeLatest(actionLoginTypes.VERIFY_CODE, verifyCode);
  yield takeLatest(actionLoginTypes.VERIFY_COUPON_CODE, verifyCouponCode);
  yield takeLatest(actionLoginTypes.UPDATE_VERIFY_COUPON_CODE, updateverifyCouponCode);
  yield takeLatest(actionLoginTypes.VERIFY_USER, verifyUser);
  yield takeLatest(actionLoginTypes.LOG_OUT_USER, logoutUsers);
}

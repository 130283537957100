import React, { useState, useEffect } from 'react';
import { TIME_PARAMETER } from '../../../constants/constants.filter';
import { HELP_TEXT_TIME_DRAWER } from '../../../constants/constants.popover';
import { COMMON_TEXT, STEP } from '../../../constants/constants.string';
import { isEmpty } from '../../../utils/utils';
import { InputField } from './indicatorDrawer'
import { BarInputField } from './barDrawer'
import UpdateButton from './updateButton';

const TimeDrawer = (props) => {

    const { code, onCodeChange, setDrawerState } = props

    // sets parameters corresponding to filter type
    const [codeBlock, setCodeBlock] = useState({});
    const [codeBlockError, setCodeBlockError] = useState({})
    const [popOverContent, setPopOverContent] = useState({})

    useEffect(() => {
        !isEmpty(code) && setCodeBlock({ ...code })
        setCodeBlockError({})
        setPopOverContent({ ...HELP_TEXT_TIME_DRAWER })
    }, [code])

    /**
     * @param {string} value value of the field
     * @param {string} fieldName name of the field
     * @description handles change in input fields
     */
    const handleFieldChange = (value, fieldName) => {
        let tempCodeBlock = JSON.parse(JSON.stringify(codeBlock));
        tempCodeBlock[fieldName]['VALUE'] = value;
        setCodeBlock({ ...tempCodeBlock })
        setCodeBlockError({
            ...codeBlockError,
            [fieldName]: {
                status: (value || value === 0) ? false : true,
                help: (value || value === 0) ? null : COMMON_TEXT.EMPTY_STRING
            }
        })
    }

    // validation states for empty field check
    const [isValid, setIsValid] = useState(true)
    useEffect(() => {
        if (Object.keys(codeBlockError).length) {
            const isValid = !Boolean(Object.values(codeBlockError).filter(i => i.status).length)
            setIsValid(isValid)
        }
    }, [codeBlockError])

    const handleCheckChange = (isChecked, fieldName) => {
        let tempCodeBlock = JSON.parse(JSON.stringify(codeBlock));
        tempCodeBlock[fieldName]['ADJUSTABLE'] = isChecked;
        setCodeBlock(tempCodeBlock)
    }


    /**
     * @description on Update button clicked
     */
    const handleOnUpdateClick = () => {
        if (isValid) {
            onCodeChange({ ...codeBlock })
            setDrawerState(false)
        }
    }

    const fields = Object.keys(TIME_PARAMETER).map(key => TIME_PARAMETER[key])
      
    return (
        <div className="drawer-body">
            <div className="property-header">{COMMON_TEXT.PROPERTIES}</div>
            <div className="property-body">
                {fields && fields.map(field =>
                    codeBlock[field.NAME] && <InputField key={field.NAME} label={field.NAME}
                        content={popOverContent[field.NAME]}
                        value={codeBlock[field.NAME].VALUE}
                        //value={codeBlock[field.NAME].VALUE}
                        //step={STEP.FLOAT}
                        typeOfField='string'
                        help={codeBlockError[field.NAME] && codeBlockError[field.NAME].help}
                        error={codeBlockError[field.NAME] && codeBlockError[field.NAME].status}
                        onChange={(event) => handleFieldChange(event.target.value, field.NAME)} /> 
                        /*
                        <InputField key={field.NAME} label={field.NAME}
                                content={popOverContent[field.NAME]}
                                checked={codeBlock[field.NAME].ADJUSTABLE}
                                value={codeBlock[field.NAME].VALUE}
                                help={codeBlockError[field.NAME] && codeBlockError[field.NAME].help}
                                error={codeBlockError[field.NAME] && codeBlockError[field.NAME].status}
                                onChange={(event) => handleFieldChange(event.target.value, field.NAME, field.REQUIRED)}
                                onCheckChange={(event) => handleCheckChange(event.target.checked, field.NAME)} /> */
                                
                )}
            </div>
            <UpdateButton onUpdate={handleOnUpdateClick} />
        </div>
    );
}

export default TimeDrawer;

import { PayPalButton } from "react-paypal-button-v2";
import React from 'react';

export function PayPalBtn(props) {
  const { amount, currency, createSubscription, onApprove, catchError,onError, onCancel,stopSpin} = props;
  const paypalKey = process.env.REACT_APP_PAYPAL_KEY
  return (
    <PayPalButton
      amount={amount}
      currency={currency}
      createSubscription={(data, details) => createSubscription(data, details)}
      onButtonReady={stopSpin}
      onApprove={(data, details) => onApprove(data, details)}
      onError={(err) => onError(err)}
      catchError={(err) => catchError(err)}
      onCancel={(err) => onCancel(err)}
      options={{
        clientId: paypalKey,
        vault:true,
        commit:true,
      }}
      style={{
        shape: 'rect',
        color: 'white',
        layout: 'horizontal',
        tagline:false,
        height: 55, 
      }}
    />
  );
}

export default PayPalBtn;
import React, { useState } from "react";
import { ReactComponent as Background } from "./Svg.svg";
import "./payment.css";
import { Button, Modal, message } from "antd";
import SelectDropDown from "../commonComponent/selectDropdown";
import Nano from "../../assets/robotasset/nanobot.png";
import Mate from "../../assets/robotasset/materobot.png";
import Magaton from "../../assets/robotasset/magton.png";
import {
  CANCEL,
  ERROR,
  LOADER,
  SUCCESS,
  THEME_COLOR,
  VERIFY_EMAIL,
  HIDE_FREE_TRIAL,
  SILVER,
  GOLD,
  BRONZE,
} from "../../constants/messages";

import { connect } from "react-redux";

import {
  resendCode,
  updateverifyCouponCode,
  verifyCode,
  verifyCouponCode,
} from "../../actions/commonActions";
import { useHistory } from "react-router-dom";

const Ptype = (props) => {
  const {
    type,
    price,
    content,
    options,
    modalvisible,
    setShowPaymentOptions,
    verifyCouponCode,
    updateverifyCouponCode,
    setmodalVisible,
    resendCode,
    fetchingUser,
    submitData,
    verifyCode,
    apiloader,
    selectedType,
    loginData,
    paymentFailedCheck,
    paymentPopUpContent,
  } = props;
  const [openStripeModal, setOpenStripeModal] = useState(false);
  const [code, setcode] = useState("");
  const [codeseterror, setcodeerror] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [resLoad, setresLoad] = useState(false);
  const [couponLoad, setcouponLoad] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentpro, setcurrentpro] = useState('');

  const plansPromo = [
    {
      type:BRONZE,
      img: Nano,
      title: "NanoBot",
      price: '$1,000 USD'
    },
    {
      type: SILVER,
      img: Mate,
      title: "RoboMate",
      price: '$2,500 USD'
    },
    {
      type:GOLD,
      img: Magaton,
      title: "MegaTron",
      price: '$15,000 USD'
    },
  ];
  
  const showModal = (type) => {
    const currentpromotype=plansPromo?.find(el=>el.type===type)
    setcurrentpro(currentpromotype);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    window.open("https://myaccount.razemarkets.com/#/register?p6=cx-35131_356402&affid=8809406757", "_blank")
    setIsModalOpen(false);
  };
  const modaleclos = () => {
    setcodeerror(false);
    setcode("");
    setmodalVisible(false);
  };
  const navigate = useHistory();
  const hideFreetail = paymentPopUpContent === HIDE_FREE_TRIAL;
  const verifyemail = () => {
    if (code) {
      const data = { email: submitData?.email, verification_code: code };
      setresLoad(true);
      verifyCode({
        data: { ...data },
        callback: (resp, data) => {
          setresLoad(false);
          if (resp !== ERROR) {
            setmodalVisible(false);
            message.success(data.message);
            if (selectedType === "free") {
              navigate.push("/login");
            } else {
              setShowPaymentOptions(true);
            }
          } else {
            message.error(data);
          }
        },
      });
    } else {
      setcodeerror(true);
    }
  };

  const handleResendCode = () => {
    const data = { email: submitData?.email };
    resendCode({
      data: { ...data },
      callback: (resp, data) => {
        if (resp === SUCCESS) {
          message.success(data.message);
        } else {
          message.error(data);
        }
      },
    });
  };

  const verfifyCoupon = () => {
    if (couponCode) {
      setcouponLoad(true);
      const data = { email: submitData?.email, couponCode: couponCode };
      verifyCouponCode({
        data: { ...data },
        callback: (resp, data) => {
          setcouponLoad(false);
          if (resp === SUCCESS) {
            // message.success(data.message);
            if (paymentFailedCheck) {
              const update_data = {
                email: loginData?.email,
                coupon_code: couponCode,
                subscription_plan: "free",
              };
              updateverifyCouponCode({
                data: { ...update_data },
                callback: (resp, data) => {
                  setcouponLoad(false);
                  if (resp === SUCCESS) {
                    message.success(data.message);
                    setOpenStripeModal(false);
                    props.onSubmit("free", "free", couponCode);
                  } else {
                    message.error(data);
                  }
                },
              });
            } else {
              setOpenStripeModal(false);
              props.onSubmit("free", "free", couponCode);
            }
          } else {
            message.error(data);
          }
        },
      });
    } else {
      message.error("Enter your coupon code");
    }
  };
  const freeTrail = () => {
    props.onSubmit("free", "free", "");
  };
  const buttonReCoupon = couponLoad ? (
    <Button key="" className="b-5 btns" type="primary">
      {LOADER}
    </Button>
  ) : (
    <Button key="submit" type="primary" onClick={verfifyCoupon}>
      Verify
    </Button>
  );
  const buttonRe = resLoad ? (
    <Button key="" className="b-5 btns" type="primary">
      {LOADER}
    </Button>
  ) : (
    <Button
      key="submit"
      className="b-5 btns"
      type="primary"
      onClick={verifyemail}
    >
      {VERIFY_EMAIL}
    </Button>
  );

  return (
    <section>
    <>

      {/* <div className="pricing pricing-palden">
        <div className="pricing-item">
          <div className={`pricing-deco ${type}`}>
            <Background />
            <div className="pricing-price">
              {price === "Free" ? (
                ""
              ) : (
                <span className="pricing-currency">$</span>
              )}
              {price && <>{price === "Free" ? "Free" : `${price}`}</>}
              {price === "Free" ? (
                ""
              ) : (
                <span className="pricing-period">/ mo</span>
              )}
            </div>
            <h3 className="pricing-title">({type})</h3>
          </div>
          <ul className="pricing-feature-list">
            <li className="pricing-feature">
              {price === "Free" ? (
                <div style={{ height: 50 }}></div>
              ) : (
                <SelectDropDown
                  optionsList={[`US $${price || ""} Billed Monthly`]}
                  styleClass="theme-text selectBox height-50 full-width"
                  value={`US $${price || ""} Billed Monthly`}
                  placeholder=""
                  handleChange={() => {}}
                />
              )}
            </li>
            <li>{content}</li>
            <li className=" pricing-feature" style={{ paddingBottom: "5px" }}>
              <button
                onClick={() =>
                  props.onSubmit
                    ? props.onSubmit(type, price)
                    : props.history.push("/login")
                }
                className="padding_btn pricing-action"
              >
                {price === "Free" ? "Sign up" : "Buy Now"}
              </button>
            </li>
            {type === "bronze" && (
              <div className="btn_payment">
                <p className="padding_or" style={{ marginBottom: "5px" }}>
                  OR
                </p>
                <p
                  className="coupon_verify"
                  onClick={() => setOpenStripeModal(true)}
                >
                  Apply Coupon  
                </p>
              </div>
            )}
            {type === "bronze" && !hideFreetail && (
              <div className="btn_payment">
                <p className="padding_or" style={{ marginBottom: "5px" }}>
                  OR
                </p>
                <p className="coupon_verify bronze" onClick={freeTrail}>
                  14 days Free trial
                </p>
              </div>
            )}
            <li className="pricing-feature">
              <div className="payment-footer">
                {options.map((data, index) => {
                  return (
                    <div
                      className={!data.allowed ? "disable-text" : undefined}
                      key={index}
                    >
                      <span className={`${!data.allowed && "cross-mark"}`}>
                        {" "}
                        {data.allowed ? `✓ ` : "✗ "}
                      </span>
                      {data.content}
                    </div>
                  );
                })}
              </div>
            </li>
          </ul>
        </div>
      </div> */}
    </>
      <div className="paymeny_card_main">
        <div className="pay_card_main">
          <img
            src={type === BRONZE ? Nano : type === SILVER ? Mate : Magaton}
            alt=""
          />
          <div className="bottom_card">
            <div className="card_price">
              ${price}
              <span>/Month</span>
            </div>
            <div className="card_details">
              {options.map((data, index) => {
                return (
                  <div
                    className={!data.allowed ? "disable-text" : undefined}
                    key={index}
                  >
                    <span
                      className={`${!data.allowed && "cross-mark"}`}
                      style={{ marginRight: "5px" }}
                    >
                      {" "}
                      {data.allowed ? `✓ ` : "✗ "}
                    </span>
                    {data.content}
                  </div>
                );
              })}
            </div>

            <span onClick={() => showModal(type)} className="animate-charcter">
              CLICK HERE TO GET FREE ACCESS
            </span>
            <div className="pay_btn_card">
              <button
                onClick={() =>
                  props.onSubmit
                    ? props.onSubmit(type, price)
                    : props.history.push("/login")
                }
              >
                Buy now
              </button>
              {type === BRONZE && (
                <div className="btn_payment">
                  <p className="padding_or">OR</p>
                  <p
                    className="coupon_verify"
                    onClick={() => setOpenStripeModal(true)}
                  >
                    Apply Coupon  
                  </p>
                </div>
              )}
              {/* {type === "bronze" && !hideFreetail && (
                <div className="btn_payment">
                  <p className="padding_or" style={{ marginBottom: "5px" }}>
                    OR
                  </p>
                  <p className="coupon_verify bronze" onClick={freeTrail}>
                    14 days Free trial
                  </p>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Verify your E-mail Address"
        visible={modalvisible}
        bodyStyle={{ color: THEME_COLOR }}
        closable={false}
        // onCancel={() => modaleclos()}
        footer={[
          // <Button
          //   key="cancel"
          //   className="btns b-5"
          //   type="default"
          //   onClick={() => modaleclos()}
          // >
          //   {CANCEL}
          // </Button>,
          buttonRe,
        ]}
      >
        <div className=" text-center">
          <h3 style={{ color: "#57387d" }}>
            Your code has been sent to{" "}
            <span style={{ fontWeight: 600 }}>{submitData?.email}</span>{" "}
          </h3>
          <input
            value={code}
            onChange={(e) => setcode(e.target.value)}
            className={
              codeseterror && code?.length === 0
                ? "login_input full-width inputBox-style height-50 b-5"
                : "full-width inputBox-style height-50 b-5"
            }
            style={{ padding: "0 10px" }}
            placeholder="Enter your code here"
          />

          {apiloader ? (
            <p style={{ margin: "10px 0 0" }}>Loading...</p>
          ) : (
            <p className="resend_code" onClick={handleResendCode}>
              Resend code
            </p>
          )}
        </div>
      </Modal>
      <Modal
        title="Coupon Code"
        onCancel={() => setOpenStripeModal(false)}
        visible={openStripeModal}
        closable={true}
        closeIcon={<>x</>}
        bodyStyle={{ color: THEME_COLOR }}
        footer={[buttonReCoupon]}
      >
        <div className=" text-center">
          <input
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            className={"full-width inputBox-style height-50 b-5"}
            style={{ padding: "0 10px" }}
            placeholder="Enter your coupon code here"
          />
        </div>
      </Modal>
      <Modal
        title=""
        visible={isModalOpen}
        closable={true}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        style={{ textAlign: "center" }}
        width={750}
      >
        <div className="promo">
          <h1 style={{ fontWeight: "700", textAlign: "center" }}>
            <img
              src={currentpro.img}
              alt=""
            />
            PROMO OFFER
          </h1>
          <h3 style={{ fontWeight: "700", textAlign: "center" }}>
            Get FREE access to {currentpro.title} Package
          </h3>
          <ul>
            <li>Open a FREE Trading Account with our recommended Brokerage</li>
            <li>Deposit at least {currentpro.price}</li>
            <li>You can withdraw funds at any time</li>
            <li>
              Simply place trades worth 3 lots and that’s it, please let us know
              for FREE access!
            </li>
            <span>
              * Please keep account active by placing trades now and again
            </span>
          </ul>
          <Button
            key="submit"
            style={{ marginRight: "15px" }}
            type="primary"
            onClick={handleOk}
          >
            Get started
          </Button>
        </div>
      </Modal>
    </section>
  );
};

const mapStateToProps = ({
  userReducer: { fetchingUser, apiloader },
  paymentReducer: { plans },
}) => ({
  fetchingUser,
  plans,
  apiloader,
});

const mapDispatchToProps = {
  resendCode: resendCode,
  verifyCode: verifyCode,
  verifyCouponCode: verifyCouponCode,
  updateverifyCouponCode: updateverifyCouponCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ptype);

import * as jwt from 'jsonwebtoken';

import { actionLoginTypes, actionQuestionTypes } from "../constants/actionTypes";

//get user data 
export const logoutUsers = ({ accessToken }) => {
  return {
    type: actionLoginTypes.LOG_OUT_USER,
    accessToken: accessToken
  };
};
export const getUser = ({ email, password, callback }) => {
  return {
    type: actionLoginTypes.GET_USER,
    email: email,
    password: password,
    callback: callback
  };
};

//get user data 
export const getUserSettings = ({ accessToken, callback }) => {
  return {
    type: actionLoginTypes.GET_USER_SETTINGS,
    accessToken: accessToken,
    callback: callback,
  };
};


//get user data 
export const changeUserEmail = ({ accessToken, data, callback }) => {
  return {
    type: actionLoginTypes.CHANGE_EMAIL,
    accessToken: accessToken,
    data: data,
    callback: callback,
  };
};


//get user data 
export const postUserSettings = ({ accessToken, data }) => {
  return {
    type: actionLoginTypes.POST_USER_SETTINGS,
    accessToken: accessToken,
    data: data
  };
};

//post user for user creation
export const postUser = ({ data, callback }) => {
  return {
    type: actionLoginTypes.POST_USER,
    data: data,
    callback: callback
  }
}

export const resendCode = ({ data, callback }) => {
  return {
    type: actionLoginTypes.RESEND_CODE,
    data: data,
    callback: callback
  }
}
export const verifyCode = ({ data, callback }) => {
  return {
    type: actionLoginTypes.VERIFY_CODE,
    data: data,
    callback: callback
  }
}
export const verifyCouponCode = ({ data, callback }) => {
  return {
    type: actionLoginTypes.VERIFY_COUPON_CODE,
    data: data,
    callback: callback
  }
}
export const verifyUser = ({ data, callback }) => {
  return {
    type: actionLoginTypes.VERIFY_USER,
    data: data,
    callback: callback
  }
}
export const updateverifyCouponCode = ({ data, callback }) => {
  return {
    type: actionLoginTypes.UPDATE_VERIFY_COUPON_CODE,
    data: data,
    callback: callback
  }
}

//get verification code for forgot password
export const getSecurityQuestions = ({ data, callback }) => {
  return {
    type: actionLoginTypes.GET_SECURITY_QUE,
    data: data,
    callback: callback
  }
}

//submit change password request for forgot password
export const postForgotPassword = ({ data, callback }) => {
  return {
    type: actionLoginTypes.FORGOT_PASSWORD,
    data: data,
    callback: callback
  }
}


//clear store data and local storage and log user out
export const logOutUser = ({ callback }) => {
  return {
    type: actionLoginTypes.LOGGING_OUT,
    callback: callback
  }
}


// get question
export const getQuestions = () => {
  return {
    type: actionQuestionTypes.QUESTIONS,
  }
}

export const checkResponse = (response, responseJson) => {
  if (!response.ok) {
    throw responseJson;
  } else return;
};

export const ifAccessTokenExpired = (token) => {
  // var decoded = jwt.decode(token, { complete: true });
  // const currentTime = Math.floor(new Date().getTime() / 1000);
  // if (decoded && currentTime > decoded.payload.exp) {
  //   localStorage.clear();
  //   window.location.replace("/login");
  //   return true;
  // }
  return false;
};

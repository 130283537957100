import { actionMySubRobotTypes } from "../constants/actionTypes";

const initialData = {
  fetchingAllMySubRobot: false,
  allMySubRobotData: {},
  allMySubRobotList: [],
  MysubRobotRating: []
};

const mySubRobotReducer = (state = initialData, action) => {

  switch (action.type) {
    case actionMySubRobotTypes.SET_ALL_MY_SUB_ROBOT_FETCHING:
      return {
        ...state,
        fetchingAllMySubRobot: !state.fetchingAllMySubRobot,
      };
    case actionMySubRobotTypes.MY_SUB_ROBOT_RECIEVED:
      return {
        ...state,
        allMySubRobotData: action.payload,
        fetchingAllMySubRobot: false,
      };
    case actionMySubRobotTypes.MY_SUB_ROBOT_ALL_RECIEVED:
      return {
        ...state,
        allMySubRobotList: action.payload,
        fetchingAllMySubRobot: false,
      };
    case actionMySubRobotTypes.RATING_RECEVIED:
      return {
        ...state,
        MysubRobotRating: action.payload,
        fetchingAllMySubRobot: false,
      };
    default:
      return state;
  }
};
export default mySubRobotReducer;
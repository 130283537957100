/**
 * @fileoverview robot store window for robot subscription
 */

import React, { useState } from 'react';
import './subscription.css';
import { Row, Col } from 'antd';
import SwipeableViews from "react-swipeable-views";
import {
    LeftOutlined, RightOutlined,
} from '@ant-design/icons';

export default function CarouselTitle(props) {
    const { list=[], ItemComp, parentUpdate, onItemClick, chunkSize } = props
    const groups = list.map((e, i) => {
        return i % chunkSize === 0 ? list.slice(i, i + chunkSize) : null;
    }).filter(e => { return e; });
    const [count, setCount] = useState(0);
    const prevScreen = () => {
        if (groups.length > 0) {
            if (count === 0) {
                setCount(SLIDES - 1);
            } else {
                setCount(count - 1);
            }
        }
    };
    const nextScreen = () => {
        if (groups.length > 0) {
            if (count === SLIDES - 1) {
                setCount(0);
            } else {
                setCount(count + 1);
            }
        }
    };
    const SLIDES = groups.length;

    return (
        <>
            <div className=" tile-btn">
                <h5 className=''>{props.title}</h5>
                <div className="btn-swiper">
                    <button className='swip-btn' onClick={prevScreen}>
                    <img src={require('../../../assets/robotasset/rightarrow.png')} alt="" />
                    </button>
                    <button className='swip-btn' onClick={nextScreen}>
                    <img src={require('../../../assets/robotasset/leftarrow.png')} alt="" />
                    </button>
                </div>
            </div>
            <Row className="swipeables">
                <Col  className="">
                    <SwipeableViews
                        index={count}
                        enableMouseEvents
                        slideStyle={{ overflow: "hidden", alignContent: "center" }}
                        containerStyle={{ height: "auto", width: "auto" }}
                    >
                        {groups.map((groupItem, index) => (
                            <Row gutter={[16, 24]} key={index} className={"align-component"}>
                                {groupItem.map((item) => (
                                    <Col span={chunkSize >= 3 ? 8 : chunkSize === 2 ? 12 : 24} className="align-one-comp min_width" key={item?.id}>
                                        <ItemComp
                                          key={item?.id}
                                            history={props.history}
                                            item={item}
                                            onItemClick={onItemClick}
                                            parentUpdate={parentUpdate}
                                            {...props}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        ))}
                    </SwipeableViews>
                </Col>
            </Row>
        </>
    )
}


import { ReactComponent as WstoreIcon } from "../../assets/robotasset/store.svg";
import { ReactComponent as DollarIcon } from "../../assets/robotasset/dollar.svg";
import { ReactComponent as SuscribeIcon } from "../../assets/robotasset/subscribe.svg";
import { ReactComponent as HomeIcon } from "../../assets/robotasset/home.svg";
import React, { useState } from "react";


import { useHistory } from "react-router-dom";

function Sidebar() {
  const nav = useHistory();

  return (
    nav.location.pathname !== "/workspace" && (
      <div className="sidebar-container">
        <div className="s_main">
          <div className=" side_item" onClick={() => nav.push(`/`)}>
            <HomeIcon
              className={`${
                nav.location.pathname === "/workspace"
                  ? "storeIconActive nav-active"
                  : "storeIcon"
              }`}
            />
            <p
              className={`${
                nav.location.pathname === "/workspace" ? "nav-active-p" : ""
              }`}
            >
              Main Site
            </p>
          </div>
          <div className=" side_item" onClick={() => nav.push(`/`)}>
            <img
              src={require("../../assets/robotasset/faq.png")}
              alt=""
              className={`${
                nav.location.pathname === "/workspace"
                  ? "storeIconActive nav-active"
                  : "storeIcon"
              }`}
            />
            <a
              className={`${
                nav.location.pathname === "/workspace" ? "nav-active-p" : ""
              }`}
              href="https://robotmaker.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to Videos
            </a>
          </div>
          <div className=" side_item" onClick={() => nav.push(`/`)}>
            <img
              src={require("../../assets/robotasset/chat.png")}
              alt=""
              className={`${
                nav.location.pathname === "/workspace"
                  ? "storeIconActive nav-active"
                  : "storeIcon"
              }`}
            />
            <a
              className={`${
                nav.location.pathname === "/workspace" ? "nav-active-p" : ""
              }`}
              href="https://robotmaker.ai/faqs"
              target="_blank"
              rel="noopener noreferrer"
            >
              FAQs
            </a>
          </div>
          <div className=" side_item" onClick={() => nav.push(`/robot_store`)}>
            <WstoreIcon
              className={`${
                nav.location.pathname === "/robot_store"
                  ? "storeIconActive nav-active"
                  : "storeIcon"
              }`}
            />
            <p
              className={`${
                nav.location.pathname === "/robot_store" ? "nav-active-p" : ""
              }`}
            >
              Robot Marketplace
            </p>
          </div>
          <div className=" side_item" onClick={() => nav.push(`/robot_rent`)}>
            <DollarIcon
              className={`${
                nav.location.pathname === "/robot_rent"
                  ? "storeIconActive nav-active"
                  : "storeIcon"
              }`}
            />
            <p
              className={`${
                nav.location.pathname === "/robot_rent" ? "nav-active-p" : ""
              }`}
            >
              Rent Your Robots
            </p>
          </div>
          <div className=" side_item" onClick={() => nav.push(`/my_robot`)}>
            <SuscribeIcon
              className={`${
                nav.location.pathname === "/my_robot"
                  ? "storeIconActive nav-active"
                  : "storeIcon"
              }`}
            />
            <p
              className={`${
                nav.location.pathname === "/my_robot" ? "nav-active-p" : ""
              }`}
            >
              Subscribed Robots
            </p>
          </div>
          {/* <div className=" side_item" onClick={() => nav.push(`/trading_account`)}>
            <SuscribeIcon
              className={`${
                nav.location.pathname === "/trading_account"||nav.location.pathname === "/create_acc"
                  ? "storeIconActive nav-active"
                  : "storeIcon"
              }`}
            />
            <p
              className={`${
                nav.location.pathname === "/trading_account"||nav.location.pathname === "/create_acc" ? "nav-active-p" : ""
              }`}
            >
             Trading Accounts
            </p>
          </div> */}
          <div className=" side_item" onClick={() => nav.push(`/ai-forcast`)}>
            <SuscribeIcon
              className={`${
                nav.location.pathname === "/ai-forcast"
                  ? "storeIconActive nav-active"
                  : "storeIcon"
              }`}
            />
            <p
              className={`${
                nav.location.pathname === "/ai-forcast" ? "nav-active-p" : ""
              }`}
            >
              AI Forecast
            </p>
          </div>
        </div>
      </div>
    )
  );
}

export default Sidebar;

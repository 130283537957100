/**
 * @fileoverview robot rent window for robot subscription
 */

import React, { useState, useEffect } from "react";
import "./robotRent.css";
import {
  Button,
  Typography,
  Input,
  Checkbox,
  message,
  Select,
  Row,
  Col,
  Form,
  Divider,
  Alert,
} from "antd";
import {
  BACK_BUTTON,
  SAVE,
  EDIT_ROBOT,
  SUCCESS,
  ROBOT_NAME,
  ROBOT_DESCRIPTION,
  ROBOT_RENT,
  ROBOT_ENABLE_FOR_RENT,
  EDIT_NOTE,
  ERROR,
  DOLLAR,
  PER_MONTH,
  ROBOT_PRODUCT_PLAN,
  MEDIUM,
  EMAIL_REQUIRED,
  ROBOT_NAME_REQUIRED,
  EDIT_NOTES,
  LOADER,
} from "../../constants/messages";
import { getStoreRobot } from "../../actions/robotStoreActions";
import { postEditRentRobot } from "../../actions/robotRentActions";
import { connect } from "react-redux";
import UploadImage from "../commonComponent/carousel/UploadImage";
import { EMAIL_VALIDATION } from "../../constants/constants";
import Loader from "../Loader/Loader";
import { Grid } from "antd";
import { useHistory } from "react-router-dom";

function RobotEdit(props) {
  const [currentRobot, setCurrentRobot] = useState({});
  const [loading, setLoading] = useState(false);
  const [saveloading, setsaveLoading] = useState(false);
  const { Text, Title } = Typography;
  const { Option } = Select;
  const navigation=useHistory()

  const { useBreakpoint } = Grid;
  const { lg, xl } = useBreakpoint();
  const isBelow1200px = !xl;
  const isBelow992px = !lg;

  const onChangeProp = (propName) => (e) => {
    setCurrentRobot({ ...currentRobot, [propName]: e.target.value });
  };
  const onSelectProp = (propName) => (e) => {
    setCurrentRobot({ ...currentRobot, [propName]: e });
  };
  const onChangeCheckedProp = (propName) => (e) => {
    setCurrentRobot({
      ...currentRobot,
      [propName]: e.target.checked ? true : false,
    });
  };
  const onChangeImageField = (attr, e) => {
    setCurrentRobot({ ...currentRobot, [attr]: e });
  };

  const { TextArea } = Input;

  useEffect(() => {
    window.scrollTo(0, 0);
    const { accessToken, match } = props;
    props.getStoreRobot({
      accessToken: accessToken,
      id: match.params.id,
      callback: (res, data) => {
        if (res === SUCCESS) {
          setCurrentRobot({
            robot_id: data.id,
            robot_name: data.name,
            description: data.description,
            monthly_price: data.rent_amount ? data.rent_amount : 0,
            enable_for_rent: data.renting_enabled,
            image_1: data.image_1,
            image_2: data.image_2,
            image_3: data.image_3,
            image_4: data.image_4,
            ...data,
          });
          setLoading(true);
        }
      },
    });
  }, []);

  const saveEditRobot = () => {
    const { accessToken, match } = props;

    const formData = new FormData();
    for (let key in currentRobot) {
      if (
        key === "image_1" ||
        key === "image_2" ||
        key === "image_3" ||
        key === "image_4"
      ) {
        if (typeof currentRobot[key] === "string") {
          formData.append(key, currentRobot[key]);
        } else if (typeof currentRobot[key] === "object") {
          formData.append(key, currentRobot[key].file);
        } else {
          formData.append(key, "");
        }
      } else if (key === "enable_for_rent") {
        formData.append(key, currentRobot[key] ? "yes" : "no");
      } else {
        formData.append(key, currentRobot[key]);
      }
    }
    setsaveLoading(true)
    props.postEditRentRobot({
      id: match.params.id,
      data: formData,
      accessToken,
      callback: (status, info) => {
        setsaveLoading(false)
        if (status === ERROR) {
          if(info!=="Token is Expired"){
            message.error(info);
          }
          return;
        } 
        if(status===SUCCESS) {
          navigation.push('/robot_rent')
          message.success(`${info.robot_name} successfully saved`);
        }
      },
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="robot-header"></div>
      <div className="robotrent">
        {/* <Button
          type="primary"
          className="btns b-5"
          onClick={() => props.history.push(`/robot_rent`)}
        >
          {BACK_BUTTON}
        </Button> */}
        {(
          loading && (
            <div className="">
              <Form
                name="basic"
                initialValues={{
                  robot_name: currentRobot.robot_name,
                  paypal_payment_email_address:
                    currentRobot.paypal_payment_email_address,
                  remember: true,
                }}
                onFinish={saveEditRobot}
                onFinishFailed={onFinishFailed}
              >
                <div
                  className="editCardLayout"
                  style={{ flexDirection: isBelow992px ? "column" : "row" }}
                >
                  <div className='eform_left'>
                    <div className="slideImage">
                      <Title level={1} className="title">
                        Upload Your Robot Image
                      </Title>
                      <Divider style={{margin:' 10px 8px'}} />
                      <UploadImage
                        mainObject={currentRobot}
                        attr="image_1"
                        updateCB={onChangeImageField}
                        imageSize={MEDIUM}
                      />
                      {/* <UploadImage
                        mainObject={currentRobot}
                        attr="image_2"
                        updateCB={onChangeImageField}
                        imageSize={MEDIUM}
                      />
                      <UploadImage
                        mainObject={currentRobot}
                        attr="image_3"
                        updateCB={onChangeImageField}
                        imageSize={MEDIUM}
                      />
                      <UploadImage
                        mainObject={currentRobot}
                        attr="image_4"
                        updateCB={onChangeImageField}
                        imageSize={MEDIUM}
                      /> */}
                    </div>
                    <Alert style={{marginTop:'15px'}} message="A fee of 10% applies. Your account will be credited monthly net of this fee and excluding GST." banner />
                  </div>
                  <div className='eform_right'>
                    <Title style={{textAlign:'left',padding: '10px 0 6px 20px'}}  level={1} className="title">
                    Update your Robot Details
                      </Title>
                      <Divider style={{margin:' 10px 8px'}} />
                    <div className="formcolumn text-left">
                      <Row gutter={[16, 24]}>
                        <Col span={12}>
                          <Text className="regular-text label">
                            {ROBOT_NAME}
                          </Text>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="robot_name"
                            value={currentRobot.robot_name}
                            onChange={onChangeProp("robot_name")}
                            rules={[
                              {
                                required: true,
                                message: ROBOT_NAME_REQUIRED,
                              },
                            ]}
                          >
                            <Input
                              placeholder="TREND ROBOT"
                              className="widthFull text-dark b-5 inputBox-style"
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Text className="regular-text label">
                            {ROBOT_DESCRIPTION}
                          </Text>
                        </Col>
                        <Col span={12}>
                          <TextArea
                            value={currentRobot.description}
                            onChange={onChangeProp("description")}
                            placeholder="This robot does abc.
                                        Great robot, definitely
                                        worth renting."
                            // autoSize={{ minRows: 3 }}
                            style={{ height: 120, resize: 'none' }}
                            className="widthFull text-dark b-5 inputBox-style"
                          />
                        </Col>

                        <Col span={12}>
                          <Text className="regular-text label">
                            {ROBOT_RENT}
                          </Text>
                        </Col>
                        <Col span={12}>
                          <Select
                            value={
                              currentRobot?.monthly_price > 0
                                ? `${DOLLAR}${currentRobot?.monthly_price}${PER_MONTH}`
                                : "Free"
                            }
                            onChange={onSelectProp("monthly_price")}
                            className="widthFull text-dark b-5 inputBox-style"
                          >
                            <Option value={0}>Free</Option>
                            {Object.keys(ROBOT_PRODUCT_PLAN).map((e, index) => (
                              <Option value={e} key={index}>
                                {DOLLAR}
                                {e}
                                {PER_MONTH}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                        <Col span={12}>
                          <Text className="regular-text label">
                            {"Paypal Email Address"}
                          </Text>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={"paypal_payment_email_address"}
                            value={currentRobot.paypal_payment_email_address}
                            onChange={onChangeProp(
                              "paypal_payment_email_address"
                            )}
                            rules={[
                              { required: true, message: EMAIL_REQUIRED },
                              {
                                pattern: EMAIL_VALIDATION,
                                message: EMAIL_REQUIRED,
                              },
                            ]}
                          >
                            <Input
                              placeholder="Email address"
                              className="widthFull text-dark b-5 inputBox-style"
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}></Col>
                        <Col span={12}>
                          <Checkbox
                            onChange={onChangeCheckedProp("enable_for_rent")}
                            checked={currentRobot.enable_for_rent}
                          >
                            <Text className="regular-text label">
                              {ROBOT_ENABLE_FOR_RENT}
                            </Text>
                          </Checkbox>
                        </Col>
                      </Row>
                    </div>
                    <div className="alignbutton">
                      <Form.Item>
                      {
                        saveloading?
                        <Button
                          type="primary"
                          className="button primary width-full padding-20 btns b-5">
                          {LOADER}
                        </Button>
                       : <Button
                          type="primary"
                          className="button primary width-full padding-20 btns b-5"
                          htmlType="submit"
                          // onClick={saveEditRobot}
                        >
                          {SAVE}
                        </Button>
                      }
                      </Form.Item>
                    </div>
                    {/* </Form> */}
                  </div>
                </div>
              </Form>
              {/* <div style={{ paddingTop: 32, paddingBottom: 16 }}>
                <Text>
                  <span style={{ fontWeight: "900" }}>{EDIT_NOTES}</span>
                  {EDIT_NOTE}
                </Text>
              </div> */}
            </div>
          )
        )}
      </div>
    </>
  );
}

const mapDispatchToProps = {
  getStoreRobot: getStoreRobot,
  postEditRentRobot: postEditRentRobot,
};
const mapStateToProps = ({
  robotStoreReducer: { allRobotData },
  userReducer: { accessToken, userData },
}) => ({
  allRobotData,
  accessToken,
  userData,
});

export default connect(mapStateToProps, mapDispatchToProps)(RobotEdit);

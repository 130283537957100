import React, { useEffect, useState } from 'react';
import './subscription.css';
import { ImageBase } from "../../../constants/APIConstant";
import { UploadOutlined } from '@ant-design/icons';
import no_Image from '../../../assets/robotasset/robotupload.png';
import { message } from 'antd';

export default function UploadImage({ mainObject = {}, attr, updateCB, imageSize }) {

    const [image_1, setImage_1] = useState(mainObject[attr]);
    useEffect(() => {
        setImage_1(mainObject[attr]);
        // eslint-disable-next-line
    }, [mainObject[attr]])

    const style = {
        width: '45%',
        backgroundPosition: 'center'
    };
    if (typeof (image_1) === "string") {
        if (image_1 !== null && image_1.length > 0) {
            if (image_1 !== undefined) {
                style.backgroundImage = `url(${ImageBase.dev + image_1})`
            }
        }
        else if (image_1 === null || image_1 === undefined || image_1.length === 0) {
                style.backgroundImage = `url(${no_Image})`;
            }
    }
    else if(typeof (image_1) === 'object')
    {
        style.backgroundImage = `url(${image_1.thumb})`
    }
    const encodeImageFileAsURL = (element) => {
        var file = element.target.files[0];
        if(file){

            if (file.size > 2 * 1024 * 1024) {
                message.warning('File size exceeds 2MB. Please choose a smaller file.');
                return; // Stop further processing
            }

            var reader = new FileReader();
            reader.onloadend = function () {
                setImage_1({ 'file': file, 'thumb': reader.result});
                updateCB(attr,{ 'file': file, 'thumb': reader.result});
            }
            reader.readAsDataURL(file);
        }
        
    }
    return (
        
        <div style={style} className="image-set-back div-margin positionImageBelow b-shadow">
                <label className="positionImageAbove">
                    <input
                        type="file"
                        onChange={encodeImageFileAsURL}
                        accept={"image"}
                        name="file"
                        style={{display:"none"}}
                    />
                    <span>
                        <UploadOutlined style={{ fontSize: 'x-large' }} />
                    </span>
                </label>
            </div>
    )
}


import { actionRobotRentTypes } from "../constants/actionTypes";

const initialData = {
  fetchingAllRobot: false,
  allRentRobotData: {},
  allRentRobotList: []
};

const robotRentReducer = (state = initialData, action) => {
  switch (action.type) {
    case actionRobotRentTypes.SET_ALL_ROBOT_RENT_FETCHING:
      return {
        ...state,
        fetchingAllRobot: true,
      };
    case actionRobotRentTypes.ROBOT_RENT_RECIEVED:
      return {
        ...state,
        allRentRobotData: action.payload,
        fetchingAllRobot: false,
      };
    case actionRobotRentTypes.ROBOT_RENT_ALL_RECIEVED:
      return {
        ...state,
        allRentRobotList: action.payload,
        fetchingAllRobot: false,
      };
    default:
      return state;
  }
};
export default robotRentReducer;
/**
 * @fileoverview robot store window for robot subscription
 */

import React, { useState, useEffect } from "react";
import "./robotStore.css";
import { Typography, Button, Divider, Rate, message } from "antd";
import ReviewCard from "../commonComponent/carousel/reviewCard";
import CardContainer from "../commonComponent/carousel/CardContainer";
import Carousel from "../commonComponent/carousel/carousel";
import MultiImages from "../commonComponent/carousel/multiImage";
import {
  BACK_BUTTON,
  ROBOT_NAME_SUBSCRIPTION,
  ROBOT_FEATURES,
  PROCEED_PAY,
  BIG,
  SUCCESS,
  REVIEW,
  PER_MONTH,
  DOLLAR,
  SMALL,
  NOT_AVAILABLE,
  ERROR,
} from "../../constants/messages";
import {
  getStoreRobot,
  getAllRobotStoreList,
  postCopyRobot,
} from "../../actions/robotStoreActions";
import FeatureCard from "../commonComponent/carousel/featuredCard";
import { connect } from "react-redux";
import CarouselTitle from "../commonComponent/carousel/CarouselTitle";
import no_Image from "../../assets/default.png";
import { ImageBase } from "../../constants/APIConstant";

function RobotSubscription(props) {
  const { Title, Paragraph, Text } = Typography;
  const [featureRobot, setFeatureRobot] = useState([]);
  const [currentRobot, setCurrentRobot] = useState({});
  const [loading, setLoading] = useState(true);
  const [copyloading, setcopyLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const { accessToken, match } = props;
    props.getStoreRobot({
      accessToken: accessToken,
      id: match.params.id,
      callback: (res, data) => {
        if (res === SUCCESS) setCurrentRobot(data);
     
      },
    });
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
    const { accessToken } = props;
    setLoading(true)
    props.getAllRobotStoreList({
      accessToken: accessToken,
      callback: (resp, data) => {
        if (resp === "success") {
          setFeatureRobot(data?.featured_robot);
        }
        setLoading(false)
      },
    });
  }, []);

  const { id, rating, name, description, rent_amount, avg_rating, images } =
    currentRobot;

  const handleCopyRobot = () => {
    const { accessToken, allRobotList } = props;

    let data = {
      name: name,
      source_user_id: currentRobot?.user,
    };
    setcopyLoading(true);
    props.postCopyRobot({
      id: id,
      accessToken,
      callback: (status, info) => {
        setcopyLoading(false);
        if (status === SUCCESS) {
          message.success(info);
          props.history.push("/workspace");
        } else if (status === ERROR) {
          // message.error(info);
        }
      },
      data: data,
    });
  };


  return (
    <>
    
      <div className="fill-container-height">
        <div className="robot_subcription">

          <div className="g-left">
            <div className="r-detail">
              <img
                src={`${currentRobot.image_1?ImageBase.dev + currentRobot.image_1 : no_Image}`}
                alt=""
              />
              <div className="description">
                <div className="title">{name}</div>
                <div className="title-d">{description ==='null'?"":description}
                </div>
                <div className="rating-robot">
                  <Rate
                    disabled
                    // defaultValue={avg_rating}
                    value={avg_rating}
                    style={{ color: "black", marginRight: "16px" }}
                  />

                  <Text className="reviews">
                    {" "}
                    {rating ? rating?.length : 0}
                  </Text>
                  <Text className="reviews"> {REVIEW}</Text>
                </div>
                <div className="d-bottom">
                  <div className="bottomcontain">
                    <div className="">
                      {rent_amount ? (
                        <Text className="font price_card">
                          <sup>{DOLLAR} </sup>
                          {rent_amount}
                          <span>{PER_MONTH}</span>
                        </Text>
                      ) : (
                        <Title level={4}>{"Free"}</Title>
                      )}
                    </div>
                    {rent_amount ? (
                      <Button
                        type="primary"
                        className="button primary width-full btns b-5"
                        onClick={() =>
                          props.history.push(
                            `/payment-robot/${currentRobot.id}`
                          )
                        }
                      >
                        {PROCEED_PAY}
                      </Button>
                    ) : copyloading ? (
                      <Button
                        type="primary"
                        className="button primary width-full btns b-5"
                      >
                        Loading...
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        className="button primary width-full btns b-5"
                        onClick={handleCopyRobot}
                      >
                        {"Copy Robot"}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {featureRobot?.length > 0 && (
              <>
                <div className="sub-swipper">

                  <CarouselTitle
                    history={props.history}
                    list={featureRobot}
                    ItemComp={CardContainer}
                    buttonStatus={true}
                    imageSize={BIG}
                    chunkSize={3}
                    ItemCard={FeatureCard}
                    title={ROBOT_FEATURES}
                  />
                </div>
              </>
            )}
          </div>
          <div className="g-right">
            <div className="r_title">Reviews</div>
            <hr />
            <div className="flexs">
                {rating?.length > 0 ? (
                  <>
                    {" "}
                    {rating?.map((item, index) => (
                      <div key={index}>
                        <ReviewCard item={item} />
                      </div>
                    ))}
                  </>
                ) : (
                  <Text>{NOT_AVAILABLE}</Text>
                )}
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  getStoreRobot: getStoreRobot,
  getAllRobotStoreList: getAllRobotStoreList,
  postCopyRobot: postCopyRobot,
};
const mapStateToProps = ({
  robotStoreReducer: { allRobotData, allRobotList },
  userReducer: { accessToken, payment },
}) => ({
  allRobotData,
  allRobotList,
  accessToken,
  payment,
});

export default connect(mapStateToProps, mapDispatchToProps)(RobotSubscription);

import PropTypes from "prop-types";
import React from 'react';
import { Steps } from "antd";

const { Step } = Steps;

export default function FormSteps(props) {
  const { stepList, activeKey } = props;
  return (
    <div className="multistep-style">
      <Steps
        current={activeKey}
        labelPlacement="vertical"
        className="step-style"
      >
        {stepList.map((steps, index) => (
          <Step style={{ color: "red" }} key={index} title={steps} />
        ))}
      </Steps>
    </div>
  );
}

FormSteps.propTypes = {
  activeKey: PropTypes.number.isRequired,
  stepList: PropTypes.array.isRequired,
};

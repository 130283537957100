/**
 * @fileoverview Week Drawer component
 */
import { Select } from 'antd';
import React from 'react';
import { ACTION_LIST } from '../../../constants/constants';
import { COMMON_TEXT } from '../../../constants/constants.string';
import { isEmpty } from '../../../utils/utils';
import UpdateButton from './updateButton';

const { Option } = Select;

function WeekDrawer(props) {

    const { currentBlockCode, tempBlockCode, onCodeChange, setDrawerState, onUpdate } = props;

    /**
    * @description on Update button clicked
    */
    const handleOnUpdate = () => {
        onUpdate();
        setDrawerState(false)
    }

    return (
        <div className="drawer-body">
            <div className="property-header">{COMMON_TEXT.PROPERTIES}</div>
            <div className="property-body">
                {!isEmpty(currentBlockCode) && currentBlockCode[0]
                    && currentBlockCode[0]['action'] && Object.keys(currentBlockCode).map(key => {
                        return (
                            <div key={key} className="option-style">
                                <div>{currentBlockCode[key]['name']}</div>
                                <Select
                                    placeholder='Select'
                                    defaultValue={'Allow'}
                                    onChange={onCodeChange}
                                    className="theme-text height-50"
                                    showArrow={true}
                                    value={((currentBlockCode[key] && currentBlockCode[key].action)
                                        || (tempBlockCode[key] && tempBlockCode[key].action))} >
                                    {JSON.parse(JSON.stringify(ACTION_LIST)).map(option => (
                                        <Option key={option.id} value={`${currentBlockCode[key].name}-${key}-${option.name}`}>
                                            {option.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        )
                    })}
            </div>
            <UpdateButton onUpdate={handleOnUpdate} />
        </div>
    );
}

export default WeekDrawer;

import "./payment.css";
import { useEffect } from "react";
import {
  PAYMENT_FAIL,
  CANCEL,
  PAYMENT_SUCCESS,
  PER_MONTH,
  SUCCESS,
  DOLLAR,
  SMALL,
  ROBOT_NAME_SUBSCRIPTION,
  PAYMENT_METHOD,
  SUMMARY,
  TAX_CHARGE,
  PAYMENT_PAYPAL,
  PAYMENT_STRIPE,
  ROBOT_PRODUCT_PLAN,
  ERROR,
} from "../../constants/messages";
import React, { useState } from "react";
import { message, Divider, Typography, Card, Input, Button, Spin } from "antd";
import {
  postStripeRobotPayment,
  postPayPalRobotPayment,
} from "../../actions/paymentActions";
import { ImageBase } from "../../constants/APIConstant";
import PayPalBtn from "./paypalBtn";
import PropTypes from "prop-types";
import StripeCheckout from "react-stripe-checkout";
import { connect } from "react-redux";
import { getStoreRobot } from "../../actions/robotStoreActions";
import no_Image from "../../assets/no_Image.png";

function PaymentRobotSubscription(props) {
  const [hasErrored, setHasErrored] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [spinning, setSpinning] = useState(true);
  const { Title, Text } = Typography;
  const { TextArea } = Input;
  const [currentRobot, setCurrentRobot] = useState({});
  const [taxes, setTaxes] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const { id, name, description, rent_amount, images } = currentRobot;
  const plan = ROBOT_PRODUCT_PLAN[rent_amount];

  const paypalOnApprove = (data, detail) => {
    const { accessToken } = props;
    const requestData = {
      receipt_id: data.orderID,
      payment_status: "COMPLETED",
      price: rent_amount,
      message: SUCCESS,
      subscription_id: data.subscriptionID,
      robot_plan: `copy_robot_price_${rent_amount}`,
      robot_id: id,
    };
    props.postPayPalRobotPayment({
      data: { requestData },
      accessToken: accessToken,
      callback: callback,
    });
  };

  const paypalSubscribe = (data, actions) => {
    return actions.subscription.create({
      plan_id: plan ? plan.payPal_plan : "",
    });
  };

  const paypalOnError = (err) => {
    setHasErrored(true);
    setErrorMessage(PAYMENT_FAIL);
  };

  const handlePaymentCancel = () => {
    props.history.push(`/robot_store`);
  };

  const handleToken = (token) => {
    const { accessToken } = props;
    const product = {
      name: name,
      price: rent_amount,
    };
    const data = {
      token: token,
      product: product,
      robot_plan: plan ? plan.stripe_plan : "",
      robot_id: currentRobot?.id,
    };

    props.postStripeRobotPayment({
      data: { data },
      accessToken: accessToken,
      callback: callback,
    });
  };

  const callback = (res, data) => {
    if (res === SUCCESS) {
      message.success(PAYMENT_SUCCESS);
      props.history.push("/workspace");
    }
    if (res === ERROR) {
      if (data !== "Token is Expired") {
        message.error(data);
        setHasErrored(true);
        setErrorMessage(data);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const { accessToken, match } = props;
    props.getStoreRobot({
      accessToken: accessToken,
      id: match.params.id,
      callback: (res, data) => {
        if (res === SUCCESS) {
          setCurrentRobot(data);
          setLoading(true);
        }
      },
    });
  }, []);
  useEffect(() => {
    const calvalue = rent_amount * TAX_CHARGE;
    setTaxes(calvalue);
    setSubTotal(rent_amount - calvalue);
  }, [currentRobot?.id]);
  const style = {
    width: "150px",
    height: "150px",
    flex: 1,
  };
  if (images && images.length > 0) {
    style.backgroundImage = `url(${ImageBase.dev + images[0]})`;
  } else {
    style.backgroundImage = `url(${no_Image})`;
  } 

  
  return (
    <>
      <div className="pay_robot">
        <div className="left_card">
          <h3>Select Payment Method</h3>
          <hr />

          <div className="robot_payment">
            <div className="card-border">
              <StripeCheckout
                label="Subscribe with Stripe"
                stripeKey={process.env.REACT_APP_STRIPE_KEY}
                token={handleToken}
                amount={rent_amount * 100}
                name={name}
                billingAddress
                shippingAddress
                ComponentClass="payment-header-desc"
                
              />
            </div>
            <img src={require('../../assets/robotasset/or.png')} alt="" />
            <div className="card-border" style={{width:'250px'}}>
              {spinning && (
                <Spin
                  tip="Loading Paypal Option..."
                  size="small"
                  className="spinner"
                />
              )}
              <div>
                <PayPalBtn
                  amount={rent_amount}
                  currency={"USD"}
                  createSubscription={paypalSubscribe}
                  onApprove={paypalOnApprove}
                  catchError={paypalOnError}
                  onError={paypalOnError}
                  onCancel={paypalOnError}
                  stopSpin={() => setSpinning(!spinning)}
                />
              </div>
              
            </div>
          </div>
        {hasErrored && (
              <div className="error-message padding-20" style={{textAlign:'center'}}>{errorMessage}</div>
            )}
        </div>
        <div className="right_card">
          <h3>Payment Summary</h3>
          <hr />
          <div className=""></div>
          <div
            className="flex-row "
            style={{
              justifyContent: "flex-start",
              gap: "15px",
              marginTop: "15px",
            }}
          >
            <div style={style} className="image-set-back"></div>
            <div style={{ margin: "1px 0px", flex: 3 }}>
              <Text
                style={{ fontSize: "18px", fontWeight: "600", marginBottom: 4 }}
              >
                {name}
              </Text>
              <div className=" pay_des">
                {description === "null" ? "" : description}
              </div>
              <div className="margin_TB"></div>
            </div>
          </div>
          <div
            className="flex-column"
            style={{
              justifyContent: "space-between",
              minHeight: 70,
              padding: "5px 0px",
              marginTop: "25px",
            }}
          >
            <div className="flex-row align_base calcul">
              <h3>PRICE</h3>
              <h6>
                <sup>{DOLLAR} </sup>
                {rent_amount || 0}
                <span>{PER_MONTH}</span>
              </h6>
            </div>
            <div className="flex-row align_base calcul">
              <h3>SUBTOTAL</h3>
              <h6>
                <sup>{DOLLAR} </sup>
                {subTotal || 0}
              </h6>
            </div>
            <div className="flex-row align_base calcul">
              <h3>TAXES</h3>
              <h6>
                <sup>{DOLLAR} </sup>
                {taxes || 0}
              </h6>
            </div>
            <div className="flex-row align_base calcul" style={{border:'unset'}}>
              <h3>TOTAL</h3>
              <h6>
                <sup>{DOLLAR} </sup>
                {rent_amount}
                <span>{PER_MONTH}</span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
}

PaymentRobotSubscription.propTypes = {
  postStripeRobotPayment: PropTypes.func,
  postPayPalRobotPayment: PropTypes.func,
};

const mapDispatchToProps = {
  getStoreRobot: getStoreRobot,
  postStripeRobotPayment: postStripeRobotPayment,
  postPayPalRobotPayment: postPayPalRobotPayment,
};

const mapStateToProps = ({
  userReducer: { userData, accessToken, refreshToken },
  paymentReducer: { plans, fetchingPayments },
}) => ({
  plans,
  fetchingPayments,
  accessToken,
  refreshToken,
  userData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentRobotSubscription);

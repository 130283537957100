import { Button } from 'antd'
import React from 'react'
import { COMMON_TEXT } from '../../../constants/constants.string'

export default function UpdateButton(props) {
    return (
        <div className="drawer-bottom">
            <Button type="primary"
                className="button primary-button height-50 full-width"
                onClick={props.onUpdate}>{COMMON_TEXT.UPDATE}</Button>
        </div>
    )
}

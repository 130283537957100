import { all } from "redux-saga/effects";
import { paymentActionWatcher } from './paymentSaga';
import { questionActionWatcher } from "./questionSaga";
import { userActionWatcher } from "./userSaga";
import { workspaceActionWatcher } from './workspaceSaga';
import { robotStoreActionWatcher } from './robatStoreSaga';
import { robotRentActionWatcher } from './robotRentSaga';
import {mySubRobotActionWatcher} from './mySubRobotSaga';
/**
 * saga to yield all others
 */
export default function* rootSaga() {
  yield all([
    userActionWatcher(),
    questionActionWatcher(),
    workspaceActionWatcher(),
    paymentActionWatcher(),
    robotStoreActionWatcher(),
    robotRentActionWatcher(),
    mySubRobotActionWatcher(),
  ]);
}

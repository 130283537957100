import { actionRobotRentTypes } from "../constants/actionTypes";

export const getAllRobotRentList = ({accessToken,id,callback}) => {
  return {
    type: actionRobotRentTypes.GET_ALL_ROBOT_RENT,
    id: id,
    accessToken: accessToken,
    callback:callback
  };
};
export const postEditRentRobot = ({accessToken, data, id, callback}) => {
  return {
    type: actionRobotRentTypes.POST_ROBOT_RENT,
    data: data,
    id: id,
    accessToken: accessToken,
    callback: callback
  }
}
import React from "react";
import "./trade.css";
import { Divider } from "antd";
import { useHistory } from "react-router-dom";

const TradingAcc = () => {
    const nav=useHistory()
    const handelnavigate=()=>{
        nav.push('/create_acc')
    }
  return (
    <div className="trade_main">
      <div className="top_trade">
        <h3>Trading Account</h3>
        <button onClick={handelnavigate}>
          <img
            style={{ height: "40px" }}
            src={require("../../assets/robotasset/prebtn.png")}
            alt=""
          />
          <p >Add trading account</p>
        </button>
      </div>
      <Divider />
      <div className="bottom_trade">
        <div className="trade_card">
          <div className="c_top">
            <div className="c_head">
              <span>Trading account</span>
              <p>2100293531</p>
            </div>
            <div className="c_text">
              <p>DEMO</p>
              <img
                src={require("../../assets/robotasset/outedit.png")}
                alt=""
              />
            </div>
          </div>
          <div className="c_detail">
              <span>Friendly Name</span>
              <p>super_robot</p>
          </div>
          <div className="c_detail">
              <span>Login</span>
              <p>2100293531</p>
          </div>
          <div className="c_detail">
              <span>Server</span>
              <p>robot_Test</p>
          </div>
          <div className="c_detail">
              <span>Deployed</span>
              <p>active</p>
              
          </div>
          <div className="c_detail">
              <span>Password</span>
              <p><img src={require('../../assets/robotasset/Show.png')} alt="" /></p>
          </div>
          <div className="btns_trade">
            <div className="del_trade">
                <img src={require('../../assets/robotasset/btndel.png')} alt="" />
                <p>Delete</p>
            </div>
            <div className="go_trade">
                <p>go to account</p>
                <img src={require('../../assets/robotasset/btnarrow.png')} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingAcc;

import { actionQuestionTypes } from "../constants/actionTypes";

const initialData = {
  questions: {},
  fetchingQuestions: false,
  fetchingResponses: false,
  responses: {},
};

const questionReducer = (state = initialData, action) => {
  switch (action.type) {
    case actionQuestionTypes.QUESTIONS:
      return {
        ...state,
        fetchingQuestions: true,
      };
    case actionQuestionTypes.FETCHED_QUESTIONS:
      return {
        ...state,
        questions: action.payload.data,
        fetchingQuestions: false,
      };
    case actionQuestionTypes.QUESTIONS_ERROR:
      return {
        ...state,
        fetchingQuestions: false,
        fetchingResponses: false
      };
    default:
      return state;
  }
};
export default questionReducer;

import { Button, Divider, Form, Input, Radio } from "antd";
import "./trade.css";
import React from "react";
import {
  ACC_REQUIRED,
  NAME_REQUIRED,
  PASSWORD_REQUIRED,
  SERVER_REQUIRED,
} from "../../constants/messages";

const CreateAcc = () => {
  const onFinish = (values) => {
    const data = { ...values };
    console.log({ ...data });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = () => {
    // if (hasErrored) {
    //   setHasErrored(false);
    // }
  };
  return (
    <div className="create_trade_main">
      <div className="top_trade">
        <h3>Add New Trading Account Details</h3>
      </div>
      <Divider />
      <div className="create_form">
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className=" form_create">
            <div className="f_label">MT5 Account Number</div>
            <Form.Item
              className="full-width"
              name="acc_number"
              rules={[
                {
                  required: true,
                  message: ACC_REQUIRED,
                },
              ]}
            >
              <Input
                onChange={handleChange}
                className="full-width inputBox-style height-50 b-5"
                placeholder="Enter accoount number"
              />
            </Form.Item>
          </div>
          <div className=" form_create">
            <div className="f_label">MT5 Account password</div>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: PASSWORD_REQUIRED,
                },
              ]}
            >
              <Input.Password
                onChange={handleChange}
                className="b-5 inputBox-style height-50"
                placeholder="Enter your password"
              />
            </Form.Item>
          </div>
          <div className=" form_create">
            <div className="f_label">Broker server</div>
            <Form.Item
              className="full-width"
              name="server"
              rules={[
                {
                  required: true,
                  message: SERVER_REQUIRED,
                },
              ]}
            >
              <Input
                onChange={handleChange}
                className="full-width inputBox-style height-50 b-5"
                placeholder="Enter server name"
              />
            </Form.Item>
          </div>
          <div className=" form_create">
            <div className="f_label">Friendly name</div>
            <Form.Item
              className="full-width"
              name="name"
              rules={[
                {
                  required: true,
                  message: NAME_REQUIRED,
                },
              ]}
            >
              <Input
                onChange={handleChange}
                className="full-width inputBox-style height-50 b-5"
                placeholder="Enter your name"
              />
            </Form.Item>
          </div>
          <div className="form_create">
            <div className="f_label">Account Type</div>
            <Form.Item
              name="account_type"
              rules={[
                {
                  required: true,
                  message: "Please select an account type!",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="Live">Live</Radio>
                <Radio value="Demo">Demo</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className=" form_create">
            <div className="f_label"></div>
            <Form.Item>
              <Button
                type="primary"
                className="height-50  full-width form_btn"
                htmlType="submit"
                style={{ marginTop: "15px" }}
              >
                SIGN IN
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateAcc;

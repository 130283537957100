import {APIService, requestURLS} from "../constants/APIConstant";
import {TranslateSubRobot} from '../components/commonComponent/translate';

export async function getSubscribedRobotRatings(token, robot_id) {
    const getUrl = APIService.dev + requestURLS.GET_ROBOT_RATING + '?id=' + robot_id;
    let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
    const response = await fetch(getUrl, {
        headers: headers,
        method: "GET",
    })
    const responseJson = await response.json()
    return responseJson;
}


export async function getMySubRobotListWithRatings(token) {
    const getUrl = APIService.dev + requestURLS.GET_ALL_MY_SUB_ROBOT;
    try {
        let headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        const response = await fetch(getUrl, {
            headers: headers,
            method: "GET",
        })

        if (!response.ok) {
            throw response;
        }
        const rj = await response.json()
        if (rj.message === "success") {
            // const subRobots = {}
            // for (let i = 0; i < rj.data.length; i++) {
            //     subRobots[rj.data[i].copy_robot_id] = rj.data[i]
            //     const ratingData = await getSubscribedRobotRatings(token, rj.data[i].copy_robot_id)
            //     subRobots[rj.data[i].copy_robot_id].ratingData = ratingData.hasOwnProperty('data') ? ratingData.data[0] : {}
            // }
           
            return {ok: true, data: rj.data}
        }
        
    } catch (error) {
        return {ok: false, data: error}
    }
    
}
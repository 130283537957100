/**
 * @param {object} obj object
 * @description return true if object is empty
 */
export const isEmpty = (obj) => {
    if (typeof obj === 'undefined')
        return true

    if (typeof obj === 'object')
        return !Boolean(Object.keys(obj).length)
}

/**
 * @param {number} num number to convert to words
 * @description converts number to corresponding word string
 * @example
 * const num = 51
 * const result = getStringForNumber(num) // Fifty First
 * 
 * const num = 213
 * const result = getStringForNumber(num) // Two Hundred Thirteenth
 */
export const getStringForNumber = (num) => {
    const mapToString = {
        One: 'First', Two: 'Second', Three: 'Third', Four: 'Fourth', Five: 'Fifth', Six: 'Sixth',
        Seven: 'Seventh', Eight: 'Eighth', Nine: 'Ninth', ten: 'Tenth', Eleventh: 'Eleventh',
        Twelve: 'Twelveth', Thirteen: 'Thirteenth', Fourteen: 'Fourteenth', Fiveteen: 'Fiveteenth',
        Sixteen: 'Sixteenth', Seventeen: 'Seventeenth', Nineteen: 'NineTeenth', Twenty: 'Twentieth',
        Thirty: 'Thirtieth', Forty: 'Fortieth', Fifty: 'Fiftieth', Sixty: 'Sixtieth',
        Seventy: 'Seventieth', Eighty: 'Eightieth', Ninety: 'Ninetieth', Hundred: 'Hundredth', Thousand: 'Thousandth'
    }

    let word = numInWords(num).trim().split(" ")
    word[word.length - 1] = mapToString[word[word.length - 1]]
    return word.join(" ")
}

var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

/**
 * @param {number} num number to convert to words
 * @description returns words for the given number
 * @example
 * const num = 51
 * const result = numInWords(num) // Fifty One
 * 
 * const num = 213
 * const result = numInWords(num) // Two Hundred Thirteen
 */
const numInWords = (num) => {
    if ((num = num.toString()).length > 9) return 'overflow';
    const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; let str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';
    return str;
}
import { actionWorkspaceTypes } from "../constants/actionTypes";

export const GlobalStates = ({ symbol, date, time, timeframe}) => {
  return {
    type: actionWorkspaceTypes.SET_FETCHINGS,
    symbol:symbol,
    timeframe: timeframe,
    date: date,
    time: time,
  };
};
export const getWorkspace = ({id, accessToken, callback}) => {
  return {
    type: actionWorkspaceTypes.GET_WORKSPACE,
    id: id,
    accessToken: accessToken,
    callback: callback
  };
};
export const emptyWorkspace = () => {
  return {
    type: actionWorkspaceTypes.WORKSPACE_RESET,
  };
};


export const getAllWorkspaceList = ({accessToken}) => {
  return {
    type: actionWorkspaceTypes.GET_ALL_WORKSPACE,
    accessToken: accessToken,
  };
};

export const saveFile = ({accessToken, type, data, name,callback}) => {
  return {
    type: actionWorkspaceTypes.SAVE_FILE,
    accessToken: accessToken,
    typeExt: type,
    data: data,
    name: name,
    callback: callback
  };
};

export const postWorkspace = ({accessToken, data, id, callback}) => {
  return {
    type: actionWorkspaceTypes.POST_WORKSPACE,
    data: data,
    id: id,
    accessToken: accessToken,
    callback: callback
  }
}
export const AIforcast = ({accessToken, data, callback}) => {
  return {
    type: actionWorkspaceTypes.AI_WORKSPACE,
    data: data,
    accessToken: accessToken,
    callback: callback
  }
}

  export const deleteWorkspace = ({accessToken, callback ,id}) => {
    return {
      type: actionWorkspaceTypes.DELETE_WORKSPACE,
      id: id,
      callback: callback,
      accessToken: accessToken,
    }
  }

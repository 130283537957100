const IMAGEPROPS = new Set(["image_1", "image_2", "image_3", "image_4"])

export const translateItem = (item) => {
    const newItem = { images: [] }
    for (let key in item) {
        if (IMAGEPROPS.has(key)) {
            if (item[key]) {
                newItem.images.push(item[key])
            }
        }
        newItem[key] = item[key]
    }
    return newItem
}

const TranslateResponse = (res) => {
    let allRobot = [];
    let featured = [];
    let free = [];
    if (res.all_robot || res.featured_robot || res.free_robot) {
        if (res.hasOwnProperty('all_robot') && res.all_robot.length > 0) {
            allRobot = res.all_robot.map(item => translateItem(item))
        }
        if (res.hasOwnProperty('featured_robot') && res.featured_robot.length > 0) {
            featured = res.featured_robot.map(item => translateItem(item))
        }
        if (res.hasOwnProperty('free_robot') && res.featured_robot.length > 0) {
            free = res.free_robot.map(item => translateItem(item))
        }
        return { all_robot: allRobot, featured_robot: featured, free_robot: free }
    }
}
export const TranslateOtherRobot = (res) => {
    let allRobot = [];
    if (res.length > 0) {
        allRobot = res.map(item => translateItem(item))
        return { all_robot: allRobot }
    }
}
export const TranslateSubRobot = (res) => {
    let allRobot = {};
    if (res.length > 0) {
        for (let item = 0; item < res.length; item++) {
            if (res[item].copy_robot_id) {
                allRobot[res[item].copy_robot_id] = translateItem(res[item])
            }
        }
        return allRobot;
    }
}

export default TranslateResponse;
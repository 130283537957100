import { logoDev,sidelogo,sidelogov } from '../assets'

export const ConfigeUI = () => {

    const config = [
        {
            domain: 'robot',
            pagebg: '#E6E8EC',
            primaryColor: '#001338',
            secondaryColor: '#003A96',
            ternaryColor: '#18EA6C',

            logo: logoDev,
            sidelogo: sidelogo,
            sidelogov: sidelogov,
            textColor: '#404E6A',

            fontFamily: 'ProximaNovaRegular',
            fontFamilyhead: 'Novecentosanswide',
            fontWeight: '300',
            titlefontWeight: '500',
            bodyfontWeight:'400',
            fontsize:'23',
            titlefontsize:'23',
            bodyfontsize:'18px',
            bordercolor:'#E0E5F2'

        },
        
    ]

    const result = config?.find(e => e.domain === process.env.REACT_APP_DOMAIN_NAME)
    
    document.documentElement.style.setProperty('--page-bg', result?.pagebg);
    document.documentElement.style.setProperty('--primary-color', result?.primaryColor);
    document.documentElement.style.setProperty('--secondary-color', result?.secondaryColor);
    document.documentElement.style.setProperty('--ternary-color', result?.ternaryColor);
    document.documentElement.style.setProperty('--textColor', result?.textColor);
    document.documentElement.style.setProperty('--bordercolor', result?.bordercolor);

    document.documentElement.style.setProperty('--font-family-', result?.fontFamily);
    document.documentElement.style.setProperty('--font-family-head-', result?.fontFamilyhead);
    document.documentElement.style.setProperty('--fontWeight-', result?.fontWeight);
    document.documentElement.style.setProperty('--fontWeight-title-', result?.titlefontWeight);
    document.documentElement.style.setProperty('--fontWeight-body-', result?.bodyfontWeight);
    document.documentElement.style.setProperty('--fontsize-', result?.fontsize);
    document.documentElement.style.setProperty('--fontsize-title-', result?.titlefontsize);
    document.documentElement.style.setProperty('--fontsize-body-', result?.bodyfontsize);




    return result
}


import { actionLoginTypes, actionPaymentTypes } from "../constants/actionTypes";

const initialData = {
  fetchingUser: false,
  apiloader: false,
  buttonloader: false,
  accessToken: "",
  refreshToken: "",
  userData: {},
  userSettings: '',
};

const userReducer = (state = initialData, action) => {

  switch (action.type) {
    case actionLoginTypes.SET_USER_FETCHING:
      return {
        ...state,
        fetchingUser: !state.fetchingUser,
      };
  
    case actionLoginTypes.SET_API_FETCHING:
      return {
        ...state,
        apiloader: !state.apiloader,
      };
    
      case actionLoginTypes.REFRESH_TOKEN_SUCCESS:
        return {
          ...state,
          accessToken: action.payload,
        };
      case actionLoginTypes.TOKEN_EMPTY:
        localStorage.removeItem('token')
        return {
          ...state,
          accessToken: '',
        };

     case actionLoginTypes.USER_RECIEVED:
      return {
        ...state,
        accessToken: action.payload?.access,
        refreshToken: action.payload?.refresh,
        userData: action.payload?.user,
        payment: action.payload?.payment,
        subscription: action?.payload?.payment?.subscription,
        fetchingUser: false,
      };
    case actionLoginTypes.USER_RECIEVED_LOGIN:
      let uName = action.payload.hasOwnProperty("user_name") ? action.payload.user_name : action.payload?.user?.name;
    
      return {
        ...state,
        accessToken: action.payload.access,
        refreshToken: action.payload.refresh,
        userData: { ...action.payload.user, "name": uName },
        payment: {},
        subscription: action.payload.subscription_plan,
        fetchingUser: false,
      };
    case actionLoginTypes.EMAIL_UPDATED:
      return {
        ...state,
        userData: action.payload.data,
        fetchingUser: false,
      };

    case actionLoginTypes.USER_PAYMENT_RECEIVED:
      return {
        ...state,
        payment: action.payload,
        subscription: action.payload.subscription,
      };

    case actionPaymentTypes.SUBSCRIPTION_CANCELLED:

      return {
        ...state,
        payment: action.payload,
      };

    case actionLoginTypes.USER_SETTINGS_RECIEVED:
      return {
        ...state,
        userSettings: action.payload,
        fetchingUser: false,
      };

    case actionLoginTypes.QUESTIONS_RECIEVED:
      return {
        ...state,
        securityQuestions: action.payload,
        fetchingUser: false,
      };

    case actionLoginTypes.GET_EVENT_TYPE:
      return {
        ...state,
        eventType: action.payload
      }

    case actionLoginTypes.USER_ERROR:
      return {
        ...state,
        fetchingUser: false,
      }

    case actionLoginTypes.LOG_OUT:
      return initialData;

    default:
      return state;
  }
};
export default userReducer;

import { actionPaymentTypes } from "../constants/actionTypes";

export const getPlans = ({ callback }) => {
  return {
    type: actionPaymentTypes.GET_PLANS,
    callback: callback
  };
};


export const postStripePayment = ({ data, callback }) => {
  return {
    type: actionPaymentTypes.POST_STRIPE_PAYMENT,
    data: data,
    callback: callback
  };
};
export const postStripeRobotPayment = ({ data, accessToken, callback }) => {
  return {
    type: actionPaymentTypes.POST_STRIPE_ROBOT_PAYMENT,
    data: data,
    accessToken: accessToken,
    callback: callback
  };
};

export const updatePayPalPayment = ({ accessToken, data, callback, method }) => {
  return {
    type: actionPaymentTypes.UPDATE_PAYPAL_SUBSCRIPTION,
    data: data,
    accessToken: accessToken,
    callback: callback,
    method: method
  };
};

export const postPayPalPayment = ({ data, callback }) => {
  return {
    type: actionPaymentTypes.POST_PAYPAL_PAYMENT,
    data: data,
    callback: callback
  };
};

export const postPayPalRobotPayment = ({ data, accessToken, callback }) => {
  return {
    type: actionPaymentTypes.POST_PAYPAL_ROBOT_PAYMENT,
    accessToken: accessToken,
    data: data,
    callback: callback
  };
};

export const cancelSubscriptionApi = ({ accessToken, callback }) => {
  return {
    type: actionPaymentTypes.CANCEL_SUBSCRIPTION,
    accessToken: accessToken,
    callback: callback
  };
}
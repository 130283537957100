import "./nav.css";

import { Avatar, Button, Drawer, Dropdown, Form, Input, Menu, Modal, Row } from "antd";
import { message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { ReactComponent as WstoreIcon } from "../../assets/robotasset/store.svg";
import { ReactComponent as DollarIcon } from "../../assets/robotasset/dollar.svg";
import { ReactComponent as SuscribeIcon } from "../../assets/robotasset/subscribe.svg";
import { ReactComponent as HomeIcon } from "../../assets/robotasset/home.svg";
import { ReactComponent as MoreIcon } from "../../assets/robotasset/more.svg";
import { BarsOutlined } from "@ant-design/icons";
import {
  NUMBERSVALIDATION,
  ONLY_NUMERIC,
  UPDATE,
  RENT_BUTTON,
  STORE_BUTTON,
  MY_SUB_ROBOT_BUTTON,
  ERROR,
} from "../../constants/messages";
import React, { useState } from "react";
import {
  ifAccessTokenExpired,
  getUserSettings,
  logOutUser,
  postUserSettings,
  logoutUsers,
} from "../../actions/commonActions";

import { connect } from "react-redux";
import icon from "../../assets/profile-icon.png";
// import logo from "../../assets/logo.png";
import { ConfigeUI } from "../../configeUI/ConfigeUI";
import { useHistory } from "react-router-dom";

function Navbar(props) {
  const [openSetting, setOpenSetting] = useState(false);
  const [openSidebar, setopenSidebar] = useState(false);
  const [form] = Form.useForm();
  const { userData } = props;
  const weblogo = ConfigeUI();
  const nav = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    const { accessToken } = props;

    props.logoutUsers({
      accessToken,
      callback: () => {
        localStorage.clear();
        if (!localStorage.getItem("token")) this.props.history.push("/login");
      },
    });
    props.logOutUser({
      callback: () => {
        localStorage.clear();
        if (!localStorage.getItem("token")) this.props.history.push("/login");
        setIsModalOpen(false);
      },
    });

  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClick = () => {
    form.resetFields();
    const { accessToken } = props;
    props.getUserSettings({
      accessToken,
      callback: (data,info) => {
        if (data !== "No Data") {
          form.setFieldsValue({
            magic: parseInt(data.magic),
          });
        }
        setOpenSetting(!openSetting);
        if(data===ERROR){
          if(info!=='Token is Expired'){
            message.error(info)
          }
        }
      },
    });
  };

  const handlesidebar = () => {
    setopenSidebar(!openSidebar);
  };

  const onUpdate = (value) => {
    const { accessToken } = props;
    props.postUserSettings({
      accessToken,
      data: {
        options: {
          magic: value.magic,
        },
      },
    });
    setOpenSetting(!openSetting);
  };

  const takeMenuAction = (input) => {
    const { accessToken } = props;
    if (accessToken && ifAccessTokenExpired(accessToken)) {
      return;
    }

    if (input.key === "1") workspace();
    else if (input.key === "2") props.history.push(`/profile`);

  };

  const logout = () => {
    showModal()
    
  };

  const onClose = () => {
    setOpenSetting(false);
    setopenSidebar(false);
  };

  const workspace = () => {
    props.history.push(`/`);
  };

  const menuSidebar = () => {
    return (
      <Menu onClick={(key) => takeMenuAction(key)}>
        <Menu.Item key="1">Robot Maker</Menu.Item>
        <Menu.Item key="2">Profile</Menu.Item>
      </Menu>
    );
  };

  const handleMenuClick = (e) => {
    // message.info(`You clicked on menu item ${e.key}`);
  };
  const menu = () => {
    return (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="1">
          {" "}
          <a
            href="https://robotmaker.ai/how-to-videos"
            target="_blank"
            rel="noopener noreferrer"
          >
            How to Videos
          </a>
        </Menu.Item>
        <Menu.Item key="2">
          <a
            href="https://robotmaker.ai/faqs"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQ
          </a>
        </Menu.Item>
        <Menu.Item key="3">
          <a
            href="https://robotmaker.ai"
            target="_blank"
            rel="noopener noreferrer"
          >
            Main Site
          </a>
        </Menu.Item>
      </Menu>
    );
  };
  const payment = props.payment;

  return (
    <div className="nav-container vertical-align">
      <div className="logo-sidebar">
        <img
          alt="logo"
          className="logo cursor"
          src={weblogo.sidelogov}
          onClick={workspace}
        />
      </div>
      
      <div className="d-flex-between flex-item ph">
      {
        nav.location.pathname === "/workspace" ?
        <Row className=" text-center  nav_btns">
          <div
            className=" nav_item"
            onClick={() => props.history.push(`/`)}
          >
            <HomeIcon
              className={`${
                nav.location.pathname === "/workspace"
                  ? "storeIconActive nav-active"
                  : "storeIcon"
              }`}
            />
            <p
              className={`${
                nav.location.pathname === "/workspace" ? "nav-active-p" : ""
              }`}
            >
              Home
            </p>
          </div>
          <div
            className=" nav_item"
            onClick={() => props.history.push(`/robot_store`)}
          >
            <WstoreIcon
              className={`${
                nav.location.pathname === "/robot_store"
                  ? "storeIconActive nav-active"
                  : "storeIcon"
              }`}
            />
            <p
              className={`${
                nav.location.pathname === "/robot_store" ? "nav-active-p" : ""
              }`}
            >
             Robot Marketplace
            </p>
          </div>
          <div
            className=" nav_item"
            onClick={() => props.history.push(`/robot_rent`)}
          >
            <DollarIcon
              className={`${
                nav.location.pathname === "/robot_rent"
                  ? "storeIconActive nav-active"
                  : "storeIcon"
              }`}
            />
            <p
              className={`${
                nav.location.pathname === "/robot_rent" ? "nav-active-p" : ""
              }`}
            >
              Rent Your Robots
            </p>
          </div>
          <div
            className=" nav_item"
            onClick={() => props.history.push(`/my_robot`)}
          >
            <SuscribeIcon
              className={`${
                nav.location.pathname === "/my_robot"
                  ? "storeIconActive nav-active"
                  : "storeIcon"
              }`}
            />
            <p
              className={`${
                nav.location.pathname === "/my_robot" ? "nav-active-p" : ""
              }`}
            >
              Subscribed Robots
            </p>
          </div>
          {/* <div
            className=" nav_item"
            onClick={() => props.history.push(`/trading_account`)}
          >
            <SuscribeIcon
              className={`${
                nav.location.pathname === "/trading_account"
                  ? "storeIconActive nav-active"
                  : "storeIcon"
              }`}
            />
            <p
              className={`${
                nav.location.pathname === "/trading_accountt" ? "nav-active-p" : ""
              }`}
            >
              Trading Accounts
            </p>
          </div> */}
          <div
            className=" nav_item"
            onClick={() => props.history.push(`/ai-forcast`)}
          >
            <SuscribeIcon
              className={`${
                nav.location.pathname === "/ai-forcast"
                  ? "storeIconActive nav-active"
                  : "storeIcon"
              }`}
            />
            <p
              className={`${
                nav.location.pathname === "/ai-forcast" ? "nav-active-p" : ""
              }`}
            >
              AI Forecast  
            </p>
          </div>
          <div className="view_more">
            <Dropdown overlay={menu} 
            // overlayClassName="custom-dropdown"
            >
              <div className="nav_item">
                <MoreIcon className="storeIcon" />
                <p>View</p>
              </div>
            </Dropdown>
          </div>
        </Row>
        :
        <div className="title-name">
        {
          nav.location.pathname === "/robot_store" ? 'Robot Marketplace':
          nav.location.pathname === "/robot_rent" ? 'Rent your Robots':
          nav.location.pathname === "/my_robot" ? 'My Subscribed Robots':
          nav.location.pathname === "/ai-forcast" ? 'AI Forecast Dashboard':
          nav.location.pathname === "/trading_account" ? 'Trading Accounts':
          nav.location.pathname === "/create_acc" ? 'Add new Trading account':''
        }
        </div>
      }
        <div className=" save-div vertical-align">
          {props.payment.remaining_days ? (
            <div className="remaining">
              <span className="r_label">Remaining Days </span>
              <span className="r_days">{props.payment.remaining_days}</span>
            </div>
          ) : (
            <div className="remaining">
              <span className="r_label">Remaining Days : </span>
              <span className="r_days">{userData?.remaining_days}</span>
            </div>
          )}

          {
        nav.location.pathname === "/workspace" &&
        <>
          <BarsOutlined
            className="sidebar_icon"
            style={{ fontSize: "20px", marginRight: "10px" }}
            onClick={handlesidebar}
          />
          
        </>
      }
          <div className="">
            <img
              className="logout-logo"
              onClick={handleClick}
              src={require("../../assets/robotasset/setting.png")}
              alt=""
            />
          </div>

          <Dropdown overlay={menuSidebar} >
            <Avatar
              size="large"
              className={`${payment && payment.mode}-circle avtar-logo`}
              src={icon}
            />
          </Dropdown>
          <div className="">
            <img
              className="logout-logo"
              onClick={logout}
              src={require("../../assets/robotasset/logout.png")}
              alt=""
            />
          </div>
        </div>
      </div>
      
      <Modal
        title=""
        visible={isModalOpen}
        closable={false}
        footer={null}
        style={{textAlign:'center'}}
      >
        <h1 style={{ fontWeight:'700',textAlign:'center'}}>Are you sure ?</h1>
        <div style={{marginTop:'15px'}}>
        <Button
            key="submit"
            style={{marginRight:'15px'}}
            type="primary"
            onClick={handleOk}
          >
            Logout
          </Button>
        <Button
            key="submit"
            type="danger"
            onClick={handleCancel}
          >
            Cancel
          </Button>

        </div>
      </Modal>
      <Drawer
        title="Settings"
        placement={"right"}
        closable={true}
        width={320}
        onClose={onClose}
        visible={openSetting}
        key={"right"}
      >
        <div className="drawer-body" id="navbar-field">
          <div className="property-header">Magic Number and Other Options</div>
          <Form form={form} onFinish={onUpdate}>
            <div className="property-body">
              <div className="magic-no">
                <div className="fw">Magic Number</div>
                <Form.Item
                  name="magic"
                  style={{ marginBottom: "0px" }}
                  rules={[
                    { required: true, message: ONLY_NUMERIC },
                    {
                      pattern: NUMBERSVALIDATION,
                      message: ONLY_NUMERIC,
                    },
                  ]}
                >
                  <Input
                    placeholder="Magic Number"
                    className="input-style b-5"
                    maxLength={10}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="drawer-bottom">
              <Button
                type="primary"
                className="height-50 button primary-button full-width btns b-5"
                htmlType="submit"
              >
                {UPDATE}
              </Button>
            </div>
          </Form>
        </div>
      </Drawer>
      <Drawer
        title="Rootmaker"
        placement={"right"}
        closable={true}
        width={320}
        onClose={onClose}
        visible={openSidebar}
        key={"right"}
      >
        <div className="drawer-body" id="navbar-field">
          <div className="width-60 text-center sidebar_btn">
            <Button
              target="_blank"
              type="primary"
              className="button primary margin-right-10 margin-top-10 b-5 nav_btn btns"
            >
              <a
                href="https://robotmaker.ai/how-to-videos"
                target="_blank"
                rel="noopener noreferrer"
              >
                How to Videos
              </a>
            </Button>
            <Button
              target="_blank"
              type="primary"
              className="button primary margin-right-10 margin-top-10 b-5 nav_btn btns"
            >
              <a
                href="https://robotmaker.ai/faqs"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>
            </Button>
            <Button
              target="_blank"
              type="primary"
              className="button primary margin-right-10 margin-top-10 b-5 nav_btn btns"
            >
              <a
                href="https://robotmaker.ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                Main Site
              </a>
            </Button>
            {/* New two button added */}
            <Button
              target="_blank"
              type="primary"
              className="button primary margin-right-10 margin-top-10 b-5 nav_btn btns"
              onClick={() => props.history.push(`/robot_store`)}
            >
              {STORE_BUTTON}
            </Button>
            <Button
              target="_blank"
              type="primary"
              className="button primary margin-right-10 margin-top-10 b-5 nav_btn btns"
              onClick={() => props.history.push(`/robot_rent`)}
            >
              {RENT_BUTTON}
            </Button>
            <Button
              target="_blank"
              type="primary"
              className="button primary margin-right-10 margin-top-10 b-5 nav_btn btns"
              onClick={() => props.history.push(`/my_robot`)}
            >
              {MY_SUB_ROBOT_BUTTON}
            </Button>
            <Button
              target="_blank"
              type="primary"
              className="button primary margin-right-10 margin-top-10 b-5 nav_btn btns"
              onClick={() => props.history.push(`/ai-forcast`)}
            >
              AI Forecast
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

const mapStateToProps = ({
  userReducer: { accessToken, payment, userData },
}) => ({
  accessToken,
  payment,
  userData,
});

const mapDispatchToProps = {
  logOutUser: logOutUser,
  getUserSettings: getUserSettings,
  postUserSettings: postUserSettings,
  logoutUsers: logoutUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
